import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ReturnMethodSelect } from '@inventory/return/forms/inputs/ReturnMethodSelect';
import { ReportTypeSelect } from '@inventory/return/forms/inputs/ReturnTypeSelect';
import { OrderTypeSelect } from '@ui/form/OrderTypeSelect';
import {
  ContactsANDCompaniesSelect,
  CustomerObject,
} from 'src/components/ui/form/ContactsANDCompaniesSelect';

export const ReturnsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'customer'}
      label={'Customer'}
      defaultOpen={!!values?.customer}
    >
      <ContactsANDCompaniesSelect
        initialValue={values?.customer as CustomerObject}
        allowClear={false}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Return Date'}
      name={'returnDate'}
      defaultOpen={!!values?.returnDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Return Method'}
      name={'returnMethod'}
      defaultOpen={!!values?.returnMethod}
      withDivider
    >
      <ReturnMethodSelect />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Return Type'}
      name={'type'}
      defaultOpen={!!values?.type}
      withDivider
    >
      <ReportTypeSelect />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Order Type'}
      name={'orderType'}
      defaultOpen={!!values?.orderType}
      withDivider
    >
      <OrderTypeSelect />
    </ExpandableFormItem>
  </>
);
