import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';

import { MultiplyDesignStyleSelect } from '@inventory/inventoryManagement/products/inputs/DesignStyleInput/MultiplyDesignStyleSelect';
import { MultiplyShankStyleSelect } from '@inventory/inventoryManagement/products/inputs/ShankStyleInput/MultiplyShankStyleSelect';
import { MultiplySizeSelect } from '@inventory/inventoryManagement/products/inputs/SizeInput/MultiplySizeSelect';
import { MultiplySpecificTypeSelect } from '@inventory/inventoryManagement/products/inputs/SpecificTypeInput/MultiplySpecificTypeSelect';

export const BandFilterCustomFields: FC<SalesItemReportFilterCustomFields> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'designStyle'}
          label={'Design Style'}
          style={customStyles}
        >
          <MultiplyDesignStyleSelect
            allowClear
            onChange={handleChange('designStyle')}
            initialValue={initialValues?.designStyle ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'shankStyle'}
          label={'Shank Style'}
          style={customStyles}
        >
          <MultiplyShankStyleSelect
            allowClear
            onChange={handleChange('shankStyle')}
            initialValue={initialValues?.shankStyle ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Band Type'}
          style={customStyles}
        >
          <MultiplySpecificTypeSelect
            allowClear
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'size'} label={'Band Size'} style={customStyles}>
          <MultiplySizeSelect
            allowClear
            onChange={handleChange('size')}
            initialValue={initialValues?.size ?? undefined}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
