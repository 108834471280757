import {
  useCreateContactsFromCsvMutation,
  useCreateOrdersFromCsvMutation,
  useCreateProductsFromCsvMutation,
} from '@/graphql';
import {
  importingReducer,
  initialState,
} from '@app/ImportingContext/importingSlice/slice';
import {
  ActionType,
  ConfigType,
  ImportResult,
  ImportResultKeys,
  ImportingProcesses,
  ParsedObject,
} from '@app/ImportingContext/types/types';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { CreateEntitiesInput } from '@components/dashboard/dashboardSettings/SettingsModuleFactory/sections/importEntity/types/types';
import { UploadFile } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import debounce from 'lodash/debounce';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

const ImportContext = createContext<{
  importingStore: ImportingProcesses;
  handleCSVFileChange: (
    file: UploadFile,
    config: { type: ConfigType; isModeOn?: boolean },
  ) => void;
  helpersFunctions: {
    removeItem: (payload: {
      importType: ConfigType;
      tableType: keyof ImportResult;
      localId: string;
    }) => void;
    forceSetResultTable: (payload: {
      importType: ConfigType;
      tableType: keyof ImportResult;
      forceUpdatingArr: any[];
    }) => void;
  };
} | null>(null);

export const ImportingProvider: FC<PropsWithChildren> = ({ children }) => {
  const [createContacts] = useCreateContactsFromCsvMutation();
  const [createOrders] = useCreateOrdersFromCsvMutation();
  const [createProducts] = useCreateProductsFromCsvMutation();

  const [importingStore, dispatch] = useReducer(importingReducer, initialState);

  const importsConfig = useMemo(
    () => ({
      contacts: {
        createFunc: createContacts,
        resultObjName: 'createContactsFromCSV',
      },
      orders: {
        createFunc: createOrders,
        resultObjName: 'createOrdersFromCSV',
      },
      products: {
        createFunc: createProducts,
        resultObjName: 'createProductsFromCSV',
      },
    }),
    [createContacts, createOrders, createProducts],
  );

  const message = useStatusMessage();

  const debouncedHandleFileChange = debounce(
    async (
      file: UploadFile,
      config: { type: ConfigType; isModeOn?: boolean },
    ) => {
      if (
        (file.type && file.type.startsWith('text/csv')) ||
        (file.name && file.name.endsWith('.csv'))
      ) {
        dispatch({ type: ActionType.START_IMPORT, importType: config.type });

        const handleFile = async (file: File) => {
          const inputObject: CreateEntitiesInput = { uploadCsv: file };

          if (config.isModeOn) {
            inputObject.createNewMode = config.isModeOn;
          }

          try {
            const res = await importsConfig[config.type].createFunc({
              variables: { input: inputObject },
            });

            if (res && res.data) {
              const result = (res.data as AnyObject)?.[
                importsConfig[config.type].resultObjName
              ];
              if (!result) message.open('error');
              const parsedObject: ParsedObject = JSON.parse(result);

              if (parsedObject) {
                dispatch({
                  type: ActionType.FINISH_IMPORT,
                  importType: config.type,
                  payload: {
                    completedImports: parsedObject.completedCreations ?? [],
                    unvalidatedImports: parsedObject.spoiledCreations ?? [],
                    needChangeCreations: parsedObject.needChangeCreations ?? [],
                  },
                });

                if (
                  config.type === 'contacts' &&
                  parsedObject.customFieldsArr
                ) {
                  dispatch({
                    type: ActionType.SET_CUSTOM_FIELDS,
                    importType: 'contacts',
                    payload: parsedObject.customFieldsArr,
                  });
                }

                if (
                  config.type === 'products' &&
                  parsedObject.maxProductsCount !== undefined &&
                  parsedObject.maxImagesCount !== undefined
                ) {
                  dispatch({
                    type: ActionType.SET_MAX_COUNTS,
                    importType: 'products',
                    payload: {
                      maxProductsCount: parsedObject.maxProductsCount,
                      maxImagesCount: parsedObject.maxImagesCount,
                    },
                  });
                }
              }
            }
          } catch (error) {
            message.open('error', 'Error occurred during import.');
            dispatch({
              type: ActionType.SET_LOADING,
              importType: config.type,
              isLoading: false,
            });
          }
        };

        try {
          await handleFile(file.originFileObj as File);
        } catch (error) {
          message.open('error', 'File handling failed.');
          dispatch({
            type: ActionType.SET_LOADING,
            importType: config.type,
            isLoading: false,
          });
        }
      } else {
        message.open('error', 'The file is not a CSV.');
      }
    },
    300,
  );

  const handleCSVFileChange = useCallback(
    (file: UploadFile, config: { type: ConfigType; isModeOn?: boolean }) => {
      debouncedHandleFileChange(file, config);
    },
    [debouncedHandleFileChange], // Ensure dependencies are correct and stable
  );

  const removeItem = (payload: {
    importType: ConfigType;
    tableType: ImportResultKeys;
    localId: string;
  }) => {
    dispatch({
      type: ActionType.REMOVE_ITEM,
      importType: payload.importType,
      tableType: payload.tableType,
      localId: payload.localId,
    });
  };

  const forceSetResultTable = (payload: {
    importType: ConfigType;
    tableType: ImportResultKeys;
    forceUpdatingArr: any[];
  }) => {
    const { importType, tableType, forceUpdatingArr } = payload;
    dispatch({
      type: ActionType.FORCE_SET_RESULT_TABLE,
      tableType,
      importType,
      forceUpdatingArr,
    });
  };

  const helpersFunctions = {
    removeItem,
    forceSetResultTable,
  };

  return (
    <ImportContext.Provider
      value={{ importingStore, handleCSVFileChange, helpersFunctions }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export const useImportContext = () => {
  const context = useContext(ImportContext);
  if (!context) {
    throw new Error(
      'useImportContext must be used within an ImportingProvider',
    );
  }
  return context;
};
