import { FC } from 'react';

import { Select, SelectProps } from 'antd';

import { capitalizeEnum } from '@helpers/capitalizeEnum';
import { SalesItemReportType } from '@helpers/enumTypes';
import { SalesItemReportTypeSelectOption } from './SalesItemReportTypeSelectOption';

export const SalesItemReportTypeSelect: FC<SelectProps> = (props) => {
  const typesItems = Object.values(SalesItemReportType)?.map((type) => ({
    value: type,
    label: (
      <SalesItemReportTypeSelectOption
        label={capitalizeEnum(type) as SalesItemReportType}
      />
    ),
  }));

  return (
    <Select
      defaultValue={props.defaultValue ?? undefined}
      placeholder={'Select item type'}
      optionLabelProp={'label'}
      options={typesItems}
      style={{ width: '100%' }}
      {...props}
    />
  );
};
