import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';

export const PinFilterCustomFields: FC<SalesItemReportFilterCustomFields> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Pin Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
