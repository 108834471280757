import { FC } from 'react';

import { ConfigProvider, Divider, Space, Typography } from 'antd';

import { useAuthLayoutContentQuery } from '@/graphql';

import styles from './AuthContent.module.scss';

export const AuthContent: FC = () => {
  const { data } = useAuthLayoutContentQuery();

  const { authContent } = data?.authLayout?.data?.attributes || {};

  const { headline, background, advantages } = authContent || {};

  const { title, subtitle } = headline || {};

  const backgroundUrl = background?.data?.attributes?.url;

  return (
    <section
      style={{
        backgroundImage: `url(${backgroundUrl})`,
      }}
      className={styles.content}
    >
      <ConfigProvider
        theme={{
          token: {
            colorText: '#fff',
            colorTextHeading: '#fff',
          },
        }}
      >
        <Space direction={'vertical'} size={32}>
          <Space direction={'vertical'} size={16}>
            <Typography.Title>{title}</Typography.Title>
            <Typography.Title
              level={3}
              style={{
                fontWeight: 400,
              }}
            >
              {subtitle}
            </Typography.Title>
          </Space>
          <Space
            size={33}
            split={<Divider type={'vertical'} className={styles.divider} />}
            className={styles.advantages}
          >
            {advantages?.map((advantage) => (
              <Space size={0} direction={'vertical'} key={advantage?.id}>
                <Typography.Text strong className={styles.advantageTitle}>
                  {advantage?.title}
                </Typography.Text>
                <Typography.Text className={styles.advantageSubtitle}>
                  {advantage?.subtitle}
                </Typography.Text>
              </Space>
            ))}
          </Space>
        </Space>
      </ConfigProvider>
    </section>
  );
};
