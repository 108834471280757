import { Icon, IconSize } from '@assets/icon';
import { useOnboarding } from '@components/onboarding/hooks/useOnboading';
import styles from '@components/onboarding/index.module.scss';
import { useTenantRoutes } from '@router/routes';
import { Alert } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router';

export const AlertNotification: FC = () => {
  const navigate = useNavigate();

  const { doneSteps, stepsCount } = useOnboarding();
  const { onboarding } = useTenantRoutes();
  const handleNavigate = () => {
    navigate(onboarding);
  };
  return (
    <Alert
      className={styles.alert}
      message={`Please complete onboarding to get full access to CaratIQ’s functionality (${
        stepsCount - doneSteps
      } steps left)`}
      type={'info'}
      showIcon
      closeIcon={<Icon type={'chevron-right'} size={IconSize.Medium} />}
      onClick={handleNavigate}
    />
  );
};
