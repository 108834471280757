import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';

import { MultiplyLinkStyleSelect } from '@inventory/inventoryManagement/products/inputs/LinkStyleInput/MultiplyLinkStyleSelect';
import { MultiplyLinkTypeSelect } from '@inventory/inventoryManagement/products/inputs/LinkTypeInput/MultiplyLinkTypeSelect';

export const AnkletFilterCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'linkStyle'}
          label={'Anklet Link Style'}
          style={customStyles}
        >
          <MultiplyLinkStyleSelect
            allowClear
            onChange={handleChange('linkStyle')}
            initialValue={initialValues?.linkStyle ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'linkType'}
          label={'Anklet Link Type'}
          style={customStyles}
        >
          <MultiplyLinkTypeSelect
            allowClear
            onChange={handleChange('linkType')}
            initialValue={initialValues?.linkType ?? undefined}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
