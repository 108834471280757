import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Marketing = lazy<FC>(() => import('@/pages/marketing'));
const MarketingDashboard = lazy<FC>(() => import('@pages/marketing/dashboard'));
const Sequence = lazy<FC>(() => import('@pages/marketing/sequence'));
const NewCampaign = lazy<FC>(() => import('@pages/marketing/newCampaign'));
const SequencePreview = lazy<FC>(
  () => import('@pages/marketing/sequencePreview'),
);
const ContactsContainer = lazy(
  () => import('@pages/marketing/contactsContainer'),
);
const EnrolledContacts = lazy<FC>(
  () => import('@pages/marketing/enrolledContacts'),
);
const EnrolledLeads = lazy<FC>(() => import('@pages/marketing/enrolledLeads'));
const UnsubscribedContacts = lazy<FC>(
  () => import('@pages/marketing/unsubscribedContacts'),
);
const UnsubscribedLeads = lazy<FC>(
  () => import('@pages/marketing/unsubscribedLeads'),
);

const EmailEditor = lazy<FC>(() => import('@pages/marketing/emailEditor'));

const { index, dashboard, sequence, email } = ROUTES.tenant.marketing;

export const marketingRoutes: RouteObject = {
  Component: Marketing,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={dashboard} />,
    },
    {
      Component: MarketingDashboard,
      path: dashboard,
    },
    {
      Component: EmailEditor,
      path: `${email}/:uuid`,
      index: true,
    },
    {
      Component: Sequence,
      path: sequence,
      children: [
        {
          Component: NewCampaign,
          path: ':uuid',
          children: [
            {
              index: true,
              element: <Navigate to={'preview'} />,
            },
            {
              Component: SequencePreview,
              path: 'preview',
              index: true,
            },
            {
              Component: ContactsContainer,
              path: 'enrolled',
              children: [
                {
                  index: true,
                  element: <Navigate to={'contacts'} />,
                },
                {
                  Component: EnrolledContacts,
                  path: 'contacts',
                  index: true,
                },
                {
                  Component: EnrolledLeads,
                  path: 'leads',
                },
              ],
            },
            {
              Component: ContactsContainer,
              path: 'unsubscribed',
              children: [
                {
                  index: true,
                  element: <Navigate to={'contacts'} />,
                },
                {
                  Component: UnsubscribedContacts,
                  path: 'contacts',
                  index: true,
                },
                {
                  Component: UnsubscribedLeads,
                  path: 'leads',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
