import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ConfigProvider, Space, Typography } from 'antd';

import { useDrawer } from '@drawer/drawerContext';
import { useToken } from '@hooks/useToken';

export interface TextInfoBlockType {
  address?: string;
  title?: string | ReactNode;
  titleColor?: string;
  descriptionColor?: string;
  description?: string | ReactNode;
  fontSize?: number;
  titleFontSize?: number;
  link?: string;
  target?: '_blank' | '_self';
  fontWeight?: number;
}

const TextInfoBlock: FC<TextInfoBlockType> = ({
  title,
  description,
  fontSize = 12,
  titleFontSize = 14,
  titleColor,
  descriptionColor,
  link,
  target = '_self',
  fontWeight,
}) => {
  const { token } = useToken();
  const { closeDrawer } = useDrawer();

  return (
    <Space direction={'vertical'} size={4}>
      <ConfigProvider
        theme={{
          token: {
            fontSize: fontSize,
            fontSizeHeading5: titleFontSize,
            fontWeightStrong: fontWeight ?? 500,
            colorText: descriptionColor ?? token.colorTextDescription,
            colorTextHeading: titleColor ?? token.colorText,
          },
        }}
      >
        <Typography.Title level={5}>{title}</Typography.Title>
        {description && (
          <Typography.Text style={{ textAlign: 'left' }}>
            {link ? (
              <Link to={link} target={target} onClick={closeDrawer}>
                {description}
              </Link>
            ) : (
              description
            )}
          </Typography.Text>
        )}
      </ConfigProvider>
    </Space>
  );
};

export default TextInfoBlock;
