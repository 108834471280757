import React, { useCallback, useMemo, useState } from 'react';

import { AnyObject } from '@helpers/types';

import { useTableParams } from '@ui/table/hooks/useTableParams';

export function useEntityTableState<S extends AnyObject>(
  onTotalChange?: (total: number) => void,
) {
  const { tableParams, updateTotalValue, handleTableChange } =
    useTableParams<S>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleTotalChange = useCallback(
    (total?: number) => {
      if (total === undefined) return;
      updateTotalValue(total);
      onTotalChange?.(total);
    },
    [onTotalChange, updateTotalValue],
  );

  const queryParams = useMemo(() => {
    return {
      ...tableParams,
      pagination: {
        page: tableParams.pagination?.current,
        pageSize: tableParams.pagination?.pageSize,
      },
    };
  }, [tableParams]);

  return {
    tableParams,
    handleTotalChange,
    handleTableChange,
    selectedRowKeys,
    rowSelection,
    queryParams,
  };
}
