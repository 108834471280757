import { FilterFunction } from '@filters/types';
import dayjs from 'dayjs';

export const createOrderIdFilter: FilterFunction<string> = (
  orderId?: string,
): OrderFiltersInput => {
  return orderId
    ? {
        id: {
          eq: orderId,
        },
      }
    : {};
};

export const createOrderTypeFilter: FilterFunction<string> = (
  orderType?: string,
): OrderFiltersInput => {
  return orderType
    ? {
        type: {
          eq: orderType,
        },
      }
    : {};
};

export const createBusinessLocationOrderFilter: FilterFunction<string[]> = (
  locationIds?: string[],
): OrderFiltersInput => {
  return locationIds?.length !== 0
    ? ({
        businessLocation: {
          id: {
            in: locationIds,
          },
        },
      } as OrderFiltersInput)
    : {};
};

export const createOrdersPeriodFilter: FilterFunction<
  [dayjs.Dayjs | null, dayjs.Dayjs | null]
> = (period): OrderFiltersInput => {
  const [start, end] = period || [];

  if (!start && !end) {
    return {};
  }

  const startFilter = start
    ? {
        gte: start.toDate(),
      }
    : {};

  const endFilter = end
    ? {
        lte: end.toDate(),
      }
    : {};

  return {
    period: {
      ...startFilter,
      ...endFilter,
    },
  } as OrderFiltersInput;
};

export const createSalesFilter: FilterFunction<string> = (
  employee?: string,
): OrderFiltersInput => {
  return employee
    ? {
        sales: {
          id: {
            eq: employee,
          },
        },
      }
    : {};
};
