import {
  ProductValues,
  StoneValues,
} from '@inventory/inventoryManagement/products/types';
import { Form } from 'antd';

type ReturnType = {
  stones: StoneValues[];
  productType: string;
  materialGradeType: string;
  metalType: string;
  cutStyle: string;
};

export function useProductFormWatch(): ReturnType {
  const form = Form.useFormInstance<ProductValues>();

  const productType = Form.useWatch<string>('productType', form);

  const metalType = Form.useWatch<string>('metalType', form);

  const stones = Form.useWatch<StoneValues[]>('stones', form);
  const cutStyle = Form.useWatch<string>('cutStyle', form);

  const materialGradeType = Form.useWatch<string>('materialGradeType', form);

  return {
    stones,
    productType,
    materialGradeType,
    metalType,
    cutStyle,
  };
}
