import { Dayjs } from 'dayjs';

import { transformToDateFilterInput } from '@helpers/filters';

export const createFormNameFilter = (name?: string): FormFiltersInput => {
  return {
    name: {
      containsi: name,
    },
  };
};

export const createSendToFilter = (sendTo?: string): FormFiltersInput => {
  return {
    sendTo: {
      containsi: sendTo,
    },
  };
};

export const createFormStatusOrCustomerNameFilter = (
  submitted?: boolean,
  customerName?: string,
): FormFiltersInput => {
  return {
    publicForm: {
      ...(submitted && {
        submitted: {
          eq: submitted,
        },
      }),
      ...(customerName && {
        customerName: {
          containsi: customerName,
        },
      }),
    },
  };
};

export const createFormCreatedAtFilter = (
  createdAt?: [null | Dayjs, null | Dayjs] | null,
): FormFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
