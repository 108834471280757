import { FC } from 'react';

import { SelectProps } from 'antd';

import { useEmployeesQuery } from '@hooks/users/useEmployeesQuery';

import { Icon } from '@assets/icon';

import { UserSelect } from '@form/inputs/userSelect/UserSelect';

interface Props extends SelectProps {
  onChange?: (value: string[]) => void;
  defaultValue?: string[];
}

export const AssigneesSelect: FC<Props> = ({
  onChange,
  defaultValue,
  value,
}) => {
  const { data, loading, changeFilters } = useEmployeesQuery({
    pagination: {
      limit: 10,
    },
  });

  return (
    <UserSelect
      data={data}
      changeFilters={changeFilters}
      onChange={onChange}
      loading={loading}
      defaultValue={defaultValue ? defaultValue : undefined}
      mode={'multiple'}
      value={value}
      placeholder={'Select Assignees'}
      menuItemSelectedIcon={<Icon type={'circle-remove'} />}
    />
  );
};
