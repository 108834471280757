import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';
import { FC, lazy } from 'react';

const QuotesMainPage = lazy<FC>(() => import('@pages/quotes/index'));
const DealsPage = lazy<FC>(() => import('@pages/quotes/deals'));
const SalesOrdersPage = lazy<FC>(() => import('@pages/quotes/salesOrders'));
const InvoicesPage = lazy<FC>(() => import('@pages/quotes/invoices'));
const QuotesPage = lazy<FC>(() => import('@pages/quotes/quotes'));

const { index, quotes, deals, salesOrders, invoices } = ROUTES.tenant.quotes;

export const quotesRoutes: RouteObject = {
  path: index,
  Component: QuotesMainPage,
  children: [
    {
      index: true,
      element: <Navigate to={quotes} />,
    },
    {
      path: quotes,
      Component: QuotesPage,
    },

    {
      path: deals,
      Component: DealsPage,
    },
    {
      path: salesOrders,
      Component: SalesOrdersPage,
    },
    {
      path: invoices,
      Component: InvoicesPage,
    },
  ],
};
