import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const TransferOrder = lazy<FC>(
  () => import('@pages/inventory/transfer-order/index'),
);

const UpdateTransferOrderForm = lazy<FC>(
  () => import('@inventory/transfer-orders/forms/UpdateTransferOrderForm'),
);

const CreateTransferOrderForm = lazy<FC>(
  () => import('@inventory/transfer-orders/forms/CreateTransferOrderForm'),
);

const { index, create } = ROUTES.tenant.inventory.transferOrders;
export const transferOrdersManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: TransferOrder,
    },
    {
      path: create,
      Component: CreateTransferOrderForm,
    },
    {
      path: ':uuid',
      Component: UpdateTransferOrderForm,
    },
  ],
};
