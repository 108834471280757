import { FC } from 'react';

import EmployeeSelect from '@ui/form/EmployeeSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';

export const CompanyAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'leadOwner'}
      label={'Company Owner'}
      defaultOpen={!!values?.leadOwner?.length}
      withDivider
    >
      <EmployeeSelect
        mode={'multiple'}
        shouldApplyDefaultValue={false}
        placeholder={'Filter companies by Company Owner'}
      />
    </ExpandableFormItem>
  </>
);
