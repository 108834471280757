import { FC } from 'react';

import { Select, SelectProps } from 'antd';

import capitalize from 'lodash/capitalize';

export type EmployeeStatus = 'pending' | 'active' | 'not active';
export const EmployeeStatusSelect: FC<SelectProps> = (props) => {
  const statuses: EmployeeStatus[] = ['pending', 'active', 'not active'];

  const statusOptions = statuses?.map((status) => ({
    value: status,
    label: capitalize(status),
  }));

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={'Please select employee status'}
      options={statusOptions}
      {...props}
    />
  );
};
