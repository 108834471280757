import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { PieceInput } from 'src/components/inventory/inventoryManagement/products/inputs/PieceInput';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';

export const GrillzFilterCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'pieces'}
          label={'Grillz Pieces'}
          style={customStyles}
        >
          <PieceInput
            onChange={handleChange('pieces')}
            initialValue={initialValues?.pieces ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Grillz Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
