import { FC, useCallback, useEffect, useMemo } from 'react';

import AvatarInfo from '@ui/avatar/AvatarInfo';

import { useContactsCompaniesLeadsLazyQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { CustomOption } from '@form/inputs/userSelect/UserSelect';

import styles from '@ui/form/ContactsANDCompaniesSelect/index.module.scss';

export type CustomerObject = {
  contact?: Maybe<string>;
  company?: Maybe<string>;
  lead?: Maybe<string>;
};
interface Props {
  onChange?: (value: any) => void;
  initialValue?: Maybe<CustomerObject>;
  onClear?: VoidFunction;
  isCalendar?: boolean;
}

export const AllCustomersSelect: FC<Props> = ({
  onChange,
  initialValue,
  onClear,
  isCalendar,
}) => {
  const handleChange = useCallback(
    (value: string) => {
      if (!value) return;
      const data = value.split(':');
      const inputData = {
        contact: null,
        lead: null,
        company: null,
        [data[0]]: data[1],
      };
      onChange?.(inputData);
    },
    [onChange],
  );

  const [getCustomers, { data, loading, called }] =
    useContactsCompaniesLeadsLazyQuery();

  useEffect(() => {
    getCustomers({
      variables: {
        pagination: {
          limit: 2,
        },
      },
    });
    if (initialValue) {
      onChange?.(initialValue);
    }
    // eslint-disable-next-line
  }, []);
  //Todo(Vanya) handle deps in better way

  const individualContacts = useMemo(() => {
    const individualContactsData = data?.contacts?.data || [];

    return individualContactsData?.map((contact) => ({
      value: `contact:${contact?.id}`,
      title: contact?.attributes?.fullName || 'Unnamed',
      label: (
        <AvatarInfo
          title={contact?.attributes?.fullName || 'Unnamed'}
          src={contact?.attributes?.avatar?.data?.attributes?.url}
        />
      ),
    }));
  }, [data?.contacts?.data]);

  const companyContacts = useMemo(() => {
    const companiesData = data?.companies?.data || [];

    return companiesData?.map((contact) => ({
      value: `company:${contact?.id}`,
      title: contact?.attributes?.name || 'Unnamed',
      label: (
        <AvatarInfo
          title={contact?.attributes?.name || 'Unnamed'}
          src={contact?.attributes?.avatar?.data?.attributes?.url}
        />
      ),
    }));
  }, [data?.companies?.data]);

  const leadsContacts = useMemo(() => {
    const leadsData = data?.leads?.data || [];

    return leadsData?.map((contact) => ({
      value: `lead:${contact?.id}`,
      title: contact?.attributes?.fullName || 'Unnamed',
      label: (
        <AvatarInfo
          title={contact?.attributes?.fullName || 'Unnamed'}
          src={contact?.attributes?.avatar?.data?.attributes?.url}
        />
      ),
    }));
  }, [data?.leads?.data]);

  const handleSearch = (value: string) => {
    getCustomers({
      variables: {
        filterContacts: {
          or: [
            {
              fullName: {
                containsi: value,
              },
            },
            {
              email: {
                containsi: value,
              },
            },
            {
              phoneNumber: {
                containsi: value,
              },
            },
          ],
        },
        filterCompanies: {
          or: [
            {
              name: {
                containsi: value,
              },
            },
            {
              email: {
                containsi: value,
              },
            },
            {
              phoneNumber: {
                containsi: value,
              },
            },
          ],
        },
        filterLeads: {
          or: [
            {
              fullName: {
                containsi: value,
              },
            },
            {
              email: {
                containsi: value,
              },
            },
            {
              phoneNumber: {
                containsi: value,
              },
            },
          ],
        },
        pagination: {
          limit: 2,
        },
      },
    });
  };

  const defaultValue = useMemo(() => {
    if (initialValue) {
      return `${
        initialValue.contact
          ? 'contact'
          : initialValue.lead
          ? 'lead'
          : 'company'
      }:${initialValue.contact || initialValue.company || initialValue.lead}`;
    }
    return undefined;
  }, [initialValue]);

  return (
    <SearchSelect<string, CustomOption>
      placeholder={'Select Contact'}
      options={[...individualContacts, ...companyContacts, ...leadsContacts]}
      optionLabelProp={'title'}
      onChange={handleChange}
      onSearch={handleSearch}
      defaultValue={defaultValue}
      allowClear
      loading={loading}
      style={{ width: '100%' }}
      className={styles.select}
      onClear={onClear}
      bordered={!isCalendar}
    />
  );
};
