import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { ShankStyleInput } from 'src/components/inventory/inventoryManagement/products/inputs/ShankStyleInput';
import { SizeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SizeInput';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';

export const RingFilterCustomFields: FC<SalesItemReportFilterCustomFields> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Ring Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'size'} label={'Ring Size'} style={customStyles}>
          <SizeInput
            onChange={handleChange('size')}
            initialValue={initialValues?.size ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'shankStyle'}
          label={'Ring Shank Style'}
          style={customStyles}
        >
          <ShankStyleInput
            onChange={handleChange('shankStyle')}
            initialValue={initialValues?.shankStyle ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
