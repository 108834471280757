import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';
import { FC, lazy } from 'react';

const LocationPage = lazy<FC>(() => import('@pages/location/index'));

const { index } = ROUTES.tenant.location;

export const locationRoutes: RouteObject = {
  Component: LocationPage,
  path: index,
};
