import { FC } from 'react';

import { Select, SelectProps } from 'antd';

import { OrderType } from '@helpers/enumTypes';
import capitalize from 'lodash/capitalize';
import { OrderTypeSelectOption } from './OrderTypeSelectOption';

export const OrderTypeSelect: FC<SelectProps> = (props) => {
  const typesItems = Object.values(OrderType)?.map((type) => ({
    value: type,
    label: <OrderTypeSelectOption label={capitalize(type) as OrderType} />,
  }));

  return (
    <Select
      defaultValue={props.defaultValue ?? undefined}
      placeholder={'Pick order type'}
      optionLabelProp={'label'}
      options={typesItems}
      style={{ width: '100%' }}
      {...props}
    />
  );
};
