import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import { FC } from 'react';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
}

const options: EnumMaintenanceStatus[] = ['Completed', 'Waiting', 'Issue'];

export const MaintenanceStatusSelect: FC<Props> = ({
  onChange,
  initValue,
  ...props
}) => {
  const items = options?.map((option) => ({
    value: option,
    label: option,
  }));

  return (
    <Select
      defaultValue={initValue}
      placeholder={'Select the Maintenance Status'}
      onChange={onChange}
      options={items}
      {...props}
    />
  );
};
