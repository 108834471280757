import { ROUTES } from '@router/routes';
import { CustomLink } from '@ui/link/CustomLink';
import { Layout, Typography } from 'antd';
import { FC } from 'react';

const { Title, Paragraph } = Typography;

const Terms: FC = () => {
  return (
    <Layout style={{ padding: '0 50px', marginTop: '20px' }}>
      <Title level={2}>Welcome!</Title>
      <Paragraph>
        These Terms and Conditions (Terms) govern your use of our website (the
        Service). By accessing or using the Service, you agree to comply with
        and be bound by these Terms. If you disagree with any part of the Terms,
        please refrain from using our Service.
      </Paragraph>

      <Title level={3}>1. User Conduct</Title>
      <Paragraph>
        You agree to use the Service in accordance with applicable laws and
        regulations. You are responsible for all content posted or transmitted
        through your account and must not engage in any prohibited activities.
      </Paragraph>

      <Title level={3}>2. Intellectual Property</Title>
      <Paragraph>
        The content and materials on the Service, including text, graphics,
        logos, and images, are the intellectual property of your company. You
        may not use, reproduce, or distribute them without our explicit
        permission.
      </Paragraph>

      <Title level={3}>3. User Accounts</Title>
      <Paragraph>
        If you create an account, you are responsible for maintaining its
        confidentiality. You must notify us immediately of any unauthorized use
        or security breaches.
      </Paragraph>

      <Title level={3}>4. Limitation of Liability</Title>
      <Paragraph>
        We are not liable for any damages, losses, or legal claims arising out
        of your use of the Service. This includes but is not limited to direct,
        indirect, incidental, or consequential damages.
      </Paragraph>

      <Title level={3}>5. Termination</Title>
      <Paragraph>
        We reserve the right to terminate or suspend your access to the Service
        at our discretion, without prior notice, for any reason, including a
        breach of these Terms.
      </Paragraph>

      <Title level={3}>6. Privacy</Title>
      <Paragraph>
        Your privacy is important to us. Please review our
        <CustomLink to={`/${ROUTES.global.privacyPolicy}`}>
          Privacy Policy
        </CustomLink>
        to understand how we collect, use, and safeguard your personal
        information.
      </Paragraph>

      <Title level={3}>7. Governing Law</Title>
      <Paragraph>
        These Terms are governed by the laws of jurisdiction. Any legal actions
        must be brought in the courts located within jurisdiction.
      </Paragraph>

      <Title level={3}>8. Changes to Terms</Title>
      <Paragraph>
        We may update these Terms at any time, and it is your responsibility to
        check for changes. Continued use of the Service after modifications
        constitutes acceptance of the revised Terms.
      </Paragraph>

      <Title level={3}>9. Contact Us</Title>
      <Paragraph>
        For questions or concerns about these Terms, please contact us at
        contact information
      </Paragraph>
    </Layout>
  );
};

export default Terms;
