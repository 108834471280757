import { useMemo } from 'react';

import {
  useCompanyByUuidQuery,
  useContactByUuidQuery,
  useLeadByUuidQuery,
} from '@/graphql';

import { CrmEntityCollectionType } from '@crm/types';

export function useGetEntityByUuid<T extends CrmEntityCollectionType>(
  dataType: T,
  uuid: string,
) {
  const getData = useMemo(
    () =>
      ({
        leads: useLeadByUuidQuery,
        contacts: useContactByUuidQuery,
        companies: useCompanyByUuidQuery,
      })[dataType],
    [dataType],
  );

  const { data, loading, error, refetch } = getData({ variables: { uuid } });

  return { data, loading, error, refetch };
}
