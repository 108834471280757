import {
  FilterFunction,
  InventoryProductFilterInput,
  LocationSublocationFilterInput,
} from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';

export const createProductBarcodeFilter: FilterFunction<string> = (
  barcode?: string,
): ProductFiltersInput => {
  return barcode
    ? {
        id: {
          eq: barcode,
        },
      }
    : {};
};

export const createInventoryProductItemFilter: FilterFunction<
  InventoryProductFilterInput
> = ({
  productId,
  conditionType,
  genderType,
  process,
  metalFinishType,
  platting,
  metalType,
  materialGradeType,
  engravingType,
  timePeriod,
  productType,
  specificType,
  size,
  shankStyle,
  designStyle,
  backing,
  strand,
  strandsLength,
  knotStyle,
  linkStyle,
  linkType,
  brand,
  country,
  boxPaper,
  pieces,
} = {}): ProductFiltersInput => {
  const isProductFilters =
    productId ||
    conditionType ||
    genderType ||
    process ||
    metalFinishType ||
    platting ||
    metalType ||
    engravingType ||
    timePeriod ||
    productType;

  return isProductFilters
    ? ({
        id: {
          eq: productId,
        },
        conditionType: {
          id: {
            eq: conditionType,
          },
        },
        genderType: {
          id: {
            eq: genderType,
          },
        },
        process: {
          id: {
            eq: process,
          },
        },
        metalFinishType: {
          id: {
            eq: metalFinishType,
          },
        },
        platting: {
          id: {
            eq: platting,
          },
        },
        metalType: {
          id: {
            eq: metalType,
          },
        },
        materialGradeType: {
          id: {
            eq: materialGradeType,
          },
        },
        engravingType: {
          id: {
            eq: engravingType,
          },
        },
        timePeriod: {
          id: {
            eq: timePeriod,
          },
        },
        productType: {
          id: {
            eq: productType,
          },
        },
        specificType: {
          id: {
            eq: specificType,
          },
        },
        size: {
          id: {
            eq: size,
          },
        },
        shankStyle: {
          id: {
            eq: shankStyle,
          },
        },
        designStyle: {
          id: {
            eq: designStyle,
          },
        },
        backing: {
          id: {
            eq: backing,
          },
        },
        strand: {
          id: {
            eq: strand,
          },
        },
        strandsLength: {
          id: {
            eq: strandsLength,
          },
        },
        knotStyle: {
          id: {
            eq: knotStyle,
          },
        },
        linkStyle: {
          id: {
            eq: linkStyle,
          },
        },
        linkType: {
          id: {
            eq: linkType,
          },
        },
        brand: {
          id: {
            eq: brand,
          },
        },
        country: {
          id: {
            eq: country,
          },
        },
        boxPaper: {
          id: {
            eq: boxPaper,
          },
        },
        pieces: {
          id: {
            eq: pieces,
          },
        },
      } as ProductFiltersInput)
    : {};
};

export const createInventoryProductCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};

export const createBusinessLocationProductFilter: FilterFunction<
  LocationSublocationFilterInput
> = ({ locationIds, sublocation } = {}): ProductFiltersInput => {
  return locationIds?.length !== 0 || sublocation
    ? ({
        productInventoryItems: {
          businessLocation: {
            id: {
              in: locationIds,
            },
          },
          sublocation: {
            id: {
              eq: sublocation,
            },
          },
        },
      } as ProductFiltersInput)
    : {};
};
