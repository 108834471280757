import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { CompanySelect } from '@ui/form/CompanySelect';
import ContactsSelect from '@ui/form/ContactsSelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';
import { FC } from 'react';

export const MemoOutAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      label={`Product Name`}
      name={'productId'}
      defaultOpen={!!values?.productId}
    >
      <ProductSelect value={values?.productId} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={`Contact`}
      name={'contactId'}
      defaultOpen={!!values?.contactId}
      withDivider
    >
      <ContactsSelect
        value={values?.contactId}
        defaultValue={values?.contactId}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={`Company`}
      name={'companyId'}
      defaultOpen={!!values?.companyId}
      withDivider
    >
      <CompanySelect value={values?.companyId} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Sent Date'}
      name={'sentDate'}
      defaultOpen={!!values?.sentDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} value={values?.sentDate} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Expiry Date'}
      name={'expiryDate'}
      defaultOpen={!!values?.expiryDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} value={values?.expiryDate} />
    </ExpandableFormItem>
  </>
);
