import { FC } from 'react';

import { ShoppingOutlined } from '@ant-design/icons';

import CustomAvatar, { AvatarSize } from '@ui/avatar/index';

interface Props {
  src?: string;
}
export const ProductAvatar: FC<Props> = ({ src }) => (
  <CustomAvatar
    src={src}
    icon={!src && <ShoppingOutlined />}
    size={AvatarSize.Medium}
  />
);
