import { FC } from 'react';

import { Select } from 'antd';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { DealStages } from '@crm/profile/deals/types';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { AllCustomersSelect } from 'src/components/ui/form/AllCustomersSelect';

export const DealsAdditionalFields: FC<
  AdditionalFieldProps & { entityName: string }
> = ({ values, entityName }) => {
  const dealStagesArray = Object.values(DealStages).map((value) => ({
    value,
    label: value,
  }));

  return (
    <>
      {entityName === 'deals-profile' && (
        <ExpandableFormItem
          name={'stage'}
          label={'Stage'}
          defaultOpen={!!values?.stage}
          withDivider
        >
          <Select
            options={dealStagesArray}
            placeholder={'Select stage'}
            defaultActiveFirstOption
          />
        </ExpandableFormItem>
      )}
      <ExpandableFormItem
        label={'Start Date'}
        name={'startDate'}
        defaultOpen={!!values?.startDate}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
      {entityName === 'deals-management' && (
        <ExpandableFormItem
          label={'Contact'}
          name={'allContacts'}
          defaultOpen={!!values?.allContacts}
          withDivider
        >
          <AllCustomersSelect />
        </ExpandableFormItem>
      )}
    </>
  );
};
