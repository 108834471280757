import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Scheduling = lazy<FC>(() => import('@/pages/scheduling'));
const Employee = lazy<FC>(() => import('@/pages/scheduling/employee'));
const Appointments = lazy<FC>(() => import('@pages/scheduling/appointments'));
const Resources = lazy<FC>(() => import('@/pages/scheduling/resources'));
const UpcomingAppointments = lazy<FC>(
  () => import('@/pages/scheduling/upcomingAppointments'),
);

const { index, employee, upcomingAppointments, resources, appointments } =
  ROUTES.tenant.scheduling;

export const schedulingRoutes: RouteObject = {
  Component: Scheduling,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={employee} />,
    },
    {
      Component: Employee,
      path: employee,
    },
    {
      Component: Appointments,
      path: appointments,
    },
    {
      Component: Resources,
      path: resources,
    },
    {
      Component: UpcomingAppointments,
      path: upcomingAppointments,
    },
  ],
};
