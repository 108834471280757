import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import CRMDeals from '@pages/crm/deals';

import { ROUTES } from '@router/routes';

const CRM = lazy<FC>(() => import('@/pages/crm'));
const CRMDashboard = lazy<FC>(
  () => import(/* crm-module-dashboard */ '@/pages/crm/dashboard'),
);
const CrmEntity = lazy<FC>(() => import('@/pages/crm/entity'));
const Profile = lazy<FC>(() => import('@/pages/crm/profile'));
const ProfileActivity = lazy<FC>(() => import('@/pages/crm/profileActivity'));
const Note = lazy<FC>(() => import('@/pages/crm/note'));
const DealManagement = lazy<FC>(() => import('@/pages/crm/dealManagement'));
const CRMOrders = lazy<FC>(() => import('@/pages/crm/orders'));
const Files = lazy<FC>(() => import('@/pages/crm/files'));

const { index, contacts, leads, companies, dealManagement } = ROUTES.tenant.crm;

export const CRMRoutes: RouteObject = {
  Component: CRM,
  path: index,
  children: [
    {
      Component: CRMDashboard,
      index: true,
    },
    {
      Component: CrmEntity,
      path: contacts,
      children: [
        {
          Component: Profile,
          path: ':uuid',
          children: [
            {
              Component: ProfileActivity,
              index: true,
            },
            {
              Component: Note,
              path: 'notes',
            },
            {
              Component: Note,
              path: 'notes',
            },
            {
              Component: CRMOrders,
              path: 'orders',
            },
            {
              Component: CRMDeals,
              path: 'deals',
            },
            {
              Component: Files,
              path: 'files',
            },
          ],
        },
      ],
    },
    {
      Component: CrmEntity,
      path: leads,
      children: [
        {
          Component: Profile,
          path: ':uuid',
          children: [
            {
              Component: ProfileActivity,
              index: true,
            },
            {
              Component: Note,
              path: 'notes',
            },
            {
              Component: CRMOrders,
              path: 'orders',
            },
            {
              Component: CRMDeals,
              path: 'deals',
            },
            {
              Component: Files,
              path: 'files',
            },
          ],
        },
      ],
    },
    {
      Component: CrmEntity,
      path: companies,
      children: [
        {
          Component: Profile,
          path: ':uuid',
          children: [
            {
              Component: ProfileActivity,
              index: true,
            },
            {
              Component: Note,
              path: 'notes',
            },
            {
              Component: CRMOrders,
              path: 'orders',
            },
            {
              Component: CRMDeals,
              path: 'deals',
            },
            {
              Component: Files,
              path: 'files',
            },
          ],
        },
      ],
    },
    {
      Component: DealManagement,
      path: dealManagement,
    },
  ],
};
