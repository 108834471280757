import { useMeQuery } from '@/graphql';
import { useEffect, useState } from 'react';
import { socket } from './index';

export const useSocket = () => {
  const { data } = useMeQuery();

  const { id } = data?.me || {};

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (id && !connected) {
      socket.auth = { userId: id };
      socket.connect();
      setConnected(true);
    }

    return () => {
      if (connected) {
        socket.disconnect();
        setConnected(false);
      }
    };
  }, [id, connected]);

  return socket;
};
