import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Downloads = lazy<FC>(() => import('@pages/downloads/index'));

const downloads = ROUTES.tenant.downloads;

export const downloadsRoute: RouteObject = {
  path: downloads,
  Component: Downloads,
};
