import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Space } from 'antd';

import { usePlatformQuery } from '@/graphql';

import { usePlatformRoutes } from '@router/routes';

import styles from './Logo.module.scss';

interface LogoProps {
  minified?: boolean;
  hasLink?: boolean;
}

const Logo: FC<LogoProps> = ({ minified, hasLink }) => {
  const { data } = usePlatformQuery();

  const { attributes } = data?.platform?.data || {};

  const logo = minified ? attributes?.minifiedLogo : attributes?.logo;

  const { url, alternativeText } = logo?.data?.attributes || {};

  const routes = usePlatformRoutes();

  return (
    <Space direction={'horizontal'} align={'center'}>
      {hasLink ? (
        <Link to={routes.index}>
          <img
            src={url}
            alt={alternativeText || 'Logo'}
            className={styles.logo}
          />
        </Link>
      ) : (
        <img src={url} alt={alternativeText || 'Logo'} />
      )}
    </Space>
  );
};

export { Logo };
