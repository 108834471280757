import { useNavigate } from 'react-router';

import { useLoginMutation } from '@/graphql';

import { usePlatformRoutes } from '@router/routes';

import { setAuthToken } from '@helpers/auth';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

export const useLogin = (): ReturnType<typeof useLoginMutation> => {
  const message = useStatusMessage();
  const navigate = useNavigate();

  const routes = usePlatformRoutes();

  return useLoginMutation({
    onCompleted: (data) => {
      if (data?.login?.jwt) {
        setAuthToken(data?.login?.jwt);
        navigate(routes.onboarding);
      }
    },
    onError: (error, clientOptions) => {
      if (error.message.includes('Your account email is not confirmed')) {
        message.open('error', 'Your account email is not confirmed');

        navigate(routes.auth.notConfirmed, {
          state: {
            email: clientOptions?.variables?.input?.identifier,
          },
        });
      } else {
        message.open('error', 'Invalid email or password, please try again');
      }
    },
  });
};
