import { ROUTES } from '@router/routes';
import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const InventoryAudit = lazy<FC>(
  () => import('@pages/inventory/inventory-audit/index'),
);

const CreateAuditForm = lazy<FC>(
  () => import('@inventory/inventoryAudit/forms/CreateAuditForm'),
);

const InventoryAuditDetails = lazy<FC>(
  () =>
    import(
      '@inventory/inventoryAudit/inventoryAuditDetails/InventoryAuditDetails'
    ),
);

const { index, create } = ROUTES.tenant.inventory.inventoryAudit;
export const inventoryAuditRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: InventoryAudit,
    },
    {
      path: create,
      Component: CreateAuditForm,
    },
    {
      path: ':uuid',
      Component: InventoryAuditDetails,
    },
  ],
};
