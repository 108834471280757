import { NotificationItemAvatarContent } from '@components/notifications/NotificationItem/NotificationItemAvatarContent/NotificationItemAvatarContent';
import { useTenantRoutes } from '@router/routes';
import { CustomButton } from '@ui/button/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  hasBeenSeen?: boolean;
}

export const NylasExpireNotification: FC<Props> = ({ hasBeenSeen }) => {
  const {
    settings: {
      integrations: { nylas },
    },
  } = useTenantRoutes();
  const navigate = useNavigate();

  const navigateToNylas = () => {
    navigate(nylas);
  };

  return (
    <NotificationItemAvatarContent
      src={''}
      title={'Your Nylas Grant has been expired'}
      description={'Please re-authenticate'}
      hasBeenSeen={hasBeenSeen}
    >
      <CustomButton type={'primary'} onClick={navigateToNylas} size={'small'}>
        Auth with Nylas
      </CustomButton>
    </NotificationItemAvatarContent>
  );
};
