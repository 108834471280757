import { FC, useEffect, useState } from 'react';

import { Flex } from 'antd';
import { SelectProps } from 'antd/lib';

import { useMembershipsQuery } from '@/graphql';

import { formatToCurrency } from '@helpers/formatter';

import AvatarInfo from '@ui/avatar/AvatarInfo';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

const buildMembershipInfo = (membership: MembershipFragment) => (
  <Flex vertical gap={4}>
    <div>Price: {formatToCurrency(membership?.attributes?.price)}</div>
  </Flex>
);

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
  selectedIds?: Maybe<string>[];
}

export const MembershipSelect: FC<Props> = ({
  onChange,
  initValue,
  value,
  selectedIds,
  ...props
}) => {
  const [filters, setFilters] = useState<MembershipFiltersInput>({});

  const { data, refetch, loading } = useMembershipsQuery({
    variables: {
      filters: {
        ...filters,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const memberships = data?.memberships?.data || [];

  const membershipItems = memberships?.map((membership) => ({
    value: membership?.id,
    label: (
      <AvatarInfo
        title={membership?.attributes?.name}
        description={buildMembershipInfo(membership)}
        isProduct
      />
    ),
    title: membership?.attributes?.name,
    disabled: selectedIds?.includes(membership?.id),
  }));

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      defaultValue={initValue}
      placeholder={props.placeholder || 'Select the membership'}
      onChange={onChange}
      options={membershipItems}
      loading={loading}
      onSearch={handleSearch}
      value={value}
      optionLabelProp={'title'}
      {...props}
    />
  );
};
