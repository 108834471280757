import { FilterFunction } from '@filters/types';

export const createBusinessLocationFilter: FilterFunction<string[]> = (
  businessLocations?: string[],
): ResourceInventoryItemFiltersInput => {
  return businessLocations?.length !== 0
    ? {
        store: {
          id: {
            in: businessLocations,
          },
        },
      }
    : {};
};

export const createResourceFilter: FilterFunction<string[]> = (
  resources?: string[],
): ResourceInventoryItemFiltersInput => {
  return resources?.length !== 0
    ? {
        resource: {
          id: {
            in: resources,
          },
        },
      }
    : {};
};

export const createMaintainedByFilter: FilterFunction<string[]> = (
  maintainedBy?: string[],
): MaintenanceFiltersInput => {
  return maintainedBy?.length
    ? {
        maintainedBy: {
          id: {
            in: maintainedBy,
          },
        },
      }
    : {};
};
