import { FC, memo, useMemo } from 'react';

import { MenuProps, Radio } from 'antd';

import { CaretDownOutlined } from '@ant-design/icons';

import { Loader } from '@components/layout/MainLayout';
import { useStoreContext } from '@components/stores/context/useStoreContext';
import styles from '@components/stores/dropdown/StoresDropdown.module.scss';

import { useTenantRoutes } from '@router/routes';

import { Icon } from '@assets/icon';

import RadioCard from '@ui/card/RadioCard';
import CustomDropdown from '@ui/dropdown';

import { useMeQuery } from '@/graphql';
import { Search } from '@form/inputs/search/Search';

const allStoresItem = {
  key: 'all',
  label: <RadioCard value={'all'} label={'All Stores'} />,
};

export const StoresDropdown: FC = memo(() => {
  const {
    selectedStoreId,
    allStores,
    handleChange,
    handleSearch,
    loading,
    hasAllStoresOption,
  } = useStoreContext();

  const { data: meData } = useMeQuery();

  const userLocationsIds = meData?.me?.attributes?.businessLocation?.data.map(
    (location) => location.id,
  );
  const userRole = meData?.me?.attributes?.role?.data?.attributes?.name || '';

  const storesItems: MenuProps['items'] = useMemo(
    () =>
      allStores
        .filter(
          ({ id }) => userRole === 'Owner' || userLocationsIds?.includes(id),
        )
        .map(({ id, attributes }, index) => {
          return {
            key: id || String(index),
            label: (
              <RadioCard
                value={id ?? String(index)}
                label={attributes?.name || 'Store'}
                address={attributes?.location?.data?.attributes?.address}
                businessLocationId={attributes?.businessLocationId}
              />
            ),
          };
        }) || [],
    [allStores, userLocationsIds, userRole],
  );

  const items: MenuProps['items'] = useMemo(
    () => (hasAllStoresOption ? [allStoresItem, ...storesItems] : storesItems),
    [hasAllStoresOption, storesItems],
  );

  const selectedStoreName = useMemo(() => {
    return (
      allStores?.find(({ id }) => id === selectedStoreId)?.attributes?.name ||
      'All stores'
    );
  }, [allStores, selectedStoreId]);

  const routes = useTenantRoutes();

  return (
    <CustomDropdown
      menu={{
        items,
        selectedKeys: [selectedStoreId || 'all'],
        selectable: true,
        onSelect: (e) => handleChange(e.key),
      }}
      renderContent={(menu) => (
        <>
          <Search onSearch={handleSearch} loading={loading} />
          {loading ? (
            <Loader style={{ width: '100%' }} />
          ) : (
            <Radio.Group
              value={selectedStoreId || 'all'}
              style={{ width: '100%' }}
            >
              {menu}
            </Radio.Group>
          )}
        </>
      )}
      contentClassName={styles.contentRadio}
      className={styles.dropdown}
      titleProps={{
        title: 'Your Stores',
        link: {
          to: routes.dashboard.stores,
          linkText: 'See all',
        },
      }}
      height={375}
    >
      <Icon type={'shop'} size={24} />
      {selectedStoreName}
      <CaretDownOutlined className={styles.caret} />
    </CustomDropdown>
  );
});
