import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import AuthLayout from '@components/layout/AuthLayout';
import { Loader } from '@components/layout/MainLayout';

import { ROUTES } from '@router/routes';

import AuthRoutes from '@auth/AuthRoutes';
import { GoogleAuthCallback } from '@auth/oAuth/GoogleAuthCallback';

const SignIn = lazy<FC>(() => import('@/pages/auth/sign-in'));
const SignUp = lazy<FC>(() => import('@/pages/auth/sign-up'));
const NotConfirmed = lazy<FC>(() => import('@/pages/auth/not-confirmed'));
const EmailConfirmation = lazy<FC>(
  () => import('@/pages/auth/email-confirmation'),
);
const ForgotPassword = lazy<FC>(() => import('@/pages/auth/forgot-password'));
const ConfirmCode = lazy<FC>(
  () => import('@/pages/auth/reset-password/confirm-code'),
);
const CreatePasswordPage = lazy<FC>(
  () => import('@/pages/auth/reset-password/create-password'),
);

const {
  index,
  signUp,
  signIn,
  google,
  emailConfirmation,
  notConfirmed,
  forgotPassword,
  resetPassword,
} = ROUTES.global.auth;

const authRoutes: RouteObject = {
  Component: AuthRoutes,
  path: index,
  children: [
    {
      Component: AuthLayout,
      loader: () => <Loader spinning />,
      children: [
        {
          Component: SignIn,
          index: true,
        },
        {
          Component: SignUp,
          path: signUp,
        },
        {
          Component: SignIn,
          path: signIn,
        },
        {
          Component: GoogleAuthCallback,
          path: google,
        },
        {
          Component: EmailConfirmation,
          path: emailConfirmation,
        },
        {
          Component: NotConfirmed,
          path: notConfirmed,
        },
        {
          Component: ForgotPassword,
          path: forgotPassword,
        },
        {
          path: resetPassword.index,
          children: [
            {
              index: true,
              Component: ConfirmCode,
            },
            {
              path: resetPassword.createPassword,
              Component: CreatePasswordPage,
            },
          ],
        },
      ],
    },
  ],
};

export default authRoutes;
