import { FilterFieldType } from '@filters/types';
import { AnyObject } from '@helpers/types';

export const getIsProductFilterOpen = (
  values: (FilterFieldType & AnyObject<any>) | null | undefined,
): boolean => {
  return !!(
    values?.productId ||
    values?.conditionType ||
    values?.genderType ||
    values?.process ||
    values?.metalFinishType ||
    values?.platting ||
    values?.metalType ||
    values?.engravingType ||
    values?.timePeriod ||
    values?.productType
  );
};
