import styles from '@components/notifications/NotificationItem/NotificationItemAvatarContent/NotificationItemAvatarContent.module.scss';
import { useTenantRoutes } from '@router/routes';
import { CustomButton } from '@ui/button/Button';
import TextInfoBlock from '@ui/text-info-block';
import { Flex, Row } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  notification: Maybe<MaintenanceQuantityNotificationFragment>;
  hasBeenSeen?: boolean;
}

export const MaintenanceQuantityNotification: FC<Props> = ({
  notification,
  hasBeenSeen,
}) => {
  const { type, maintenance } = notification?.attributes || {};
  const resource =
    maintenance?.data?.attributes?.resourceInventoryItem?.data?.attributes
      ?.resource || {};

  const name = resource?.data?.attributes?.name;
  const message =
    type === 'NoLeft' ? (
      <>{`${name} is overdue for maintenance`}</>
    ) : (
      <>{`Left 1 day for maintenance of ${name}`}</>
    );

  const {
    inventory: {
      maintenance: { index },
    },
  } = useTenantRoutes();
  const navigate = useNavigate();
  const navigateToMaintenance = () => {
    navigate(`${index}/${maintenance?.data?.attributes?.uuid}`);
  };
  return (
    <Flex vertical gap={10}>
      <Row>
        <TextInfoBlock title={message} titleFontSize={12} />
        {!hasBeenSeen && <div className={styles.circle} />}
      </Row>
      <CustomButton
        type={'primary'}
        onClick={navigateToMaintenance}
        size={'small'}
        style={{ width: 150 }}
      >
        View Maintenance
      </CustomButton>
    </Flex>
  );
};
