import { useUserNotificationsLazyQuery } from '@/graphql';
import { useCallback, useState } from 'react';

const DEFAULT_PAGE_SIZE = 5;
export const useNotificationsData = () => {
  const [activePageSize, setActivePageSize] = useState(DEFAULT_PAGE_SIZE);

  const [fetchNotifications, { data, refetch, loading }] =
    useUserNotificationsLazyQuery({
      variables: {
        sort: ['createdAt:desc'],
        pagination: {
          limit: DEFAULT_PAGE_SIZE,
        },
      },
      fetchPolicy: 'network-only',
    });

  const hasMoreItems =
    (data?.userNotifications?.meta?.pagination?.total || 0) >
    (data?.userNotifications?.data?.length || 0);

  const fetchMore = useCallback(() => {
    if (!hasMoreItems) {
      return;
    }

    setActivePageSize((prev) => {
      const pageSize = prev + DEFAULT_PAGE_SIZE;

      refetch({
        pagination: {
          limit: pageSize,
        },
      });

      return pageSize;
    });
  }, [hasMoreItems, refetch]);

  const refetchData = useCallback(() => {
    refetch({
      pagination: {
        limit: activePageSize,
      },
    });
  }, [activePageSize, refetch]);

  const fetchData = useCallback(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const resetPageSize = useCallback(() => {
    setActivePageSize(DEFAULT_PAGE_SIZE);
  }, []);

  return {
    data,
    loading,
    fetchData,
    fetchMore,
    refetchData,
    hasMoreItems,
    resetPageSize,
  };
};
