import { useUnreadNotificationCountQuery } from '@/graphql';

export const useUnreadNotificationsCount = () => {
  const { data: unreadCountData, refetch } = useUnreadNotificationCountQuery();
  const unreadCount = unreadCountData?.userNotifications?.meta.pagination.total;

  return {
    unreadCount,
    refetch,
  };
};
