import { getEntityIdWithRegex } from '@/components/selling/helpers/getOrderId';

import { ChartFilter, DealTransactionStatus, PaymentOption } from './types';

import { RecurringPeriod } from '@components/selling/pos/orders-list/OrderDetailsActions/types';
import { FilterFieldType } from '@filters/types';
import capitalize from 'lodash/capitalize';
export const switchCurrentSegmentTypes = (segment: string) => {
  switch (segment) {
    case 'Expense':
      return {
        type: {
          eq: 'cost',
        },
      };
    case 'Revenue':
      return {
        type: {
          eq: 'income',
        },
      };
    default:
      return {};
  }
};

export function transformArray(inputArray: DealTransactionFragment[]) {
  const transformedArray = [];
  let currentStatus = null;

  for (const item of inputArray) {
    // Check if openDate is a Date object
    const openDate = item.attributes?.createdAt?.toString()?.split('-');
    const stringWithoutZeros = openDate && parseInt(openDate[0], 10).toString();
    const yearMonth = openDate ? stringWithoutZeros + '-' + openDate[1] : null;
    // If the current status is different, add a new status object
    if (yearMonth !== currentStatus) {
      transformedArray.push({
        id: getEntityIdWithRegex(),
        attributes: { status: yearMonth },
      });
      currentStatus = yearMonth;
    }

    // Add the item to the transformed array
    transformedArray.push(item);
  }

  return transformedArray;
}

export const createChartFilter = (
  values: FilterFieldType & {
    status?: string | undefined;
    dueDate?: Date;
    chart?: string | undefined;
  },
): ChartFilter => {
  const chartFilter: ChartFilter = {};
  if (values.chart) {
    const chartArr = values.chart.split('-');

    if (+chartArr[2] && +chartArr[1] && +chartArr[0]) {
      chartFilter.chartSubcategory = {
        id: {
          eq: +chartArr[2] ? chartArr[2] : null,
        },
      };
    } else if (+chartArr[1] && +chartArr[0]) {
      chartFilter.chartCategory = {
        id: {
          eq: +chartArr[1] ? chartArr[1] : null,
        },
      };
    } else if (+chartArr[0]) {
      chartFilter.chartAccount = {
        id: {
          eq: +chartArr[0] ? chartArr[0] : null,
        },
      };
    }
  }
  return chartFilter;
};

export const PERIOD_OPTIONS = Object.values(RecurringPeriod).map((value) => ({
  value,
  label: capitalize(value),
}));

export const STATUS_OPTIONS = Object.values(DealTransactionStatus).map(
  (value) => ({
    value,
    label: value,
  }),
);

export const PAYMENT_OPTIONS = Object.values(PaymentOption).map((value) => ({
  value,
  label: value,
}));
