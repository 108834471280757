import { FilterFieldType, StoneFiltersType } from '@filters/types';
import { GetChangeHandler } from '@form/hooks/useCustomForm';
import { CustomFormItem } from '@form/item/FormItem';
import { AnyObject } from '@helpers/types';
import { Col } from 'antd';
import { CSSProperties, FC } from 'react';

import { GemstoneColorCustomFields } from '@filters/additionalFields/stoneCustomFileds/GemstoneColorCustomFields';
import { StoneWeightCustomInput } from '@filters/additionalFields/stoneCustomFileds/StoneWeigthCustomInput';
import { MultiplyStoneClaritySelect } from '@inventory/inventoryManagement/products/inputs/ClarityInput/MultiplyStoneClaritySelect';
import { MultiplyClarityTypeSelect } from '@inventory/inventoryManagement/products/inputs/ClarityTypeInput/MultiplyClarityTypeSelect';
import { MultiplyStoneCutSelect } from '@inventory/inventoryManagement/products/inputs/CutInput/MultiplyStoneCutSelect';
import { MultiplyStoneFluorescenceSelect } from '@inventory/inventoryManagement/products/inputs/FluorescenceInput/MultiplyStoneFluorescenceSelect';
import { MultiplyStoneGradingReportSelect } from '@inventory/inventoryManagement/products/inputs/GradingReportInput/MultiplyStoneGradingReportSelect';
import { MultiplyStoneHueSelect } from '@inventory/inventoryManagement/products/inputs/HueInput/MultiplyStoneHueSelect';
import { MultiplyStoneCutStyleSelect } from '@inventory/inventoryManagement/products/inputs/StoneCutStyleInput/MultiplyStoneCutStyleSelect';
import { MultiplyStoneGeographicSelect } from '@inventory/inventoryManagement/products/inputs/StoneGeographicInput/MultiplyStoneGeographicSelect';
import { MultiplyStoneIntensitySelect } from '@inventory/inventoryManagement/products/inputs/StoneIntensityInput/MultiplyStoneIntensitySelect';
import { MultiplyStoneOriginSelect } from '@inventory/inventoryManagement/products/inputs/StoneOriginInput/MultiplyStoneOriginSelect';
import { MultiplyStoneShapeSelect } from '@inventory/inventoryManagement/products/inputs/StoneShapeInput/MultiplyStoneShapeSelect';
import { MultiplyStoneToneSelect } from '@inventory/inventoryManagement/products/inputs/StoneToneInput/MultiplyStoneToneSelect';
import { MultiplyStoneTransparencySelect } from '@inventory/inventoryManagement/products/inputs/StoneTransparencyInput/MultiplyStoneTransparencySelect';
import { MultiplyStoneTypeSelect } from '@inventory/inventoryManagement/products/inputs/StoneTypeInput/MultiplyStoneTypeSelect';
import { MultiplyStoneTreatmentSelect } from '@inventory/inventoryManagement/products/inputs/TreatmentInput/MultiplyStoneTreatmentSelect';

interface Props {
  handleChange: GetChangeHandler<StoneFiltersType>;
  initialValues?: (FilterFieldType & AnyObject<any>) | null | undefined;
  customStyles?: CSSProperties;
}

export const GemstoneCustomFields: FC<Props> = ({
  initialValues,
  customStyles,
  handleChange,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'stoneType'}
          label={'Stone Type'}
          style={customStyles}
        >
          <MultiplyStoneTypeSelect
            allowClear
            initialValue={initialValues?.stoneType ?? undefined}
            onChange={handleChange('stoneType')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'weight'} label={'Weight'} style={customStyles}>
          <StoneWeightCustomInput
            onChange={handleChange('weight')}
            initialValue={initialValues?.weight ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <GemstoneColorCustomFields
          getChangeHandler={handleChange}
          initialValues={initialValues?.color ?? undefined}
          type={'Other'}
          customStyles={customStyles}
        />
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'origin'}
          label={'Nature/Origin'}
          style={customStyles}
        >
          <MultiplyStoneOriginSelect
            allowClear
            initialValue={initialValues?.origin ?? undefined}
            onChange={handleChange('origin')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'stoneShape'}
          label={'Stone Shape'}
          style={customStyles}
        >
          <MultiplyStoneShapeSelect
            allowClear
            initialValue={initialValues?.stoneShape ?? undefined}
            type={'Other'}
            onChange={handleChange('stoneShape')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'cutStyleCrown'}
          label={'Cut Style: Crown'}
          style={customStyles}
        >
          <MultiplyStoneCutStyleSelect
            allowClear
            initialValue={initialValues?.cutStyleCrown ?? undefined}
            onChange={handleChange('cutStyleCrown')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'cutStylePavilion'}
          label={'Cut Style: Pavilion'}
          style={customStyles}
        >
          <MultiplyStoneCutStyleSelect
            allowClear
            initialValue={initialValues?.cutStylePavilion ?? undefined}
            onChange={handleChange('cutStylePavilion')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'transparency'}
          label={'Transparency'}
          style={customStyles}
        >
          <MultiplyStoneTransparencySelect
            allowClear
            initialValue={initialValues?.transparency ?? undefined}
            onChange={handleChange('transparency')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'geographic'}
          label={'Geographic Origin'}
          style={customStyles}
        >
          <MultiplyStoneGeographicSelect
            allowClear
            initialValue={initialValues?.geographic ?? undefined}
            onChange={handleChange('geographic')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'intensity'}
          label={'Saturation / Intensity'}
          style={customStyles}
        >
          <MultiplyStoneIntensitySelect
            allowClear
            initialValue={initialValues?.intensity ?? undefined}
            onChange={handleChange('intensity')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'tone'} label={'Tone'} style={customStyles}>
          <MultiplyStoneToneSelect
            allowClear
            initialValue={initialValues?.tone ?? undefined}
            onChange={handleChange('tone')}
          />
        </CustomFormItem>
      </Col>

      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceLong'}
          label={'Fluorescence (Long Wave)'}
          style={customStyles}
        >
          <MultiplyStoneFluorescenceSelect
            allowClear
            initialValue={initialValues?.fluorescenceLong ?? undefined}
            onChange={handleChange('fluorescenceLong')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceShort'}
          label={'Fluorescence (Short Wave)'}
          style={customStyles}
        >
          <MultiplyStoneFluorescenceSelect
            allowClear
            initialValue={initialValues?.fluorescenceShort ?? undefined}
            onChange={handleChange('fluorescenceLong')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceLongHue'}
          label={'Fluorescence Long Hue'}
          style={customStyles}
        >
          <MultiplyStoneHueSelect
            allowClear
            initialValue={initialValues?.fluorescenceLongHue ?? undefined}
            onChange={handleChange('fluorescenceLongHue')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceShortHue'}
          label={'Fluorescence Short Hue'}
          style={customStyles}
        >
          <MultiplyStoneHueSelect
            allowClear
            initialValue={initialValues?.fluorescenceShortHue ?? undefined}
            onChange={handleChange('fluorescenceShortHue')}
          />
        </CustomFormItem>
      </Col>

      <Col span={24}>
        <CustomFormItem name={'clarity'} label={'Clarity'} style={customStyles}>
          <MultiplyStoneClaritySelect
            allowClear
            initialValue={initialValues?.clarity ?? undefined}
            type={'Other'}
            onChange={handleChange('clarity')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'clarityType'}
          label={'Clarity Type'}
          style={customStyles}
        >
          <MultiplyClarityTypeSelect
            allowClear
            initialValue={initialValues?.clarityType ?? undefined}
            onChange={handleChange('clarityType')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'cut'} label={'Cut'} style={customStyles}>
          <MultiplyStoneCutSelect
            allowClear
            initialValue={initialValues?.cut ?? undefined}
            onChange={handleChange('cut')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'treatment'}
          label={'Enhancement/Treatments'}
          style={customStyles}
        >
          <MultiplyStoneTreatmentSelect
            allowClear
            initialValue={initialValues?.treatment ?? undefined}
            onChange={handleChange('treatment')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'gradingReportBy'}
          label={'Grading Report By'}
          style={customStyles}
        >
          <MultiplyStoneGradingReportSelect
            allowClear
            initialValue={initialValues?.gradingReportBy ?? undefined}
            type={'Other'}
            onChange={handleChange('gradingReportBy')}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
