import { useInventoryAuditItemsLazyQuery } from '@/graphql';
import { useDrawerFilter } from '@filters/hooks/useDrawerFilter';
import { useEntityTableState } from '@hooks/crm/useEntityTableState';
import { TableParams } from '@ui/table/types';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { HandleTableChangeType } from '../helpers/createTabsItems';

interface InventoryAuditItemsReturnType {
  data: InventoryAuditItemsQuery | undefined;
  loading: boolean;
  handleTableChange: HandleTableChangeType;
  handleTotalChange: () => void;
  tableParams: TableParams;
  queryParams: {
    pagination: {
      page?: number | undefined;
      pageSize: number | undefined;
    };
    sort?: string | string[];
  };
  handleToggleFilterDrawer: () => void;
  filters: InventoryAuditFiltersInput;
  rowSelection: { onChange: (selectedRowKeys: React.Key[]) => void };
}

export const useInventoryAuditItems = (): InventoryAuditItemsReturnType => {
  const params = useParams<{ uuid: string }>();

  const { handleToggleFilterDrawer, filters } =
    useDrawerFilter<InventoryAuditItemFiltersInput>({
      entityName: 'inventoryAuditDetails',
      withDefaultFilters: false,
    });

  const {
    rowSelection,
    tableParams,
    handleTableChange,
    queryParams,
    handleTotalChange,
  } = useEntityTableState<InventoryAuditFragment>();

  const [fetch, { data, loading, refetch }] = useInventoryAuditItemsLazyQuery({
    variables: {
      filters: {
        inventoryAudit: {
          uuid: { eq: params.uuid },
        },
        ...filters,
      },
      ...queryParams,
    },
  });

  useMemo(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    handleTotalChange(data?.inventoryAuditItems?.meta?.pagination.total);
  }, [data?.inventoryAuditItems?.meta?.pagination.total, handleTotalChange]);

  return {
    data,
    loading,
    handleTableChange,
    handleTotalChange,
    tableParams,
    queryParams,
    handleToggleFilterDrawer,
    filters,
    rowSelection,
  };
};
