import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { DesignStyleInput } from 'src/components/inventory/inventoryManagement/products/inputs/DesignStyleInput';
import { KnotStyleInput } from 'src/components/inventory/inventoryManagement/products/inputs/KnotStyleInput';
import { StrandInput } from 'src/components/inventory/inventoryManagement/products/inputs/StrandInput';

export const PearlBraceletFilterCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'knotStyle'}
          label={'Pearl Bracelet Knot Style'}
          style={customStyles}
        >
          <KnotStyleInput
            onChange={handleChange('knotStyle')}
            initialValue={initialValues?.knotStyle ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'designStyle'}
          label={'Design Style'}
          style={customStyles}
        >
          <DesignStyleInput
            onChange={handleChange('designStyle')}
            initialValue={initialValues?.designStyle ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'strand'}
          label={'Number of Strands'}
          style={customStyles}
        >
          <StrandInput
            onChange={handleChange('strand')}
            initialValue={initialValues?.strand ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
