import { FilterFunction } from '@filters/types';

export const createCampaignOwnerFilter: FilterFunction<string[]> = (
  campaignOwner?: string[],
): CampaignFiltersInput => {
  return campaignOwner?.length !== 0
    ? {
        campaignOwner: {
          id: {
            in: campaignOwner,
          },
        },
      }
    : {};
};

export const createEnrolledContactsFilter: FilterFunction<string[]> = (
  contacts?: string[],
): CampaignFiltersInput => {
  return contacts?.length !== 0
    ? {
        enrolledContacts: {
          contact: {
            id: {
              in: contacts,
            },
          },
        },
      }
    : {};
};
