import { FC, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const InventoryAdjustment = lazy<FC>(
  () => import('@pages/inventory/inventory-adjustment/index'),
);

const CreateAdjustmentForm = lazy<FC>(
  () => import('@inventory/inventoryAdjustment/forms/CreateAdjustmentForm'),
);

const UpdateAdjustmentFrom = lazy<FC>(
  () => import('@inventory/inventoryAdjustment/forms/UpdateAdjustmentFrom'),
);

const { index, create } = ROUTES.tenant.inventory.inventoryAdjustment;
export const inventoryAdjustmentManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: InventoryAdjustment,
    },
    {
      path: create,
      Component: CreateAdjustmentForm,
    },
    {
      path: ':uuid',
      Component: UpdateAdjustmentFrom,
    },
  ],
};
