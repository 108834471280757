import { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';

import { useProfileContext } from '@/components/crm/profile/context/profileContext/useProfileContext';
import { CustomSegmented } from '@/components/ui/segmented';
import { useTenantRoutes } from '@/router/routes';

type Props = {
  defaultValue: 'Activity' | 'Notes' | 'Orders' | 'Deals' | 'Files';
};

const ProfileSegmented: FC<Props> = ({ defaultValue }) => {
  const {
    crm: { index },
  } = useTenantRoutes();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const ctx = useProfileContext();

  const mainPath = `${index}/${ctx}/${uuid}`;

  const onSegmentedChange = useCallback(
    (value: string | number) => {
      switch (value) {
        case 'Activity':
          navigate(mainPath);
          break;
        case 'Notes':
          navigate(`${mainPath}/notes`);
          break;
        case 'Orders':
          navigate(`${mainPath}/orders`);
          break;
        case 'Deals':
          navigate(`${mainPath}/deals`);
          break;
        case 'Files':
          navigate(`${mainPath}/files`);
          break;
        default:
          navigate(mainPath);
      }
    },
    [navigate, mainPath],
  );

  return (
    <CustomSegmented
      defaultValue={defaultValue}
      onChange={onSegmentedChange}
      options={['Activity', 'Notes', 'Orders', 'Deals', 'Files']}
    />
  );
};

export default ProfileSegmented;
