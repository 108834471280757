import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';

import { ContactsANDCompaniesSelect } from 'src/components/ui/form/ContactsANDCompaniesSelect';

export const InventoryInvoiceAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        name={'customer'}
        label={'Select contact or company'}
        defaultOpen={!!values?.customer}
      >
        <ContactsANDCompaniesSelect />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
