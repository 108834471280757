export const createCommonFilters = ({
  customer,
  campaignId,
}: {
  customer?: {
    contact: string | null;
    lead: string | null;
  };
  campaignId?: string;
}) => {
  return campaignId && !customer
    ? {
        or: [
          {
            enrolledContact: {
              campaign: {
                id: {
                  eq: campaignId,
                },
              },
            },
          },
          {
            enrolledLead: {
              campaign: {
                id: {
                  eq: campaignId,
                },
              },
            },
          },
        ],
      }
    : customer?.contact
    ? {
        enrolledContact: {
          contact: {
            id: {
              eq: customer.contact,
            },
          },
          campaign: {
            id: { eq: campaignId ?? undefined },
          },
        },
      }
    : customer?.lead
    ? {
        enrolledLead: {
          lead: {
            id: {
              eq: customer.lead,
            },
          },
          campaign: {
            id: { eq: campaignId ?? undefined },
          },
        },
      }
    : {};
};
