import { FC, useEffect, useMemo, useState } from 'react';

import { SelectProps } from 'antd/lib';

import { useCampaignsQuery } from '@/graphql';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

import { useCampaignIdDataSelect } from '@components/accounting/transactions/ui/selects/useCampaignIdDataSelect';

interface Props extends SelectProps {
  onChange?: (value: string) => void;
}

export const CampaignIdSelect: FC<Props> = ({ onChange, value, ...props }) => {
  const [valueLoading, setValueLoading] = useState(false);
  const { handleChange, handleSearch, queryParams } = useCampaignIdDataSelect({
    onChange,
  });

  const { data, refetch, loading } = useCampaignsQuery({
    variables: queryParams,
  });

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    setValueLoading(true);
  }, []);

  const options = useMemo(() => {
    const dealTransactionsData = data?.campaigns?.data || [];

    const transformedData = dealTransactionsData?.map((campaign) => ({
      value: campaign?.id,
      label: campaign?.attributes?.name,
    }));
    setValueLoading(false);
    return transformedData;
  }, [data?.campaigns?.data, setValueLoading]);

  return (
    <SearchSelect
      value={valueLoading ? 'Loading' : value}
      placeholder={'Select Campaign Name'}
      onChange={handleChange}
      options={options}
      loading={loading}
      disabled={valueLoading}
      onSearch={handleSearch}
    />
  );
};
