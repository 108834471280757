import { FC, lazy } from 'react';

import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const InvoicesPage = lazy<FC>(() => import('@pages/inventory/invoices/index'));
const UpdateInvoiceFrom = lazy<FC>(
  () => import('@inventory/invoices/forms/UpdateInvoiceFrom'),
);
const InvoiceView = lazy<FC>(
  () => import('@pages/inventory/invoices/invoiceView'),
);

const { index, preview } = ROUTES.tenant.inventory.invoices;
export const invoicesManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: InvoicesPage,
    },
    {
      path: `${preview}/:uuid`,
      Component: InvoiceView,
    },
    {
      path: ':uuid',
      Component: UpdateInvoiceFrom,
    },
  ],
};
