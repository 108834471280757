import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';

export const TaxesAdditionalFields: FC<AdditionalFieldProps> = ({ values }) => (
  <>
    <ExpandableFormItem
      name={'orderId'}
      defaultOpen={!!values?.orderId}
      label={`Order Id`}
    >
      <OrderIdSelect value={values?.orderId} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Date Created'}
      name={'createdAt'}
      defaultOpen={!!values?.createdAt}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} value={values?.createdAt} />
    </ExpandableFormItem>
  </>
);
