import { FC } from 'react';

import EmployeeSelect from '@ui/form/EmployeeSelect';
import LeadSourceSelect from '@ui/form/LeadSourceSelect';
import LeadStageSelect from '@ui/form/LeadStageSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';

export const LeadAdditionalFields: FC<AdditionalFieldProps> = ({ values }) => (
  <>
    <ExpandableFormItem
      name={'leadSource'}
      label={'Lead Source'}
      defaultOpen={!!values?.leadSource?.length}
      withDivider
    >
      <LeadSourceSelect
        mode={'multiple'}
        placeholder={'Filter leads by Lead Source'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadStage'}
      label={'Lead Stage'}
      defaultOpen={!!values?.leadStage?.length}
      withDivider
    >
      <LeadStageSelect
        mode={'multiple'}
        placeholder={'Filter leads by Lead Stage'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadOwner'}
      label={'Lead Owner'}
      defaultOpen={!!values?.leadOwner?.length}
      withDivider
    >
      <EmployeeSelect
        mode={'multiple'}
        shouldApplyDefaultValue={false}
        placeholder={'Filter leads by Lead Owner'}
      />
    </ExpandableFormItem>
  </>
);
