import { Loader } from '@components/layout/MainLayout';
import { useOnboarding } from '@components/onboarding/hooks/useOnboading';
import { usePlatformRoutes } from '@router/routes';
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const OnboardingLayout = () => {
  const { status, loading } = useOnboarding();

  const routes = usePlatformRoutes();
  if (loading) {
    return null;
  }

  if (status) {
    return <Navigate to={routes.dashboard.index} />;
  }

  return (
    <Suspense fallback={<Loader size={'small'} />}>
      <Outlet />
    </Suspense>
  );
};

export default OnboardingLayout;
