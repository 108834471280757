import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { CampaignIdSelect } from '@components/accounting/transactions/ui/selects/CampaignIdSelect';
import { AdditionalFieldProps } from '@filters/types';
import { ContactsANDLeadsSelect } from '@ui/form/ContactsANDLeadsSelect';

export const MarketingReportAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        label={'Customer'}
        name={'customer'}
        defaultOpen={!!values?.customer}
      >
        <ContactsANDLeadsSelect initialValue={values?.customer} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'campaignId'}
        defaultOpen={!!values?.campingId}
        label={`Campaign Name`}
      >
        <CampaignIdSelect />
      </ExpandableFormItem>
    </>
  );
};
