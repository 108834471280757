import { FC } from 'react';

import { Badge, ConfigProvider, Flex, Typography } from 'antd';
import { BaseType } from 'antd/es/typography/Base';

export type CustomBadgeStatusType =
  | 'default'
  | 'success'
  | 'processing'
  | 'error'
  | 'warning';

interface Props {
  text?: string;
  type: CustomBadgeStatusType;
  withoutText?: boolean;
  amount?: number | string;
}

const CustomBadge: FC<Props> = ({ text, type, withoutText, amount }) => {
  const textType: BaseType =
    type === 'processing' || type === 'default'
      ? 'secondary'
      : type === 'error'
      ? 'danger'
      : type;

  const badgeText = {
    default: 'Inactive',
    processing: 'Ongoing',
    success: 'Done',
    error: 'Cancelled',
    warning: 'Inactive',
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeHeading5: 12,
          fontWeightStrong: 500,
        },
      }}
    >
      <Flex gap={6}>
        <Badge status={type} />
        {!withoutText && (
          <Typography.Title level={5} type={textType}>
            {text ?? badgeText[type]}
            {amount && <span>{`(${amount})`}</span>}
          </Typography.Title>
        )}
      </Flex>
    </ConfigProvider>
  );
};

export default CustomBadge;
