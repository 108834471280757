import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Accounting = lazy<FC>(() => import('@/pages/accounting'));
const AccountDashboard = lazy<FC>(
  () => import('@/pages/accounting/account-dashboard'),
);
const Transactions = lazy<FC>(() => import('@/pages/accounting/transactions'));
const Charts = lazy<FC>(() => import('@pages/accounting/charts'));
const IncomeStatement = lazy<FC>(
  () => import('@/pages/accounting/income-statement'),
);
const Reminders = lazy<FC>(() => import('@/pages/accounting/reminders'));
const Taxes = lazy<FC>(() => import('@/pages/accounting/taxes'));

const { index, transactions, charts, incomeStatement, reminders, taxes } =
  ROUTES.tenant.accounting;

export const accountingRoutes: RouteObject = {
  Component: Accounting,
  path: index,
  children: [
    {
      Component: AccountDashboard,
      index: true,
    },
    {
      Component: Transactions,
      path: transactions,
    },
    {
      Component: Charts,
      path: charts,
    },
    {
      Component: IncomeStatement,
      path: incomeStatement,
    },
    {
      Component: Reminders,
      path: reminders,
    },
    {
      Component: Taxes,
      path: taxes,
    },
  ],
};
