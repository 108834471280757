import { FC, useCallback, useMemo } from 'react';

import { useStatusMessage } from '@/components/app/StatusMessageContext/statusMessageContext';
import DrawerFormWrapper from '@/components/crm/formWrapper';
import { getActivityRelationField } from '@/components/crm/profile/activity/helpers/getActivityRelationField';
import { useCreateDealMutation } from '@/graphql';

import { useCustomForm } from '@form/hooks/useCustomForm';

import DealInputsList from '@crm/profile/deals/DealInputsList';
import { DealInputWithAllContacts } from '@crm/profile/deals/EditDealForm/UpdateDealsForm';

export interface Props {
  onCancel: () => void;
  id?: string;
  context?: string;
  location: string;
}

const CreateDealForm: FC<Props> = ({ onCancel, id, context, location }) => {
  const [form] = useCustomForm<DealInputWithAllContacts>();
  const message = useStatusMessage();
  const [createDeal, { loading }] = useCreateDealMutation({
    refetchQueries: ['deals'],
  });

  const activityContext = useMemo(
    () => getActivityRelationField(context ?? ''),
    [context],
  );

  const handleCreateDeal = useCallback(
    async (variables: DealInputWithAllContacts) => {
      const { allContacts, ...rest } = variables;
      await createDeal({
        variables: {
          input: {
            ...rest,
            [activityContext?.slice(0, -3)]: id,
            ...allContacts,
          },
        },
        onCompleted: () => {
          message?.open('success', 'Deal created successfully!');
          form.resetFields();
          onCancel();
        },
        onError: () => message?.open('error'),
      });
    },
    [activityContext, createDeal, form, id, message, onCancel],
  );

  return (
    <DrawerFormWrapper<DealInputWithAllContacts>
      requiredMark={false}
      onFinish={handleCreateDeal}
      onCancel={onCancel}
      form={form}
      primaryAction={loading ? 'Loading' : `Add Deal`}
    >
      <DealInputsList location={location} />
    </DrawerFormWrapper>
  );
};

export default CreateDealForm;
