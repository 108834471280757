import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { MultiplyDesignStyleSelect } from '@inventory/inventoryManagement/products/inputs/DesignStyleInput/MultiplyDesignStyleSelect';
import { MultiplyKnotStyleSelect } from '@inventory/inventoryManagement/products/inputs/KnotStyleInput/MultiplyKnotStyleSelect';
import { MultiplyStrandSelect } from '@inventory/inventoryManagement/products/inputs/StrandInput/MultiplyStrandSelect';
import { MultiplyStrandsLengthSelect } from '@inventory/inventoryManagement/products/inputs/StrandsLengthInput/MultiplyStrandsLengthSelect';
import { Col } from 'antd';
import { FC } from 'react';

export const PearlFilterNecklaceCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'knotStyle'}
          label={'Knot Style'}
          style={customStyles}
        >
          <MultiplyKnotStyleSelect
            allowClear
            onChange={handleChange('knotStyle')}
            initialValue={initialValues?.knotStyle ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'strandsLength'}
          label={'Necklace Length'}
          style={customStyles}
        >
          <MultiplyStrandsLengthSelect
            allowClear
            onChange={handleChange('strandsLength')}
            initialValue={initialValues?.strandsLength ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'strand'}
          label={'Number of Strands'}
          style={customStyles}
        >
          <MultiplyStrandSelect
            allowClear
            onChange={handleChange('strand')}
            initialValue={initialValues?.strand ?? undefined}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'designStyle'}
          label={'Pearl Necklace Design style'}
          style={customStyles}
        >
          <MultiplyDesignStyleSelect
            allowClear
            onChange={handleChange('designStyle')}
            initialValue={initialValues?.designStyle ?? undefined}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
