import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

const Reports = lazy<FC>(() => import('@/pages/reports'));
const SalesReportsList = lazy<FC>(
  () => import('@pages/reports/salesReports/salesReportsList'),
);
const CustomersReportsList = lazy<FC>(
  () => import('@pages/reports/customersReports/customersReportsList'),
);
const InventoryReportsList = lazy<FC>(
  () => import('@pages/reports/inventoryReports/inventoryReportsList'),
);
const SalesReport = lazy<FC>(
  () => import('@pages/reports/salesReports/salesReport/salesReport'),
);
const SalesItemReport = lazy<FC>(
  () => import('@pages/reports/salesReports/salesItemReport/salesItemReport'),
);
const TaxesReport = lazy<FC>(
  () => import('@pages/reports/salesReports/taxesReport/taxesReport'),
);

const CustomersReport = lazy<FC>(
  () =>
    import('@pages/reports/customersReports/customerReport/customersReport'),
);

const MarketingReport = lazy<FC>(
  () =>
    import('@pages/reports/customersReports/marketingReport/marketingReport'),
);

const InventoryReport = lazy<FC>(
  () =>
    import('@pages/reports/inventoryReports/inventoryReport/inventoryReport'),
);

const MemoReport = lazy<FC>(
  () => import('@pages/reports/inventoryReports/memoReport/memoReport'),
);

const MemoOutReport = lazy<FC>(
  () => import('@pages/reports/inventoryReports/memoOutReport/memoOutReport'),
);

const {
  index,
  sales: { index: salesIndex, salesReport, salesItemReport, taxesReport },
  customers: { index: customerIndex, customersReport, marketingReport },
  inventory: {
    index: inventoryIndex,
    inventoryReport,
    memoReport,
    memoOutReport,
  },
} = ROUTES.tenant.reports;

export const reportsRoute: RouteObject = {
  path: index,
  Component: Reports,
  children: [
    {
      index: true,
      element: <Navigate to={salesIndex} />,
    },
    {
      Component: SalesReportsList,
      path: salesIndex,
      children: [
        {
          path: salesReport,
          Component: SalesReport,
        },
        {
          path: salesItemReport,
          Component: SalesItemReport,
        },
        {
          path: taxesReport,
          Component: TaxesReport,
        },
      ],
    },
    {
      Component: CustomersReportsList,
      path: customerIndex,
      children: [
        {
          path: customersReport,
          Component: CustomersReport,
        },
        {
          path: marketingReport,
          Component: MarketingReport,
        },
      ],
    },
    {
      Component: InventoryReportsList,
      path: inventoryIndex,
      children: [
        {
          path: inventoryReport,
          Component: InventoryReport,
        },
        {
          path: memoReport,
          Component: MemoReport,
        },
        {
          path: memoOutReport,
          Component: MemoOutReport,
        },
      ],
    },
  ],
};
