import { useMeRole } from '@components/onboarding/hooks/useMeRole';
import { useOnboardingData } from '@components/onboarding/hooks/useOnboardingData';
import { useOnboardingEmployeeData } from '@components/onboarding/hooks/useOnboardingEmployeeData';
import { useParams } from 'react-router';

type ReturnType = {
  status: boolean;
  doneSteps: number;
  stepsCount: number;
  loading: boolean;
};

export function useOnboarding(): ReturnType {
  const { role, loading } = useMeRole();
  const { tenantSlug } = useParams<{ tenantSlug: string }>();

  const {
    status: ownerStatus,
    doneSteps: ownerSteps,
    stepsCount: ownerStepCount,
  } = useOnboardingData(tenantSlug as string);
  const {
    status: employeeStatus,
    doneSteps: employeeSteps,
    stepsCount: employeeStepsCount,
  } = useOnboardingEmployeeData();

  switch (role) {
    case 'Owner': {
      return {
        status: ownerStatus,
        doneSteps: ownerSteps,
        stepsCount: ownerStepCount,
        loading,
      };
    }
    case 'Employee': {
      return {
        status: employeeStatus,
        doneSteps: employeeSteps,
        stepsCount: employeeStepsCount,
        loading,
      };
    }
    default:
      return {
        status: false,
        doneSteps: 0,
        stepsCount: 0,
        loading,
      };
  }
}
