import { useMeQuery } from '@/graphql';
import omit from 'lodash/omit';

type ReturnType = {
  status: boolean;
  isPasswordChanged: boolean;
  isNylasConnected: boolean;
  doneSteps: number;
  stepsCount: number;
  loading: boolean;
};

export function useOnboardingEmployeeData(): ReturnType {
  const { data: me, loading } = useMeQuery();

  const status = me?.me?.attributes?.onboardingUser?.data?.attributes
    ?.isCompleted as boolean;

  const isPasswordChanged = me?.me?.attributes?.onboardingUser?.data?.attributes
    ?.isPasswordChanged as boolean;
  const isNylasConnected = me?.me?.attributes?.onboardingUser?.data?.attributes
    ?.isNylasConnected as boolean;

  const onboardingUserData = omit(
    me?.me?.attributes?.onboardingUser?.data?.attributes || {},
    ['__typename', 'id', 'isCompleted'],
  );
  const doneSteps = Object.values(onboardingUserData).filter(
    (item) => item === true,
  ).length;

  const stepsCount = Object.keys(onboardingUserData).length;

  return {
    status,
    isPasswordChanged,
    isNylasConnected,
    doneSteps,
    stepsCount,
    loading,
  };
}
