import { useDataSelect } from '@inventory/inventoryManagement/products/inputs/useDataSelect';
import { SelectProps } from 'antd/lib';
import { FC, memo, useEffect, useMemo } from 'react';

import { useMetalTypesLazyQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { useCustomSelectLoader } from '@inventory/inventoryManagement/products/hooks/useCustomSelectLoader';
import { useMetalType } from '@inventory/inventoryManagement/products/inputs/MetalTypeInput/useMetalType';
import { SelectCustomOption } from '@inventory/inventoryManagement/products/inputs/SelectCustomOption';
import { AddNewSelectButton } from '@ui/form/AddNewSelectButton';
import { Skeleton } from 'antd';

interface Props extends SelectProps {
  onChange?: (value: string | null) => void;
  initialValue?: string;
  editable?: boolean;
}

export const MetalTypeInput: FC<Props> = memo(
  ({ onChange, initialValue, editable = true }) => {
    const { handleChange, handleSearch, queryParams, selectedValue } =
      useDataSelect({
        onChange,
        initialSelectedValue: initialValue,
      });

    const { mutationLoading, handleCreate, handleRemove } =
      useMetalType(handleChange);

    const [fetch, { data, loading }] = useMetalTypesLazyQuery({
      variables: {
        ...queryParams,
        pagination: {
          limit: -1,
        },
        sort: ['createAt:asc'],
      },
    });
    const loader = useCustomSelectLoader(loading);

    useEffect(() => {
      fetch();
    }, [fetch]);

    const options = useMemo(() => {
      const types = data?.metalTypes?.data || [];

      return types?.map((entity) => ({
        value: entity?.id,
        label: entity?.attributes?.name,
        editable: editable,
      }));
    }, [data?.metalTypes?.data, editable]);

    if (loader) {
      return <Skeleton.Input active={true} size={'default'} block={true} />;
    }

    return (
      <SearchSelect
        allowClear
        defaultValue={initialValue}
        placeholder={`Select the metal type ${
          editable ? 'or create a new one' : ''
        }`}
        onChange={handleChange}
        value={selectedValue}
        options={options}
        optionRender={(option) => {
          return <SelectCustomOption {...option} onRemove={handleRemove} />;
        }}
        loading={loading || mutationLoading}
        onSearch={handleSearch}
        dropdownRender={(menu) => (
          <>
            {menu}
            {editable && <AddNewSelectButton handleAddNew={handleCreate} />}
          </>
        )}
      />
    );
  },
);
