import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { getIsProductFilterOpen } from '@filters/helpers/getIsProductFilterOpen';
import { AdditionalFieldProps, StoneFiltersType } from '@filters/types';
import { useCustomForm } from '@form/hooks/useCustomForm';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { CustomFormItem } from '@form/item/FormItem';
import { useProductFormWatch } from '@inventory/inventoryManagement/products/hooks/useProductFormWatch';

import { MetalTypeInput } from '@inventory/inventoryManagement/products/inputs/MetalTypeInput';

import {
  ProductValues,
  StoneValues,
} from '@inventory/inventoryManagement/products/types';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { Col, Form, Row } from 'antd';

import { DiamondCustomFields } from '@filters/additionalFields/stoneCustomFileds/DiamondCustomFields';
import { GemstoneCustomFields } from '@filters/additionalFields/stoneCustomFileds/GemstoneCustomFields';
import { useRenderProductFilterFieldsHelper } from '@filters/helpers/renderProductFilterFieldsHelper';
import { MultiplyConditionSelect } from '@inventory/inventoryManagement/products/inputs/ConditionInput/MultiplyConditionSelect';
import { MultiplyEngravingTypeSelect } from '@inventory/inventoryManagement/products/inputs/EngravingTypeInput/MultiplyEngravingTypeSelect';
import { MultiplyGenderTypeSelect } from '@inventory/inventoryManagement/products/inputs/GenderInput/MultiplyGenderTypeSelect';
import { MultiplyManufacturingProcessSelect } from '@inventory/inventoryManagement/products/inputs/ManufacturingProcessInput/MultiplyManufacturingProcessSelect';
import { MultiplyMaterialGradeSelect } from '@inventory/inventoryManagement/products/inputs/MaterialGradeInput/MultiplyMaterialGradeSelect';
import { MultiplyMetalFinishSelect } from '@inventory/inventoryManagement/products/inputs/MetalFinishInput/MultiplyMetalFinishSelect';
import { MultiplyPlattingSelect } from '@inventory/inventoryManagement/products/inputs/PlattingTypeInput/MultiplyPlattingSelect';
import { MultiplyBrandSelect } from '@inventory/inventoryManagement/products/inputs/ProductBrandSelect/MultiplyBrandSelect';
import { ProductTypeSelect } from '@inventory/inventoryManagement/products/inputs/ProductTypeSelect';
import { MultiplyTimePeriodSelect } from '@inventory/inventoryManagement/products/inputs/TimePeriodInput/MultiplyTimePeriodSelect';

const customInputStyles = { margin: 0 };

const stoneSelectType = [
  {
    label: 'Diamond',
    value: 'Diamond',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const ProductsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  const { productType, metalType } = useProductFormWatch();
  const [_, handleProductChange] = useCustomForm<ProductValues>();
  const [__, handleStoneChange] = useCustomForm<StoneFiltersType>();

  const form = Form.useFormInstance<StoneValues>();
  const type = Form.useWatch<string>('type', form);

  const productFiltersCustomFields = useRenderProductFilterFieldsHelper(
    productType,
    handleProductChange,
    values,
    customInputStyles,
  );
  const isProductFilterOpen = getIsProductFilterOpen(values);

  const getStoneFields = () => {
    switch (type) {
      case 'Diamond': {
        return (
          <DiamondCustomFields
            customStyles={customInputStyles}
            handleChange={handleStoneChange}
            initialValues={values}
          />
        );
      }
      case 'Other': {
        return (
          <GemstoneCustomFields
            customStyles={customInputStyles}
            handleChange={handleStoneChange}
            initialValues={values}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      <ExpandableFormItem label={'Product'} defaultOpen={isProductFilterOpen}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem
              name={'productType'}
              label={'Product Type'}
              style={customInputStyles}
            >
              <ProductTypeSelect
                allowClear
                initialValue={values?.productType}
                editable={false}
                onChange={handleProductChange('productType')}
              />
            </CustomFormItem>
          </Col>
          {productFiltersCustomFields}
          <Col span={24}>
            <CustomFormItem
              name={'brand'}
              label={'Brand'}
              style={customInputStyles}
            >
              <MultiplyBrandSelect
                allowClear
                onChange={handleProductChange('brand')}
                initialValue={values?.brand ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'conditionType'}
              label={'Condition'}
              style={customInputStyles}
            >
              <MultiplyConditionSelect
                allowClear
                onChange={handleProductChange('conditionType')}
                initialValue={values?.conditionType ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'genderType'}
              label={'Gender'}
              style={customInputStyles}
            >
              <MultiplyGenderTypeSelect
                allowClear
                onChange={handleProductChange('genderType')}
                initialValue={values?.genderType ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'process'}
              label={'Manufacturing Process'}
              style={customInputStyles}
            >
              <MultiplyManufacturingProcessSelect
                allowClear
                onChange={handleProductChange('process')}
                initialValue={values?.process ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'metalFinishType'}
              label={'Metal Finish'}
              style={customInputStyles}
            >
              <MultiplyMetalFinishSelect
                allowClear
                onChange={handleProductChange('metalFinishType')}
                initialValue={values?.metalFinishType ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'platting'}
              label={'Platting'}
              style={customInputStyles}
            >
              <MultiplyPlattingSelect
                allowClear
                onChange={handleProductChange('platting')}
                initialValue={values?.platting ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'metalType'}
              label={'Metal'}
              style={customInputStyles}
            >
              <MetalTypeInput
                allowClear
                onChange={handleProductChange('metalType')}
                initialValue={values?.metalType ?? undefined}
                editable={false}
              />
            </CustomFormItem>
          </Col>
          {metalType && (
            <Col span={24}>
              <CustomFormItem
                name={'materialGradeType'}
                label={'Material Grade'}
                style={customInputStyles}
              >
                <MultiplyMaterialGradeSelect
                  allowClear
                  onChange={handleProductChange('materialGradeType')}
                  initialValue={values?.materialGradeType ?? undefined}
                  metalId={metalType}
                />
              </CustomFormItem>
            </Col>
          )}
          <Col span={24}>
            <CustomFormItem
              name={'engravingType'}
              label={'Stamped/Engraved'}
              style={customInputStyles}
            >
              <MultiplyEngravingTypeSelect
                allowClear
                onChange={handleProductChange('engravingType')}
                initialValue={values?.engravingType ?? undefined}
              />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              name={'timePeriod'}
              label={'Time Period'}
              style={customInputStyles}
            >
              <MultiplyTimePeriodSelect
                allowClear
                onChange={handleProductChange('timePeriod')}
                initialValue={values?.timePeriod ?? undefined}
              />
            </CustomFormItem>
          </Col>
        </Row>
      </ExpandableFormItem>

      <ExpandableFormItem
        withDivider
        label={'Stone'}
        defaultOpen={!!values?.type}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem
              label={'Type'}
              name={'type'}
              style={customInputStyles}
            >
              <SearchSelect allowClear options={stoneSelectType} />
            </CustomFormItem>
          </Col>
          {getStoneFields()}
        </Row>
      </ExpandableFormItem>

      <ExpandableFormItem
        withDivider
        label={'Created At'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
      >
        <DateRangeInput style={{ width: '100%' }} allowClear />
      </ExpandableFormItem>
    </>
  );
};
