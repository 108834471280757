import { RouteObject } from 'react-router-dom';

import ReturnPage from '@pages/inventory/return/index';

import { ROUTES } from '@router/routes';
import { lazy } from 'react';

const { index, create } = ROUTES.tenant.inventory.returns;

const CreateReturnForm = lazy(
  () => import('@inventory/return/forms/CreateReturnForm'),
);
const UpdateReturnForm = lazy(
  () => import('@inventory/return/forms/UpdateReturnForm'),
);

export const returnManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: ReturnPage,
    },
    {
      path: create,
      Component: CreateReturnForm,
    },
    {
      path: ':uuid',
      Component: UpdateReturnForm,
    },
  ],
};
