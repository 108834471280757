import { FC, memo } from 'react';

import { useMeQuery } from '@/graphql';

import CustomAvatar from '@ui/avatar';

import styles from './UserAvatar.module.scss';

interface Props {
  hasOutline?: boolean;
}
export const UserAvatar: FC<Props> = memo(({ hasOutline }) => {
  const { data } = useMeQuery();

  return (
    <CustomAvatar
      src={data?.me?.attributes?.avatar?.data?.attributes?.url}
      alt={data?.me?.attributes?.fullName || ''}
      className={styles.avatar}
    />
  );
});
