import { AliasToken } from 'antd/es/theme/interface';

export const colors: Partial<AliasToken> = {
  colorText: '#1F2933',
  colorTextSecondary: '#747679',
  colorPrimary: '#0146C5',
  colorBorderSecondary: '#4876D5',
  colorSplit: '#E7E7E7',
  colorLink: '#4876D5',
  colorError: '#C62421',
  colorSuccess: '#077C38',
  colorWarning: '#D37D21',
  colorTextDescription: '#626670',
  colorBgTextHover: '#F0F3FB',
  colorTextHeading: '#1F2933',
  colorBgLayout: '#F7F8FA',
  boxShadow: '4px 0px 16px 0px rgba(49, 62, 88, 0.04)',
};
