import {
  useCreateEngravingTypeMutation,
  useDeleteEngravingTypeMutation,
} from '@/graphql';
import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { ReturnUseEntityType } from '@inventory/inventoryManagement/products/types';
import { useCallback } from 'react';

export function useEngravingType(
  onChange: (values: string) => void,
): ReturnUseEntityType {
  const message = useStatusMessage();

  const [createEngravingType, { loading: createLoading }] =
    useCreateEngravingTypeMutation({
      onError: () => message.open('error'),
      refetchQueries: ['engravingTypes'],
    });

  const [remove, { loading: removeLoading }] = useDeleteEngravingTypeMutation({
    onError: () => message.open('error'),
    refetchQueries: ['boxPapers'],
  });

  const handleCreate = useCallback(
    async (name: string) => {
      await createEngravingType({
        variables: {
          input: {
            name,
            editable: true,
          },
        },
        onCompleted: (data) => {
          if (data?.createEngravingType?.data?.id) {
            onChange(data.createEngravingType.data.id);
            message.open('success', 'Option added successful');
          }
        },
      });
    },
    [createEngravingType, message, onChange],
  );

  const handleRemove = useCallback(
    async (id: string) => {
      await remove({
        variables: {
          id,
        },
        onCompleted: async () => {
          onChange('');
          message.open('success', 'Option removed successfully');
        },
      });
    },
    [message, onChange, remove],
  );

  return {
    mutationLoading: createLoading || removeLoading,
    handleRemove,
    handleCreate,
  };
}
