import { FC, lazy } from 'react';

import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Warehouses = lazy<FC>(() => import('@pages/inventory/warehouses'));

const CreateWarehouseForm = lazy<FC>(
  () => import('@inventory/warehouses/forms/CreateWarehouseForm'),
);
const UpdateWarehouseForm = lazy<FC>(
  () => import('@inventory/warehouses/forms/UpdateWarehouseForm'),
);
const { index, create, archive, active } = ROUTES.tenant.inventory.warehouses;

export const warehousesManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={active} />,
    },
    {
      path: active,
      Component: Warehouses,
    },
    {
      path: archive,
      Component: Warehouses,
    },
    {
      path: create,
      Component: CreateWarehouseForm,
    },
    {
      path: `${active}/:uuid`,
      Component: UpdateWarehouseForm,
    },
    {
      path: `${archive}/:uuid`,
      Component: UpdateWarehouseForm,
    },
  ],
};
