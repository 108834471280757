import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const InventoryManagement = lazy<FC>(
  () => import('@pages/inventory/inventory-management'),
);
const InvManagementProvider = lazy<FC>(
  () => import('@inventory/inventoryManagement/context/InvManagementProvider'),
);

const InventoryManagementDashboard = lazy<FC>(
  () => import('@pages/inventory/inventory-management/dashboard'),
);

const Resources = lazy<FC>(
  () => import('@pages/inventory/inventory-management/resources'),
);
const CreateResourceForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/resources/CreateResourceForm'),
);
const UpdateResourceForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/resources/UpdateResourceForm'),
);

const Services = lazy<FC>(
  () => import('@pages/inventory/inventory-management/services'),
);
const CreateServiceForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/services/CreateServiceForm'),
);
const UpdateServiceForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/services/UpdateServiceForm'),
);

const Classes = lazy<FC>(
  () => import('@pages/inventory/inventory-management/classes'),
);
const CreateClassForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/classes/CreateClassForm'),
);
const UpdateClassForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/classes/UpdateClassForm'),
);

const Products = lazy<FC>(
  () => import('@pages/inventory/inventory-management/products'),
);
const CreateProductForm = lazy<FC>(
  () =>
    import('@inventory/inventoryManagement/products/forms/CreateProductForm'),
);
const UpdateProductForm = lazy<FC>(
  () =>
    import('@inventory/inventoryManagement/products/forms/UpdateProductForm'),
);

const CompositeProducts = lazy<FC>(
  () => import('@pages/inventory/inventory-management/composite-products'),
);
const CreateCompositeProductForm = lazy<FC>(
  () =>
    import(
      '@inventory/inventoryManagement/compositeProducts/CreateCompositeProductForm'
    ),
);
const UpdateCompositeProductForm = lazy<FC>(
  () =>
    import(
      '@inventory/inventoryManagement/compositeProducts/UpdateCompositeProductForm'
    ),
);

const ProductGroups = lazy<FC>(
  () => import('@pages/inventory/inventory-management/product-groups'),
);
const CreateProductGroupForm = lazy<FC>(
  () =>
    import(
      '@inventory/inventoryManagement/productGroups/CreateProductGroupForm'
    ),
);
const UpdateProductGroupForm = lazy<FC>(
  () =>
    import(
      '@inventory/inventoryManagement/productGroups/UpdateProductGroupForm'
    ),
);

const Discounts = lazy<FC>(
  () => import('@pages/inventory/inventory-management/discounts'),
);
const CreateDiscountForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/discount/CreateDiscountForm'),
);
const UpdateDiscountForm = lazy<FC>(
  () => import('@inventory/inventoryManagement/discount/UpdateDiscountForm'),
);

const Memberships = lazy<FC>(
  () => import('@pages/inventory/inventory-management/memberships'),
);
const CreateMembershipForm = lazy<FC>(
  () =>
    import('@inventory/inventoryManagement/memberships/CreateMembershipForm'),
);
const UpdateMembershipForm = lazy<FC>(
  () =>
    import('@inventory/inventoryManagement/memberships/UpdateMembershipForm'),
);

const {
  index,
  dashboard,
  products,
  productGroups,
  compositeProducts,
  services,
  classes,
  resources,
  memberships,
  discounts,
} = ROUTES.tenant.inventory.inventoryManagement;

export const inventoryManagementRoutes: RouteObject = {
  Component: InvManagementProvider,
  children: [
    {
      path: index,
      Component: InventoryManagement,
      children: [
        {
          index: true,
          element: <Navigate to={dashboard} />,
        },
        {
          path: dashboard,
          Component: InventoryManagementDashboard,
        },
        {
          path: products.index,
          children: [
            {
              index: true,
              Component: Products,
            },
            {
              path: products.create,
              Component: CreateProductForm,
            },
            {
              path: ':uuid',
              Component: UpdateProductForm,
            },
          ],
        },
        {
          path: productGroups.index,
          children: [
            {
              index: true,
              Component: ProductGroups,
            },
            {
              path: productGroups.create,
              Component: CreateProductGroupForm,
            },
            {
              path: ':uuid',
              Component: UpdateProductGroupForm,
            },
          ],
        },
        {
          path: compositeProducts.index,
          children: [
            {
              index: true,
              Component: CompositeProducts,
            },
            {
              path: compositeProducts.create,
              Component: CreateCompositeProductForm,
            },
            {
              path: ':uuid',
              Component: UpdateCompositeProductForm,
            },
          ],
        },
        {
          path: services.index,
          children: [
            {
              index: true,
              Component: Services,
            },
            {
              path: services.create,
              Component: CreateServiceForm,
            },
            {
              path: ':uuid',
              Component: UpdateServiceForm,
            },
          ],
        },
        {
          path: classes.index,
          children: [
            {
              index: true,
              Component: Classes,
            },
            {
              path: classes.create,
              Component: CreateClassForm,
            },
            {
              path: ':uuid',
              Component: UpdateClassForm,
            },
          ],
        },
        {
          path: resources.index,
          children: [
            {
              index: true,
              Component: Resources,
            },
            {
              path: resources.create,
              Component: CreateResourceForm,
            },
            {
              path: ':uuid',
              Component: UpdateResourceForm,
            },
          ],
        },
        {
          path: memberships.index,
          children: [
            {
              index: true,
              Component: Memberships,
            },
            {
              path: memberships.create,
              Component: CreateMembershipForm,
            },
            {
              path: ':uuid',
              Component: UpdateMembershipForm,
            },
          ],
        },
        {
          path: discounts.index,
          children: [
            {
              index: true,
              Component: Discounts,
            },
            {
              path: discounts.create,
              Component: CreateDiscountForm,
            },
            {
              path: ':uuid',
              Component: UpdateDiscountForm,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to={dashboard} />,
        },
      ],
    },
  ],
};
