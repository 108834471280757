import {
  AudioMutedOutlined,
  AudioOutlined,
  KeyOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Divider, Space, Typography } from 'antd';
import { FC } from 'react';

const { Text } = Typography;

interface CallComponentProps {
  phoneNumber: string;
  contactName: string;
  avatar?: string;
  isCallActive: boolean;
  isIncomingCall: boolean;
  duration: string;
  isMuted: boolean;
  toggleMute: () => void;
  handleDecline: () => void;
  handleAccept: () => void;
}

const CallComponent: FC<CallComponentProps> = ({
  phoneNumber,
  contactName,
  avatar,
  isCallActive,
  isIncomingCall,
  duration,
  isMuted,
  toggleMute,
  handleDecline,
  handleAccept,
}) => {
  return (
    <div
      style={{
        transition: 'transform 0.6s ease-in-out, bottom 0.6 ease-in-out',
        backgroundColor: isCallActive || isIncomingCall ? '#fff' : '#f0f2f5',
        boxShadow: '0px 4px 12px 0px #00000014',
        bottom: '0',
        justifyContent: 'space-between',
        position: 'fixed',
        padding: '16px 32px',
        alignItems: 'center',
        display: isCallActive || isIncomingCall ? 'flex' : 'none',
        zIndex: 3,
        right: 0,
        left: 0,
      }}
    >
      <Space>
        {isIncomingCall && (
          <>
            <Text
              style={{
                marginRight: 10,
                fontWeight: 500,
                color: '#000',
                fontSize: 16,
              }}
            >
              Incoming call from
            </Text>
          </>
        )}

        <Avatar src={avatar ?? undefined} size={24} icon={<UserOutlined />} />
        <Space direction={'horizontal'} size={0}>
          <Text
            style={{
              color: '#4876D5',
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            {contactName}
          </Text>
          <Divider
            type={'vertical'}
            style={{
              margin: '0 15px',
              height: 17,
            }}
          />
          <Text style={{ color: '#000', fontSize: 16 }}>{phoneNumber}</Text>
          <Divider
            type={'vertical'}
            style={{
              margin: '0 15px',
              height: 17,
            }}
          />
          {isCallActive && (
            <Text style={{ color: '#000', fontSize: 16 }}>{duration}</Text>
          )}
        </Space>
      </Space>

      <Space size={'large'}>
        {isIncomingCall ? (
          <>
            <Button
              icon={<PhoneOutlined style={{ color: '#0146C5' }} />}
              style={{ background: '#E7ECF8' }}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              onClick={handleAccept}
            />
            <Button
              style={{ transform: 'rotate(225deg)' }}
              icon={<PhoneOutlined />}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              danger
              onClick={handleDecline}
            />
          </>
        ) : (
          <>
            <Button
              icon={
                !isMuted ? (
                  <AudioMutedOutlined style={{ color: '#fff' }} />
                ) : (
                  <AudioOutlined style={{ color: '#0146C5' }} />
                )
              }
              style={{ background: isMuted ? '#E7ECF8' : '#0146C5' }}
              onClick={toggleMute}
              size={'large'}
              type={'primary'}
              shape={'circle'}
            />
            <Button
              icon={<KeyOutlined style={{ color: '#0146C5' }} />}
              style={{ background: '#E7ECF8' }}
              size={'large'}
              type={'primary'}
              shape={'circle'}
            />
            <Button
              style={{ transform: 'rotate(225deg)' }}
              icon={<PhoneOutlined />}
              size={'large'}
              type={'primary'}
              shape={'circle'}
              danger
              onClick={handleDecline}
            />
          </>
        )}
      </Space>
    </div>
  );
};

export default CallComponent;
