import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ConfigProvider, Typography } from 'antd';

import { useToken } from '@hooks/useToken';

interface Props extends LinkProps {
  fontSize?: number;
}

export const CustomLink: FC<Props> = ({
  children,
  fontSize = 10,
  ...props
}) => {
  const { token } = useToken();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: fontSize,
          colorText: token.colorLink,
        },
      }}
    >
      <Link {...props}>
        <Typography.Text>{children}</Typography.Text>
      </Link>
    </ConfigProvider>
  );
};
