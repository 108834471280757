import { ApolloQueryResult } from '@apollo/client';
import { UploadFile } from 'antd';
import dayjs from 'dayjs';

export type RefetchDealTransactionFunction = (
  variables?:
    | Partial<
        Exact<{
          filters?: InputMaybe<DealTransactionFiltersInput>;
          pagination?: InputMaybe<PaginationArg>;
          sort?: InputMaybe<any>;
        }>
      >
    | undefined,
) => Promise<ApolloQueryResult<DealTransactionsQuery>>;

export type DealTransactionFormValues = Omit<
  DealTransactionInput,
  ('chart' & 'allcontacts') | 'files'
> & {
  chart: string;
  sellingOrder: string;
  allContacts: {
    company?: string;
    contact?: string;
  };
  files: UploadFile[];
};

export interface ChartFilter {
  chartAccount?: {
    id: {
      eq: string | null;
    };
  };
  chartCategory?: {
    id: {
      eq: string | null;
    };
  };
  chartSubcategory?: {
    id: {
      eq: string | null;
    };
  };
}

export type FilterTransactionFieldType = {
  dueDate: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null;
  status?: string;
  chart?: string;
};

export enum DealTransactionStatus {
  OPEN = 'Open',
  PAID = 'Paid',
  CANCELLED = 'Cancelled',
  REFUNDED = 'Refunded',
  RUNNING = 'Running',
}

export enum PaymentOption {
  CARD = 'card',
  CASH = 'cash',
}

export enum AmountSummeriedColor {
  Open = '#0146c5',
  Paid = '#077c38',
  Refunded = '#d37d21',
  Cancelled = '#d37d21',
  Running = '#0146c5',
}
