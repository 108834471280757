import { RefObject, useEffect, useRef, useState } from 'react';

export const useIsVisible = <T extends HTMLElement>(): {
  isVisible: boolean;
  ref: RefObject<T>;
} => {
  const [isIntersecting, setIntersecting] = useState(false);
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return {
    isVisible: isIntersecting,
    ref,
  };
};
