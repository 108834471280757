import { SorterResult } from 'antd/es/table/interface';

import { AnyObject, isNotEmpty } from '@helpers/types';

const sorterOrderToGraphql = (order: SorterResult<object>['order']) => {
  switch (order) {
    case 'descend':
      return 'desc';
    case 'ascend':
    default:
      return 'ASC';
  }
};

const transformSorterToGraphql = <T = AnyObject>(sorter?: SorterResult<T>) => {
  if (!sorter?.order || !sorter?.field) {
    return;
  }

  const order = sorterOrderToGraphql(sorter.order);

  let fieldValue = sorter.field;

  if (Array.isArray(sorter.field)) {
    fieldValue = sorter?.field
      ?.filter((field) => field !== 'data' && field !== 'attributes')
      .join('.');
  }

  return fieldValue + ':' + order;
};

export const processSorter = <T = AnyObject>(
  sorter?: SorterResult<T> | SorterResult<T>[],
): string | string[] | undefined => {
  if (!sorter) {
    return;
  }

  if (Array.isArray(sorter)) {
    return sorter.map(transformSorterToGraphql).filter(isNotEmpty);
  }

  return transformSorterToGraphql(sorter);
};
