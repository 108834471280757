import { GetChangeHandler } from '@form/hooks/useCustomForm';
import { CustomFormItem } from '@form/item/FormItem';
import { MultiplyStoneColorDominantSelect } from '@inventory/inventoryManagement/products/inputs/ColorDominantInput/MultiplyStoneColorDominantSelect';
import { MultiplyStoneColorIntensitySelect } from '@inventory/inventoryManagement/products/inputs/ColorIntensityInput/MultiplyStoneColorIntensitySelect';
import { MultiplyStoneColorSecondarySelect } from '@inventory/inventoryManagement/products/inputs/ColorSecondaryInput/MultiplyStoneColorSecondarySelect';
import { STONE_COLOR_FIELD_NAME } from '@inventory/inventoryManagement/products/static/fieldName';
import { Col, Typography } from 'antd';
import { CSSProperties, FC } from 'react';

interface Props {
  type: EnumStoneType;
  getChangeHandler: GetChangeHandler<any>;
  initialValues?: any;
  customStyles?: CSSProperties;
}

export const GemstoneColorCustomFields: FC<Props> = ({
  initialValues,
  getChangeHandler,
  customStyles,
  type,
}) => {
  return (
    <>
      <Typography.Title
        level={4}
        style={{
          fontWeight: 600,
          fontSize: 14,
          marginBottom: 24,
        }}
      >
        Form Stone Color or Color Combination:
      </Typography.Title>

      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorIntensity']}
          label={'Color Intensity'}
        >
          <MultiplyStoneColorIntensitySelect
            allowClear
            initialValue={initialValues?.colorIntensity ?? undefined}
            type={type}
            onChange={getChangeHandler('colorIntensity')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorSecondary']}
          label={'Secondary Color'}
        >
          <MultiplyStoneColorSecondarySelect
            allowClear
            initialValue={initialValues?.colorSecondary ?? undefined}
            onChange={getChangeHandler('colorSecondary')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorDominant']}
          label={'Dominant Color'}
        >
          <MultiplyStoneColorDominantSelect
            allowClear
            initialValue={initialValues?.colorDominant ?? undefined}
            onChange={getChangeHandler('colorDominant')}
          />
        </CustomFormItem>
      </Col>

      <Typography.Title
        style={{ fontWeight: 600, fontSize: 14, marginBottom: 24 }}
        level={4}
      >
        To Stone Color or Color Combination:
      </Typography.Title>

      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorIntensityTo']}
          label={'Color Intensity'}
        >
          <MultiplyStoneColorIntensitySelect
            allowClear
            initialValue={initialValues?.colorIntensityTo ?? undefined}
            type={type}
            onChange={getChangeHandler('colorIntensityTo')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorSecondaryTo']}
          label={'Secondary Color'}
        >
          <MultiplyStoneColorSecondarySelect
            allowClear
            initialValue={initialValues?.colorSecondaryTo ?? undefined}
            onChange={getChangeHandler('colorSecondaryTo')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'colorDominantTo']}
          label={'Dominant Color'}
          style={customStyles}
        >
          <MultiplyStoneColorDominantSelect
            allowClear
            initialValue={initialValues?.colorDominantTo ?? undefined}
            onChange={getChangeHandler('colorDominantTo')}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
