import { usePlatformQuery } from '@/graphql';

export function usePlatformHook() {
  const { data, loading } = usePlatformQuery();

  const address = data?.platform?.data?.attributes?.address ?? '';
  const name = data?.platform?.data?.attributes?.name ?? '';
  const logo = data?.platform?.data?.attributes?.logo?.data || [];
  const logoMin = data?.platform?.data?.attributes?.minifiedLogo?.data;

  return {
    address,
    logo,
    name,
    logoMin,
    loading,
  };
}
