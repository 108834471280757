import { NotificationItemAvatarContent } from '@components/notifications/NotificationItem/NotificationItemAvatarContent/NotificationItemAvatarContent';
import { useTenantRoutes } from '@router/routes';
import { CustomButton } from '@ui/button/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router';

interface Props {
  notification: Maybe<InventoryQuantityNotificationFragment>;
  hasBeenSeen?: boolean;
}
export const InventoryQuantityNotification: FC<Props> = ({
  notification,
  hasBeenSeen,
}) => {
  const { type, productInventoryItem } = notification?.attributes || {};
  const { product, businessLocation, lowQuantity, quantity } =
    productInventoryItem?.data?.attributes || {};

  const storeName = <b>{businessLocation?.data?.attributes?.name}</b>;

  const message =
    type === 'NoLeft' ? (
      <>There are no more items left in the inventory of {storeName}</>
    ) : (
      <>
        Stock of {storeName} has{' '}
        {lowQuantity === quantity ? 'reached' : 'fallen below'} a low quantity
        of {lowQuantity} items
      </>
    );

  const {
    inventory: {
      inventoryManagement: { products },
    },
  } = useTenantRoutes();
  const navigate = useNavigate();
  const navigateToProduct = () => {
    navigate(`${products.index}/${product?.data?.attributes?.uuid}`);
  };

  return (
    <NotificationItemAvatarContent
      isProduct
      src={product?.data?.attributes?.files?.data?.[0]?.attributes?.url}
      title={product?.data?.attributes?.name}
      description={message}
      hasBeenSeen={hasBeenSeen}
    >
      <CustomButton type={'primary'} onClick={navigateToProduct} size={'small'}>
        View Product
      </CustomButton>
    </NotificationItemAvatarContent>
  );
};
