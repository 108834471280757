import { exhaustiveArray } from '@helpers/types';
import { Select, SelectProps } from 'antd';
import capitalize from 'lodash/capitalize';
import { FC } from 'react';

const TYPES = exhaustiveArray<EnumReturnType>()('full', 'partial');

const options = TYPES.map((type) => ({
  label: capitalize(type),
  value: type,
}));

export const ReportTypeSelect: FC<SelectProps<EnumReturnType>> = (props) => {
  return (
    <Select<EnumReturnType>
      {...props}
      options={options}
      placeholder={'Select return type'}
    />
  );
};
