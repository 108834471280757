import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';
import { CustomerObject } from 'src/components/ui/form/ContactsANDCompaniesSelect';

export const createInventoryInvoiceCustomerFilter: FilterFunction<
  CustomerObject
> = (customer): InvoiceFiltersInput => {
  const contactFilter = customer?.contact
    ? {
        contact: {
          id: {
            eq: customer.contact,
          },
        },
      }
    : {};

  const companyFilter = customer?.company
    ? {
        company: {
          id: {
            eq: customer.company,
          },
        },
      }
    : {};

  return {
    orderId: {
      ...contactFilter,
      ...companyFilter,
    },
  };
};

export const createInventoryInvoiceCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): InvoiceFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
