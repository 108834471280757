import { FilterFunction } from '@filters/types';

export const createStageFilter: FilterFunction<string> = (
  stage?: string,
): DealFiltersInput => {
  return stage
    ? {
        stage: {
          eq: stage,
        },
      }
    : {};
};

export const createAllContactsFilter = (allContacts?: {
  contact?: string;
  lead?: string;
  company?: string;
}) => {
  return allContacts
    ? {
        contact: {
          id: {
            eq: allContacts?.contact || undefined,
          },
        },
        lead: {
          id: {
            eq: allContacts?.lead || undefined,
          },
        },
        company: {
          id: {
            eq: allContacts?.company || undefined,
          },
        },
      }
    : {};
};
