import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { ConfigProvider, Row, Space } from 'antd';

import { Icon } from '@/assets/icon';
import { UserAvatar } from '@/components/auth/userAvatar/UserAvatar';
import AvatarInfo from '@/components/ui/avatar/AvatarInfo';
import { CustomButton } from '@/components/ui/button/Button';
import CustomDropdown from '@/components/ui/dropdown';
import { useMeQuery } from '@/graphql';
import { useSignOut } from '@/hooks/auth/useSignOut';
import { useTenantRoutes } from '@/router/routes';

import styles from './ProfileDropdown.module.scss';

const WEBSITE_ZENDESK_HELP_CENTER_LINK = import.meta.env
  .WEBSITE_ZENDESK_HELP_CENTER_LINK;
export const ProfileDropdown: FC = () => {
  const { settings, downloads } = useTenantRoutes();

  const { data: me } = useMeQuery();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const navigateToSettings = useCallback(() => {
    navigate(settings.index);
  }, [settings.index, navigate]);

  const navigateToDownloads = useCallback(() => {
    navigate(downloads);
  }, [downloads, navigate]);

  const items = useMemo(
    () => [
      {
        key: 'profile',
        label: (
          <Row onClick={navigateToSettings}>
            <AvatarInfo
              src={me?.me?.attributes?.avatar?.data?.attributes?.url || ''}
              title={me?.me?.attributes?.fullName || ''}
              description={me?.me?.attributes?.email || ''}
            />
          </Row>
        ),
      },
      {
        key: 'divider login',
        type: 'divider',
      },
      {
        key: 'download',
        label: (
          <CustomButton
            type={'text'}
            onClick={navigateToDownloads}
            icon={<Icon type={'download'} />}
            size={'large'}
            paddingless
            block
            className={styles.menuButton}
          >
            Downloads
          </CustomButton>
        ),
      },
      {
        key: 'support',
        label: (
          <Link
            to={WEBSITE_ZENDESK_HELP_CENTER_LINK}
            target={'_blank'}
            rel={'noreferrer'}
          >
            <CustomButton
              type={'text'}
              icon={<Icon type={'circle-help'} />}
              size={'large'}
              paddingless
              block
              className={styles.menuButton}
            >
              Support
            </CustomButton>
          </Link>
        ),
      },
      {
        key: 'divider second',
        type: 'divider',
      },
      {
        key: 'logout',
        label: (
          <CustomButton
            type={'text'}
            onClick={handleSignOut}
            icon={<Icon type={'sign-out'} />}
            size={'large'}
            paddingless
            block
            className={styles.menuButton}
          >
            Sign Out
          </CustomButton>
        ),
      },
    ],
    [
      handleSignOut,
      me?.me?.attributes?.avatar?.data?.attributes?.url,
      me?.me?.attributes?.email,
      me?.me?.attributes?.fullName,
      navigateToDownloads,
      navigateToSettings,
    ],
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            fontSizeLG: 14,
            controlHeightLG: 24,
          },
        },
      }}
    >
      <CustomDropdown
        menu={{
          items,
        }}
        contentClassName={styles.content}
        width={300}
        withButton={false}
      >
        <Space>
          <UserAvatar />
        </Space>
      </CustomDropdown>
    </ConfigProvider>
  );
};
