import dayjs from 'dayjs';

import { transformToDateFilterInput } from '@helpers/filters';

export const createNameContractFilter = (
  name?: string,
): ContractFiltersInput => {
  return {
    name: {
      containsi: name,
    },
  };
};

export const createStatusContractFilter = (
  status?: string,
): ContractFiltersInput => {
  return {
    publicContract: {
      status: {
        eq: status,
      },
    },
  };
};

export const customerContractFilter = (
  contactId?: string,
): ContractFiltersInput => {
  return {
    contact: {
      id: {
        eq: contactId,
      },
    },
  };
};
export const createContractCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ContractFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
