import { FC } from 'react';

import { Col, Row } from 'antd';

import DealsCard from './DealsCard';

interface Props {
  data?: DealsQuery;
}

const DealsList: FC<Props> = ({ data }) => {
  const dealsData = data?.deals?.data;

  return (
    <Row gutter={[24, 24]}>
      {dealsData && dealsData?.length > 0 ? (
        dealsData?.map((deal: DealFragment) => (
          <Col key={deal?.id} span={12}>
            <DealsCard deal={deal} />
          </Col>
        ))
      ) : (
        <Col span={24}>No deals yet</Col>
      )}
    </Row>
  );
};

export default DealsList;
