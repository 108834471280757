import dayjs from 'dayjs';

import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';

export const createInventoryAuditBusinessLocationFilters: FilterFunction<
  string
> = (businessLocation?: string): InventoryAuditFiltersInput => ({
  businessLocation: {
    id: {
      eq: businessLocation,
    },
  },
});

export const createInventoryAuditCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): InventoryAuditFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
export const createInventoryAuditDateOfAuditFilter: FilterFunction<Date> = (
  auditDate?: Date,
): InventoryAuditFiltersInput => {
  const date = dayjs(auditDate);
  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');
  return auditDate
    ? {
        auditDate: {
          between: [startOfDay.toDate(), endOfDay.toDate()],
        },
      }
    : {};
};

export const createInventoryAuditSublocationFilters: FilterFunction<string> = (
  sublocation?: string,
): InventoryAuditFiltersInput => ({
  sublocation: {
    id: {
      eq: sublocation,
    },
  },
});
