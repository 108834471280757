import dayjs from 'dayjs';

export const transformToDateFilterInput = (
  value?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): DateTimeFilterInput => {
  const from = value && value[0];
  const to = value && value[1];

  const transformedFrom = from ? from.toDate() : new Date();
  const transformedTo = to ? to.toDate() : new Date();

  return {
    between: from || to ? [transformedFrom, transformedTo] : undefined,
  };
};

export const transformDateToValue = (between?: InputMaybe<Date>[] | null) => {
  if (!between) {
    return null;
  }

  const [from, to] = between;

  if (!from && !to) {
    return null;
  }

  return [from ? dayjs(from) : null, to ? dayjs(to) : null] as [
    dayjs.Dayjs | null,
    dayjs.Dayjs | null,
  ];
};
