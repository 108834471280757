import { FC } from 'react';

import { SalesItemReportType } from '@helpers/enumTypes';
import { Space } from 'antd';

interface Props {
  label: SalesItemReportType;
}

export const SalesItemReportTypeSelectOption: FC<Props> = ({ label }) => (
  <Space>{label}</Space>
);
