import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { MultiplySpecificTypeSelect } from '@inventory/inventoryManagement/products/inputs/SpecificTypeInput/MultiplySpecificTypeSelect';
import { Col } from 'antd';
import { FC } from 'react';

export const CuffFilterCustomFields: FC<SalesItemReportFilterCustomFields> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Cufflinks Type'}
          style={customStyles}
        >
          <MultiplySpecificTypeSelect
            allowClear
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
