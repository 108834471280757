import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';

export const createTaxesOrderFilter: FilterFunction<string> = (
  orderId?: string,
): TaxReportFiltersInput => {
  return orderId
    ? {
        order: {
          id: {
            eq: orderId,
          },
        },
      }
    : {};
};

export const createTaxesReportCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): ProductFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
