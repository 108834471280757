import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';
import { FC, lazy } from 'react';

const MaintenancePage = lazy<FC>(
  () => import('@pages/inventory/maintenance/index'),
);
const CreateMaintenanceForm = lazy<FC>(
  () => import('@inventory/maintenance/forms/CreateMaintenanceForm'),
);
const MaintenanceView = lazy<FC>(
  () => import('@inventory/maintenance/MaintenanceView'),
);

const { index, create } = ROUTES.tenant.inventory.maintenance;
export const maintenanceManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      Component: MaintenancePage,
    },
    {
      path: create,
      Component: CreateMaintenanceForm,
    },
    {
      path: ':uuid',
      Component: MaintenanceView,
    },
  ],
};
