import { useTenantBySlugQuery } from '@/graphql';
import { useRouteTenant } from '@hooks/useRouteTenant';

type ReturnType = {
  tenantId: string;
  mainLogo: string;
  mainAddress: string;
  mainPhone: string;
  mainSiteUrl: string;
  mainEmail: string;
  invoiceTerms: string;
  appraisalTerms: string;
  taskContent: string;
  purchaseTerms: string;
  invoiceClientMessage: string;
  mainCompanyName: string;
  isShowOrderItemsImages: boolean;
  twilioConnectionId: string;
};

export const useTenantInfo = (): ReturnType => {
  const tenantSlug = useRouteTenant();

  const { data } = useTenantBySlugQuery({
    variables: { tenantSlug: tenantSlug as string },
  });

  const tenantId = data?.tenants?.data[0]?.id as string;
  const mainLogo =
    data?.tenants?.data[0]?.attributes?.logo?.data?.attributes?.url || '';
  const mainAddress =
    data?.tenants?.data[0]?.attributes?.mainLocation?.data?.attributes
      ?.address || '';
  const mainEmail = data?.tenants?.data[0]?.attributes?.email || '';
  const mainPhone = data?.tenants?.data[0]?.attributes?.phoneNumber || '';
  const mainSiteUrl = data?.tenants?.data[0]?.attributes?.websiteUrl || '';
  const invoiceTerms = data?.tenants?.data[0]?.attributes?.invoiceTerms || '';
  const appraisalTerms =
    data?.tenants?.data[0]?.attributes?.appraisalTerms || '';
  const purchaseTerms = data?.tenants?.data[0]?.attributes?.purchaseTerms || '';
  const invoiceClientMessage =
    data?.tenants?.data[0]?.attributes?.invoiceClientMessage || '';
  const taskContent =
    data?.tenants?.data[0]?.attributes?.taskNotificationContent || '';
  const mainCompanyName = data?.tenants?.data[0]?.attributes?.companyName || '';
  const isShowOrderItemsImages =
    data?.tenants?.data[0]?.attributes?.isShowOrderItemsImages ?? false;
  const twilioConnectionId =
    data?.tenants?.data[0]?.attributes?.twilioConnection?.data?.id || '';

  return {
    tenantId,
    mainLogo,
    mainAddress,
    mainPhone,
    mainSiteUrl,
    mainEmail,
    invoiceTerms,
    appraisalTerms,
    purchaseTerms,
    taskContent,
    invoiceClientMessage,
    mainCompanyName,
    isShowOrderItemsImages,
    twilioConnectionId,
  };
};
