import { useMeQuery } from '@/graphql';

type ReturnType = {
  role: string;
  loading: boolean;
};
export function useMeRole(): ReturnType {
  const { data: me, loading } = useMeQuery();

  const role = me?.me?.attributes?.role?.data?.attributes?.name as string;

  return { role, loading };
}
