import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useSessionStorage } from 'usehooks-ts';

import { StoreContext } from '@components/stores/context/StoreContext';
import { useActiveStoresQuery } from '@components/stores/hooks/useActiveStoresQuery';

export const StoreProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [hasAllStoresOption, setHasAllStoresOption] = useState(true);
  const [selectedStoreId, setSelectedStoreId] = useSessionStorage<
    string | null
  >('selectedStoreId', null);

  const [filters, setFilters] = useState<BusinessLocationFiltersInput>({});
  const { data, refetch, loading } = useActiveStoresQuery({
    variables: {
      filters,
    },
  });

  const handleSearch = useCallback((value: string) => {
    setFilters({
      or: [
        {
          name: {
            containsi: value,
          },
        },
        {
          location: {
            address: {
              containsi: value,
            },
          },
        },
        {
          phoneNumber: {
            contains: value,
          },
        },
        {
          email: {
            containsi: value,
          },
        },
      ],
    });
  }, []);

  useEffect(() => {
    refetch({
      filters,
    });
  }, [filters, refetch]);

  const handleChange = useCallback(
    (id: string) => {
      setSelectedStoreId(id === 'all' ? null : id);
    },
    [setSelectedStoreId],
  );

  const handleSetAllStoresOption = useCallback((value: boolean) => {
    setHasAllStoresOption(value);
  }, []);

  return (
    <StoreContext.Provider
      value={{
        selectedStoreId,
        handleChange,
        handleSearch,
        allStores: data?.businessLocations?.data || [],
        loading,
        hasAllStoresOption,
        toggleAllStoresOption: handleSetAllStoresOption,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
});
