import CustomSquareCheckbox from '@ui/checkbox';
import { ConfigProvider, Space, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib';
import { FC } from 'react';

interface Props extends CheckboxProps {
  disabled?: boolean;
}
const CheckboxGroupItem: FC<Props> = ({ value, name }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontSizeHeading5: 12,
            fontWeightStrong: 500,
            colorTextHeading: '#1F2933',
          },
        },
      }}
    >
      <Space size={10} align={'center'}>
        <CustomSquareCheckbox
          value={value}
          checked={value}
          children={
            <Typography.Title style={{ paddingTop: 1 }} level={5}>
              {name}
            </Typography.Title>
          }
        />
      </Space>
    </ConfigProvider>
  );
};

export default CheckboxGroupItem;
