import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { CompanySelect } from '@ui/form/CompanySelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';
import { FC } from 'react';

export const MemoAdditionalFields: FC<AdditionalFieldProps> = ({ values }) => (
  <>
    <ExpandableFormItem
      label={`Product Name`}
      name={'productId'}
      defaultOpen={!!values?.productId}
    >
      <ProductSelect value={values?.productId} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={`Location`}
      name={'locationId'}
      defaultOpen={!!values?.locationId}
      withDivider
    >
      <BusinessLocationsSelect value={values?.locationId} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={`Vendor`}
      name={'vendorId'}
      defaultOpen={!!values?.vendorId}
      withDivider
    >
      <CompanySelect
        value={values?.vendorId}
        initFilter={{
          type: {
            eq: 'vendor',
          },
        }}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Receive Date'}
      name={'receiveDate'}
      defaultOpen={!!values?.receiveDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} value={values?.receiveDate} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Expiry Date'}
      name={'expiryDate'}
      defaultOpen={!!values?.expiryDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} value={values?.expiryDate} />
    </ExpandableFormItem>
  </>
);
