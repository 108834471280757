import { FC } from 'react';

import { ConfigProvider, Space, Typography } from 'antd';

import { useToken } from '@hooks/useToken';

import { Icon } from '@assets/icon';

import { CustomRadio } from '@ui/radio';

interface Props {
  label: string;
  value: string | null;
  address?: string;
  onClick?: any;
  businessLocationId?: Maybe<string>;
}
const RadioCard: FC<Props> = ({
  label,
  value,
  address,
  onClick,
  businessLocationId,
}) => {
  const { token } = useToken();

  return (
    <Space direction={'vertical'} size={[0, 12]}>
      <CustomRadio name={'shop'} value={value} onClick={onClick}>
        <Typography.Title level={4}>{label}</Typography.Title>
      </CustomRadio>
      {businessLocationId && (
        <ConfigProvider
          theme={{
            components: {
              Typography: {
                fontWeightStrong: 400,
                fontSizeHeading5: 12,
                colorTextHeading: token.colorTextDescription,
              },
            },
          }}
        >
          <Typography.Title level={5} copyable>
            {businessLocationId}
          </Typography.Title>
        </ConfigProvider>
      )}
      {address && (
        <Typography.Text style={{ color: token.colorTextHeading }}>
          <Space size={5}>
            <Icon type={'map'} />
            {address}
          </Space>
        </Typography.Text>
      )}
    </Space>
  );
};

export default RadioCard;
