import { AutocompleteInput } from '@form/inputs/autocompleteInput/AutocompleteInput';
import { useInventoryAuditItems } from '@inventory/inventoryAudit/inventoryAuditDetails/hooks/useInventoryAuditItems';
import { FC } from 'react';
import { transformDataToOptions } from './helpers/transformDataToOptions';

interface InventoryAuditProductAutocompleteProps {
  type: 'name' | 'barcode' | 'vendor';
  placeholder?: string;
}

export const InventoryAuditProductAutocomplete: FC<
  InventoryAuditProductAutocompleteProps
> = ({ type, placeholder, ...rest }) => {
  const { data } = useInventoryAuditItems();

  return (
    <AutocompleteInput
      options={transformDataToOptions(data ?? null, type)}
      placeholder={placeholder}
      {...rest}
    />
  );
};
