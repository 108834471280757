import { FC, useState } from 'react';

import { InputNumber, InputNumberProps } from 'antd';

import { formatNumber } from '@helpers/formatter';

interface Props extends InputNumberProps<number> {
  skipFormatting?: boolean;
}
export const CurrencyInput: FC<Props> = (props) => {
  const { placeholder, prefix = '$', skipFormatting, disabled } = props;

  const [isTyping, setIsTyping] = useState(false);

  const toggleIsTyping = () => {
    setIsTyping((prev) => !prev);
  };

  const formatValue: InputNumberProps<number>['formatter'] = (value, info) => {
    if (isTyping) {
      const numberForParsing = info.input.split(',').join('');

      return formatNumber(Number(numberForParsing), {
        minimumFractionDigits: 0,
      });
    }

    return formatNumber(value);
  };

  return (
    <InputNumber<number>
      {...props}
      controls={false}
      placeholder={placeholder ?? '0.00'}
      min={0}
      prefix={prefix}
      formatter={!skipFormatting ? formatValue : undefined}
      onFocus={toggleIsTyping}
      onBlur={toggleIsTyping}
      style={{ width: '100%' }}
    />
  );
};
