import { useChatNotificationsQuery } from '@/graphql';
import { useSocket } from '@/socket/useSocket';
import { useToken } from '@hooks/useToken';
import { Badge } from 'antd';
import { FC, PropsWithChildren, useEffect } from 'react';

interface Props extends PropsWithChildren {
  userId: string;
  additionalFilters?: ChatNotificationFiltersInput;
  isDot?: boolean;
  isCount?: boolean;
  isActiveChat?: boolean;
}

export const ChatNotificationBadge: FC<Props> = ({
  userId,
  additionalFilters = {},
  isDot = false,
  isCount = false,
  isActiveChat,
  children,
}) => {
  const socket = useSocket();
  const { token } = useToken();
  const { data, loading, refetch } = useChatNotificationsQuery({
    variables: {
      filters: {
        conversation: {
          user: {
            id: {
              eq: userId,
            },
          },
        },
        ...additionalFilters,
      },
    },
  });
  const count =
    isCount && data?.chatNotifications?.meta.pagination.total
      ? data?.chatNotifications?.meta.pagination.total
      : undefined;
  const dot =
    isDot && Number(data?.chatNotifications?.meta.pagination.total) > 0;
  useEffect(() => {
    const handleNotificationUpdate = () => {
      refetch();
    };

    if (!(isCount && isActiveChat)) {
      socket.on('chat-notification:create', handleNotificationUpdate);
    }
    socket.on('chat-notification:delete', handleNotificationUpdate);

    return () => {
      socket.off('chat-notification:create', handleNotificationUpdate);
      socket.off('chat-notification:delete', handleNotificationUpdate);
    };
  }, [socket, userId, isActiveChat, isCount, refetch]);

  return (
    <Badge size={'small'} color={token.colorPrimary} count={count} dot={dot}>
      {children}
    </Badge>
  );
};
