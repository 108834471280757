import { useNavigate } from 'react-router';

import { returnColumn, returnDrawerData } from '@pages/inventory/return/static';

import { useTenantRoutes } from '@router/routes';

import { Icon, IconSize } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';
import ModuleTitle from '@ui/module-title';
import { CustomTable } from '@ui/table/CustomTable';

import { useReturnsQuery } from '@/graphql';
import { useDrawerFilter } from '@filters/hooks/useDrawerFilter';
import { useEntityTableState } from '@hooks/crm/useEntityTableState';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ReturnPage = () => {
  const navigate = useNavigate();
  const { inventory, selling } = useTenantRoutes();
  const { pathname } = useLocation();
  const isSellingReturns = pathname.includes('selling');

  const handleOpenCreateReturn = () => {
    isSellingReturns
      ? navigate(selling.returns.create)
      : navigate(inventory.returns.create);
  };

  const { handleTotalChange, queryParams, tableParams, handleTableChange } =
    useEntityTableState<ReturnFragment>();

  const { handleToggleFilterDrawer, filters } =
    useDrawerFilter<ReturnFiltersInput>(returnDrawerData);

  const { data, loading, refetch } = useReturnsQuery({
    variables: {
      ...queryParams,
      filters,
    },
  });

  useEffect(() => {
    refetch();
  }, [queryParams, filters, refetch]);

  useEffect(() => {
    handleTotalChange(data?.returns?.meta.pagination.total);
  }, [data?.returns?.meta.pagination.total, handleTotalChange]);

  return (
    <>
      <ModuleTitle
        title={'Returns'}
        primaryAction={'New Return'}
        onPrimaryClick={handleOpenCreateReturn}
        buttons={[
          <CustomButton
            size={'large'}
            type={'text'}
            onClick={handleToggleFilterDrawer}
            icon={<Icon type={'filters'} size={IconSize.Large} />}
            key={'filters'}
          />,
        ]}
      />

      <CustomTable<ReturnFragment>
        style={{ marginTop: 32 }}
        rowKey={'id'}
        columns={returnColumn}
        pagination={tableParams.pagination}
        loading={loading}
        dataSource={data?.returns?.data}
        onChange={handleTableChange}
      />
    </>
  );
};

export default ReturnPage;
