import { FilterFunction } from '@filters/types';

export const createPurchaseRequestVendorFilter: FilterFunction<
  string | undefined
> = (companyId): PurchaseRequestFiltersInput => {
  const companyFilter = companyId
    ? {
        orderId: {
          company: {
            id: {
              eq: companyId,
            },
          },
        },
      }
    : {};

  return {
    ...companyFilter,
  };
};

export const createPurchaseRequestIdFilter: FilterFunction<
  string | undefined
> = (requestId): PurchaseRequestFiltersInput => {
  const requestIdFilter = requestId
    ? {
        requestId: {
          eq: requestId,
        },
      }
    : {};

  return {
    ...requestIdFilter,
  };
};
