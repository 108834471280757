import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@/router/routes';
import { FC, lazy } from 'react';

const Messaging = lazy<FC>(() => import('@pages/messaging'));
const Chat = lazy<FC>(() => import('@pages/messaging/chat'));

const { index } = ROUTES.tenant.messaging;

export const messagingRoutes: RouteObject = {
  Component: Messaging,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={':uuid'} />,
    },
    {
      Component: Chat,
      path: ':uuid',
    },
  ],
};
