import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  cloneElement,
  useState,
} from 'react';

import { AnyObject } from '@helpers/types';

import { Icon, IconSize } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';
import CustomDrawer from '@ui/drawer';

import {
  DrawerContext,
  HeaderAction,
  OpenDrawerAdditionalOptions,
} from '@drawer/drawerContext';

//TODO: Lesya - Consider refactoring for the headerAction's to accept extraComponent
export const DrawerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [formComponent, setFormComponent] = useState<ReactElement | null>(null);
  const [title, setTitle] = useState<ReactNode>('');
  const [headerAction, setHeaderAction] = useState<HeaderAction | undefined>(
    undefined,
  );
  const [extraComponent, setExtraComponent] = useState<
    ReactElement | undefined
  >(undefined);
  const [formProps, setFormProps] = useState<AnyObject>({});
  const [width, setWidth] = useState<string | number | undefined>(undefined);

  const extra = headerAction ? (
    <CustomButton
      icon={<Icon type={headerAction.iconType} size={IconSize.XXS} />}
      onClick={headerAction.action}
      type={'link'}
      style={{ gap: '6px' }}
    >
      {headerAction.title}
    </CustomButton>
  ) : (
    extraComponent
  );
  const openDrawer = <T extends PropsWithChildren>(
    formComponent: ReactElement<T>,
    drawerTitle: ReactNode,
    additionalOptions?: OpenDrawerAdditionalOptions,
  ) => {
    const { headerAction, extraComponent, drawerWidth } =
      additionalOptions || {};
    setFormComponent(formComponent);
    setTitle(drawerTitle);
    setHeaderAction(headerAction);
    setWidth(drawerWidth);
    setExtraComponent(extraComponent);
  };

  const closeDrawer = () => {
    setFormComponent(null);
    setHeaderAction(undefined);
    setFormProps({});
  };

  return (
    <DrawerContext.Provider
      value={{ openDrawer, closeDrawer, updateFormProps: setFormProps }}
    >
      {children}
      <CustomDrawer
        open={!!formComponent}
        onClose={closeDrawer}
        title={title}
        width={width}
        closeIcon={<Icon type={'close'} size={24} />}
        extra={extra}
        styles={
          !!extraComponent && !headerAction
            ? { header: { alignItems: 'flex-start' } }
            : {}
        }
      >
        {formComponent && cloneElement(formComponent, formProps)}
      </CustomDrawer>
    </DrawerContext.Provider>
  );
};
