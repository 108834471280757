import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { InventoryQuantityNotification } from '@components/notifications/NotificationItem/InventoryQuantityNotification/InventoryQuantityNotification';
import { MaintenanceQuantityNotification } from '@components/notifications/NotificationItem/MaintenanceQuantityNotification/MaintenanceQuantityNotification';
import { NylasExpireNotification } from '@components/notifications/NotificationItem/NylasExpireNotification';
import { DAY } from '@helpers/time';
import { useIsVisible } from '@hooks/useIsVisible';
import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styles from './NotificationItem.module.scss';

dayjs.extend(relativeTime);

interface Props {
  notification: UserNotificationFragment;
  handleReadNotification: (id: string) => void;
}

const RELATIVE_TIME_THRESHOLD = 25 * DAY;

const NotificationItem: FC<Props> = ({
  notification,
  handleReadNotification,
}) => {
  const {
    createdAt,
    inventoryQuantityNotification,
    maintenanceQuantityNotification,
    hasBeenSeen,
    type,
  } = notification.attributes || {};

  const createdDate = createdAt ? new Date(createdAt) : new Date();

  const relativeTime =
    Date.now() - createdDate.getTime() >= RELATIVE_TIME_THRESHOLD
      ? dayjs(createdAt).format('DD MMM`YY')
      : dayjs(createdAt).fromNow();

  const notificationData = useMemo(() => {
    switch (type) {
      case 'InventoryQuantity':
        return (
          <InventoryQuantityNotification
            notification={inventoryQuantityNotification?.data}
            hasBeenSeen={Boolean(hasBeenSeen)}
          />
        );
      case 'MaintenanceQuantity':
        return (
          <MaintenanceQuantityNotification
            notification={maintenanceQuantityNotification?.data}
            hasBeenSeen={Boolean(hasBeenSeen)}
          />
        );
      case 'NylasGrantExpire':
        return <NylasExpireNotification hasBeenSeen={Boolean(hasBeenSeen)} />;
      default:
        return;
    }
  }, [
    inventoryQuantityNotification?.data,
    maintenanceQuantityNotification?.data,
    type,
    hasBeenSeen,
  ]);

  const { isVisible, ref } = useIsVisible<HTMLElement>();

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
  }, []);

  const readNotification = useCallback(() => {
    if (notification.id) {
      handleReadNotification(notification.id);

      clearTimer();
    }
  }, [notification.id, clearTimer, handleReadNotification]);

  useEffect(() => {
    if (isVisible && !hasBeenSeen) {
      timeout.current = setTimeout(readNotification, 4000);
    }

    return clearTimer;
  }, [hasBeenSeen, isVisible, clearTimer, readNotification]);

  return (
    <Flex
      justify={'space-between'}
      gap={12}
      align={'flex-start'}
      ref={ref}
      onMouseEnter={!hasBeenSeen ? readNotification : undefined}
    >
      {notificationData}
      <Typography.Text type={'secondary'} className={styles.relativeTime}>
        {relativeTime}
      </Typography.Text>
    </Flex>
  );
};

export default NotificationItem;
