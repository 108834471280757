import { FilterFunction } from '@filters/types';
import dayjs from 'dayjs';
import { CustomerObject } from 'src/components/ui/form/ContactsANDCompaniesSelect';

export const createCustomerFilter: FilterFunction<CustomerObject> = (
  customer,
): ReturnFiltersInput => {
  const contactFilter = customer?.contact
    ? {
        contact: {
          id: {
            eq: customer.contact,
          },
        },
      }
    : {};

  const companyFilter = customer?.company
    ? {
        company: {
          id: {
            eq: customer.company,
          },
        },
      }
    : {};

  return {
    order: {
      ...contactFilter,
      ...companyFilter,
    },
  };
};

export const createReturnDateFilter: FilterFunction<
  [dayjs.Dayjs | null, dayjs.Dayjs | null]
> = (returnDate): ReturnFiltersInput => {
  const [start, end] = returnDate || [];

  if (!start && !end) {
    return {};
  }

  const startFilter = start
    ? {
        gte: start.toDate(),
      }
    : {};

  const endFilter = end
    ? {
        lte: end.toDate(),
      }
    : {};

  return {
    returnDate: {
      ...startFilter,
      ...endFilter,
    },
  };
};

export const createReturnMethodFilter: FilterFunction<string> = (
  returnMethod?: string,
): ReturnFiltersInput => {
  return returnMethod
    ? {
        returnMethod: {
          id: {
            eq: returnMethod,
          },
        },
      }
    : {};
};

export const createReturnTypeFilter: FilterFunction<string> = (
  returnType,
): ReturnFiltersInput => {
  return returnType
    ? {
        type: {
          eq: returnType,
        },
      }
    : {};
};

export const createReturnOrderTypeFilter: FilterFunction<string> = (
  orderType,
): ReturnFiltersInput => {
  return orderType
    ? {
        order: {
          type: {
            eq: orderType,
          },
        },
      }
    : {};
};
