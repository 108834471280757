import CheckboxGroupItem from '@components/reports/CheckboxGroupItem';
import { ColumnItem } from '@filters/hooks/useDrawerFilter';
import { CustomSpace } from '@ui/space';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { FC } from 'react';

interface Props extends CheckboxGroupProps {
  additionalColumns?: ColumnItem[];
}

const CheckboxGroup: FC<Props> = ({ onChange, additionalColumns, value }) => {
  return (
    <Checkbox.Group value={value} style={{ width: '100%' }} onChange={onChange}>
      <CustomSpace block direction={'vertical'} size={12}>
        {additionalColumns?.map((item) => (
          <CheckboxGroupItem key={item.value} {...item} />
        ))}
      </CustomSpace>
    </Checkbox.Group>
  );
};

export default CheckboxGroup;
