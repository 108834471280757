import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import DealTransactionTreeSelect from '@components/accounting/transactions/ui/selects/DealTransactionTreeSelect';
import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { STATUS_OPTIONS } from '@components/accounting/transactions/utils';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { Select } from 'antd';

export const DealTransactionsAdditionalFields: FC<
  AdditionalFieldProps & {
    enitityName?: 'crm-dealTransactions' | 'accounting-dealTransactions';
  }
> = ({ values, enitityName }) => {
  return (
    <>
      {enitityName === 'accounting-dealTransactions' && (
        <ExpandableFormItem
          name={'status'}
          label={'Status'}
          defaultOpen={!!values?.status}
        >
          <Select
            placeholder={'Choose status'}
            options={STATUS_OPTIONS}
            defaultActiveFirstOption
          />
        </ExpandableFormItem>
      )}
      <ExpandableFormItem
        withDivider
        name={'sellingOrder'}
        defaultOpen={!!values?.orderId}
        label={`Order Id`}
      >
        <OrderIdSelect value={values?.orderId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'chart'}
        defaultOpen={!!values?.chart}
        label={`Chart Property`}
      >
        <DealTransactionTreeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Created Date'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider={enitityName === 'accounting-dealTransactions'}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Due Date'}
        name={'dueDate'}
        defaultOpen={!!values?.dueDate}
        withDivider={enitityName === 'accounting-dealTransactions'}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
