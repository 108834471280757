import { FC, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { ROUTES } from '@/router/routes';

const Tasks = lazy<FC>(() => import('@/pages/tasks'));

const tasks = ROUTES.tenant.tasks;

export const tasksRoute: RouteObject = {
  Component: Tasks,
  path: tasks,
};
