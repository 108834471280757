import { CrmSingleEntityFiltersInput, FilterFunction } from '@filters/types';

export const createCrmEntityNameFilter: FilterFunction<string> = (
  name?: string,
): CrmSingleEntityFiltersInput => ({
  or: [
    {
      fullName: {
        containsi: name,
      },
    },
    {
      email: {
        containsi: name,
      },
    },
  ],
});

export const createCompanyNameFilters: FilterFunction<string> = (
  name?: string,
): CompanyFiltersInput => ({
  or: [
    {
      name: {
        containsi: name,
      },
    },
    {
      website: {
        containsi: name,
      },
    },
  ],
});

export const createActivityNameFilters: FilterFunction<string> = (
  name?: string,
): ActivityFiltersInput => ({
  or: [
    {
      title: {
        containsi: name,
      },
    },
    {
      description: {
        containsi: name,
      },
    },
  ],
});

export const createNotesDescriptionFilters: FilterFunction<string> = (
  name?: string,
): NoteFiltersInput => ({
  description: {
    containsi: name,
  },
});

export const createEmployeeNameFilters: FilterFunction<string> = (
  name?: string,
): UsersPermissionsUserFiltersInput => ({
  or: [
    {
      firstName: {
        containsi: name,
      },
    },
    {
      lastName: {
        containsi: name,
      },
    },
    {
      email: {
        containsi: name,
      },
    },
    {
      username: {
        containsi: name,
      },
    },
  ],
});

export const createWarehousesNameFilters: FilterFunction<string> = (
  name?: string,
): BusinessLocationFiltersInput => ({
  name: {
    containsi: name,
  },
});

export const createTransactionStatusFilters: FilterFunction<string> = (
  name?: string,
): DealTransactionFiltersInput => ({
  status: {
    eqi: name,
  },
});

export const createTasksNameFilters: FilterFunction<string> = (
  name?: string,
): TaskFiltersInput => ({
  name: {
    containsi: name,
  },
});

export const createDealsNameFilters: FilterFunction<string> = (
  name?: string,
): DealFiltersInput => ({
  name: {
    containsi: name,
  },
});

export const createCampaignNameFilters: FilterFunction<string> = (
  name?: string,
): CampaignFiltersInput => ({
  name: {
    containsi: name,
  },
});

export const createFileItemsNameFilters: FilterFunction<string> = (
  name?: string,
): FileItemFiltersInput => ({
  name: {
    containsi: name,
  },
});

export const createInventoryProductNameFilters: FilterFunction<string> = (
  name?: string,
): FileItemFiltersInput => ({
  name: {
    containsi: name,
  },
});
