import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';
import { StrandInput } from 'src/components/inventory/inventoryManagement/products/inputs/StrandInput';
import { StrandsLengthInput } from 'src/components/inventory/inventoryManagement/products/inputs/StrandsLengthInput';

export const NecklaceFilterCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'strand'}
          label={'Number of Strands'}
          style={customStyles}
        >
          <StrandInput
            onChange={handleChange('strand')}
            initialValue={initialValues?.strand ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Necklace Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'strandsLength'}
          label={'Necklace Strands Length'}
          style={customStyles}
        >
          <StrandsLengthInput
            onChange={handleChange('strandsLength')}
            initialValue={initialValues?.strandsLength ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
