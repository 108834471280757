import { useProductTypesQuery } from '@/graphql';
import { AnkletFilterCustomFields } from '@filters/additionalFields/productCustomeFields/AnkletFilterCustomFields';
import { BandFilterCustomFields } from '@filters/additionalFields/productCustomeFields/BandFilterCustomFields';
import { BraceletFilterCustomFields } from '@filters/additionalFields/productCustomeFields/BraceletFilterCustomFields';
import { BroochFilterCustomFields } from '@filters/additionalFields/productCustomeFields/BroochFilterCustomFields';
import { BroochPendantFilterCustomFields } from '@filters/additionalFields/productCustomeFields/BroochPendantFilterCustomFields';
import { ChainFilterCustomFields } from '@filters/additionalFields/productCustomeFields/ChainFilterCustomFields';
import { CuffFilterCustomFields } from '@filters/additionalFields/productCustomeFields/CuffFilterCustomFields';
import { EarringFilterCustomFields } from '@filters/additionalFields/productCustomeFields/EarringFilterCustomFields';
import { GrillzFilterCustomFields } from '@filters/additionalFields/productCustomeFields/GrillzFilterCustomFields';
import { NecklaceFilterCustomFields } from '@filters/additionalFields/productCustomeFields/NecklaceFilterCustomFielrs';
import { PearlBraceletFilterCustomFields } from '@filters/additionalFields/productCustomeFields/PearlBraceletFilterCustomFields';
import { PearlFilterNecklaceCustomFields } from '@filters/additionalFields/productCustomeFields/PearlFilterNecklaceCustomFields';
import { PendantFilterCustomFields } from '@filters/additionalFields/productCustomeFields/PendantFilterCustomFields';
import { PinFilterCustomFields } from '@filters/additionalFields/productCustomeFields/PinFilterCustomFields';
import { RingFilterCustomFields } from '@filters/additionalFields/productCustomeFields/RingFilterCustomFields';
import { WatchFilterCustomFields } from '@filters/additionalFields/productCustomeFields/WatchFilterCustomFields';
import { FilterFieldType } from '@filters/types';
import { GetChangeHandler } from '@form/hooks/useCustomForm';
import { AnyObject } from '@helpers/types';
import { ProductValues } from '@inventory/inventoryManagement/products/types';
import { CSSProperties, useMemo } from 'react';

export const useRenderProductFilterFieldsHelper = (
  productType: string,
  handleChange: GetChangeHandler<ProductValues>,
  initialValues?: (FilterFieldType & AnyObject<any>) | null | undefined,
  customStyles?: CSSProperties,
) => {
  const { data, loading } = useProductTypesQuery({
    variables: {
      filters: {
        id: {
          eq: productType,
        },
      },
    },
  });

  const condition = useMemo(() => {
    return Number(data?.productTypes?.data?.length) > 1
      ? ''
      : data?.productTypes?.data[0]?.attributes?.name;
  }, [data?.productTypes?.data]);

  if (loading) {
    return null;
  }

  switch (condition) {
    case 'Ring': {
      return (
        <RingFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Bracelet': {
      return (
        <BraceletFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Band': {
      return (
        <BandFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Earrings': {
      return (
        <EarringFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Necklace': {
      return (
        <NecklaceFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Pearl Necklace': {
      return (
        <PearlFilterNecklaceCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Chain': {
      return (
        <ChainFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Pendant': {
      return (
        <PendantFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Brooch': {
      return (
        <BroochFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Pendant/Brooch': {
      return (
        <BroochPendantFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Watch': {
      return (
        <WatchFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Pearl Bracelet': {
      return (
        <PearlBraceletFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Cufflinks': {
      return (
        <CuffFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Anklet': {
      return (
        <AnkletFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Pin': {
      return (
        <PinFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    case 'Grillz': {
      return (
        <GrillzFilterCustomFields
          handleChange={handleChange}
          initialValues={initialValues}
          customStyles={customStyles}
        />
      );
    }
    default: {
      return null;
    }
  }
};
