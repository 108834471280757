import { FC } from 'react';

import EmployeeSelect from '@ui/form/EmployeeSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import ContactsSelect from '@ui/form/ContactsSelect';

export const CampaignAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'campaignOwner'}
      label={'Campaign Owner'}
      defaultOpen={!!values?.campaignOwner?.length}
      withDivider
    >
      <EmployeeSelect
        mode={'multiple'}
        shouldApplyDefaultValue={false}
        placeholder={'Filter campaign by Campaign Owner'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'contacts'}
      label={'Enrolled contact'}
      defaultOpen={!!values?.contacts?.length}
      withDivider
    >
      <ContactsSelect
        mode={'multiple'}
        placeholder={'Filter campaigns by Enrolled Contacts'}
        defaultValue={values?.contacts ?? undefined}
      />
    </ExpandableFormItem>
  </>
);
