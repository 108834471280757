import styles from '@components/notifications/NotificationItem/NotificationItemAvatarContent/NotificationItemAvatarContent.module.scss';
import AvatarInfo, { AvatarInfoProps } from '@ui/avatar/AvatarInfo';
import { FC, PropsWithChildren } from 'react';

interface Props extends AvatarInfoProps, PropsWithChildren {
  hasBeenSeen?: boolean;
}
export const NotificationItemAvatarContent: FC<Props> = ({
  hasBeenSeen,
  title,
  ...props
}) => {
  return (
    <AvatarInfo
      {...props}
      title={
        <>
          {title}
          {!hasBeenSeen && <div className={styles.circle} />}
        </>
      }
      titleFontSize={12}
      alignAvatar={'start'}
    />
  );
};
