import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import ContactsSelect from '@ui/form/ContactsSelect';
import { Input, Select } from 'antd';
import { FC } from 'react';

const selectOptions = [
  {
    key: 1,
    value: 'pending',
    label: 'Pending',
  },
  {
    key: 2,
    value: 'signed',
    label: 'Signed',
  },
  {
    key: 3,
    value: 'declined',
    label: 'Declined',
  },
];

export const ContractsAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        label={'Contract name'}
        name={'name'}
        defaultOpen={!!values?.name}
      >
        <Input />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Choose contract status'}
        name={'status'}
        defaultOpen={!!values?.status}
        withDivider
      >
        <Select options={selectOptions} />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Choose Customer'}
        name={'contactId'}
        defaultOpen={!!values?.contactId}
        withDivider
      >
        <ContactsSelect defaultValue={values?.contactId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
