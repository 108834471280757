import { FC } from 'react';

import { InputNumber } from 'antd';

import EmployeeSelect from '@/components/ui/form/EmployeeSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { MaintenanceStatusSelect } from '@ui/form/MaintenanceStatusSelect';
import { ResourceSelect } from '@ui/form/ResourceSelect';

export const MaintenanceAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'resources'}
      label={'Resource'}
      defaultOpen={!!values?.resources?.length}
    >
      <ResourceSelect mode={'multiple'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'businessLocations'}
      label={'Location'}
      defaultOpen={!!values?.businessLocations?.length}
      withDivider
    >
      <BusinessLocationsSelect storesOnly mode={'multiple'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Last Maintenance Date'}
      name={'lastMaintenanceDate'}
      defaultOpen={!!values?.lastMaintenanceDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Next Maintenance Date'}
      name={'nextMaintenanceDate'}
      defaultOpen={!!values?.nextMaintenanceDate}
      withDivider
    >
      <DateRangeInput style={{ width: '100%' }} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Days Left'}
      name={'daysLeft'}
      defaultOpen={!!values?.daysLeft}
      withDivider
    >
      <InputNumber
        placeholder={'Enter max days left value'}
        style={{ width: '100%' }}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Status'}
      name={'status'}
      defaultOpen={!!values?.status?.length}
      withDivider
    >
      <MaintenanceStatusSelect mode={'multiple'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      label={'Maintained By'}
      name={'maintainedBy'}
      defaultOpen={!!values?.maintainedBy}
      withDivider
    >
      <EmployeeSelect shouldApplyDefaultValue={false} mode={'multiple'} />
    </ExpandableFormItem>
  </>
);
