import {
  createActivityNameFilters,
  createCampaignNameFilters,
  createCompanyNameFilters,
  createCrmEntityNameFilter,
  createDealsNameFilters,
  createEmployeeNameFilters,
  createFileItemsNameFilters,
  createInventoryProductNameFilters,
  createNotesDescriptionFilters,
  createTasksNameFilters,
  createTransactionStatusFilters,
  createWarehousesNameFilters,
} from '@filters/filterFactories/nameFilters';
import { FilterFunction } from '@filters/types';

export const getEntityNameFilterFunction = (
  entityType?: string,
): FilterFunction => {
  switch (entityType) {
    case 'leads':
      return createCrmEntityNameFilter;
    case 'contacts':
      return createCrmEntityNameFilter;
    case 'companies':
      return createCompanyNameFilters;
    case 'activity':
      return createActivityNameFilters;
    case 'notes':
      return createNotesDescriptionFilters;
    case 'employee':
      return createEmployeeNameFilters;
    case 'warehouses':
      return createWarehousesNameFilters;
    case 'transaction-status':
      return createTransactionStatusFilters;
    case 'tasks':
      return createTasksNameFilters;
    case 'deals-profile':
    case 'deals-management':
      return createDealsNameFilters;
    case 'campaign':
      return createCampaignNameFilters;
    case 'fileItems':
      return createFileItemsNameFilters;
    case 'product':
      return createInventoryProductNameFilters;
    default:
      return () => ({});
  }
};
