import { useInventoryAuditItemRangeDataQuery } from '@/graphql';
import { useParams } from 'react-router';

export const useInventoryAuditItemsRangeData = ({
  fieldKey,
}: {
  fieldKey: string;
}): InventoryAuditItemRangeData => {
  const params = useParams();
  const { data } = useInventoryAuditItemRangeDataQuery({
    variables: {
      uuid: params.uuid as string,
      key: fieldKey ? fieldKey : '',
    },
  });
  const min = data?.inventoryAuditItemRangeData?.min ?? 0;
  const max = data?.inventoryAuditItemRangeData?.max ?? 0;

  return { min, max };
};
