import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { Input } from 'antd';
import { FC } from 'react';

export const AppraisalAdditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => {
  return (
    <>
      <ExpandableFormItem
        label={'Document name'}
        name={'name'}
        defaultOpen={!!values?.name}
      >
        <Input />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Created By'}
        name={'employee'}
        defaultOpen={!!values?.employee}
        withDivider
      >
        <EmployeeSelect
          shouldApplyDefaultValue={false}
          mode={'multiple'}
          limit={-1}
        />
      </ExpandableFormItem>

      <ExpandableFormItem
        label={'Date Created'}
        name={'createdAt'}
        defaultOpen={!!values?.createdAt}
        withDivider
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
