import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Col, Pagination, Row } from 'antd';

import CreateDealForm from '@/components/crm/profile/deals/CreateDealForm/CreateDealForm';
import DealsList from '@/components/crm/profile/deals/DealsList';
import { CustomSpace } from '@/components/ui/space';
import { useDealsQuery } from '@/graphql';

import { Loader } from '@components/layout/MainLayout';

import { useGetEntityByUuid } from '@hooks/crm/useGetEntityByUuid';

import { Icon, IconSize } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';
import ModuleTitle from '@ui/module-title';

import { useDrawerFilter } from '@filters/hooks/useDrawerFilter';

import { useDrawer } from '@drawer/drawerContext';

import getProfileTitle from '@crm/helpers/getProfileTitle';
import ProfileSegmented from '@crm/profile/ProfileSegmented/ProfileSegmented';
import { getActivityRelationField } from '@crm/profile/activity/helpers/getActivityRelationField';
import { useProfileContext } from '@crm/profile/context/profileContext/useProfileContext';
import { CrmEntityCollectionType } from '@crm/types';

const Deals = () => {
  const { uuid } = useParams();
  const { pathname } = useLocation();
  const title = useMemo(
    () => getProfileTitle(pathname) as CrmEntityCollectionType,
    [pathname],
  );

  const { data: profileData } = useGetEntityByUuid(title, uuid as string);

  const { openDrawer, closeDrawer } = useDrawer();

  const [dealsPage, setDealsPage] = useState<number>(1);

  const { handleToggleFilterDrawer, filters } = useDrawerFilter({
    entityName: 'deals-profile',
    entityTitle: 'Deals',
  });

  const ctx = useProfileContext();

  const activityContext = useMemo(
    () => getActivityRelationField(ctx ?? ''),
    [ctx],
  );

  const entityId = useMemo(
    () => ({
      contacts: (profileData as ContactByUuidQuery)?.contacts?.data?.[0]?.id,
      leads: (profileData as LeadByUuidQuery)?.leads?.data?.[0]?.id,
      companies: (profileData as CompanyByUuidQuery)?.companies?.data?.[0]?.id,
    }),
    [profileData],
  );

  const {
    data: deals,
    loading,
    refetch: refetchDeals,
  } = useDealsQuery({
    variables: {
      filters: {
        ...filters,
        [activityContext?.slice(0, -3)]: { uuid: { eq: uuid } },
      } as DealFiltersInput,
      pagination: { page: dealsPage, pageSize: 6 },
      sort: 'createdAt:desc',
    },
  });

  const handleCreateNewDeal = useCallback(() => {
    openDrawer(
      <CreateDealForm
        onCancel={closeDrawer}
        id={entityId?.[ctx as CrmEntityCollectionType] ?? ''}
        context={ctx}
        location={'crm-deals'}
      />,
      'Create Deal',
    );
  }, [closeDrawer, entityId, ctx, openDrawer]);

  const totalDeals = deals?.deals?.meta?.pagination?.total;

  const onPaginationChange = useCallback(
    (pageNumber: number) => {
      refetchDeals().then(() => setDealsPage(pageNumber));
    },
    [refetchDeals],
  );

  return (
    <Row gutter={[24, 24]} justify={'end'} align={'top'} wrap={false}>
      <Col flex={'auto'}>
        <CustomSpace direction={'vertical'} size={32} block>
          <ProfileSegmented defaultValue={'Deals'} />
          <ModuleTitle
            title={`Deals`}
            primaryAction={`Create Deal`}
            onPrimaryClick={handleCreateNewDeal}
            buttons={[
              <CustomButton
                size={'large'}
                type={'text'}
                onClick={handleToggleFilterDrawer}
                icon={<Icon type={'filters'} size={IconSize.Large} />}
                key={'filters'}
              />,
            ]}
          />
          {loading ? <Loader spinning /> : <DealsList data={deals} />}
          <Row justify={'end'}>
            {!!totalDeals && totalDeals > 6 && (
              <Pagination
                onChange={onPaginationChange}
                current={dealsPage}
                pageSize={6}
                simple
                total={totalDeals}
              />
            )}
          </Row>
        </CustomSpace>
      </Col>
    </Row>
  );
};

export default Deals;
