import { SalesItemReportFilterCustomFields } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { FC } from 'react';
import { DesignStyleInput } from 'src/components/inventory/inventoryManagement/products/inputs/DesignStyleInput';
import { SpecificTypeInput } from 'src/components/inventory/inventoryManagement/products/inputs/SpecificTypeInput';

export const PendantFilterCustomFields: FC<
  SalesItemReportFilterCustomFields
> = ({ initialValues, handleChange, customStyles }) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'designStyle'}
          label={'Design Style'}
          style={customStyles}
        >
          <DesignStyleInput
            onChange={handleChange('designStyle')}
            initialValue={initialValues?.designStyle ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'specificType'}
          label={'Pendant Type'}
          style={customStyles}
        >
          <SpecificTypeInput
            onChange={handleChange('specificType')}
            initialValue={initialValues?.specificType ?? undefined}
            editable={false}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
