import dayjs from 'dayjs';

import { transformToDateFilterInput } from '@helpers/filters';

export const createNameAppraisalFilter = (
  name?: string,
): AppraisalFiltersInput => {
  return {
    name: {
      containsi: name,
    },
  };
};

export const createEmployeeAppraisalFilter = (
  employee?: string[],
): AppraisalFiltersInput => {
  return {
    employee: {
      id: {
        in: employee,
      },
    },
  };
};

export const createAppraisalCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): AppraisalFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
