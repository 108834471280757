import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { transformToDateFilterInput } from '@helpers/filters';
import { AnyObject } from '@helpers/types';

import { getEntityNameFilterFunction } from '@filters/filterMaps';
import { FiltersForm } from '@filters/form';
import { useAdditionalFilters } from '@filters/hooks/useAdditionalFilters';
import { FilterFieldType, PossibleFilters } from '@filters/types';

import { useDrawer } from '@drawer/drawerContext';

export interface ColumnItem {
  value: string;
  name: string;
}
interface Props {
  entityName?: string;
  entityTitle?: string;
  withDefaultFilters?: boolean;
  additionalColumns?: ColumnItem[];
}

interface DrawerFilterReturnProps<
  FilterType extends PossibleFilters = PossibleFilters,
  FilterValues extends AnyObject = AnyObject,
> {
  handleToggleFilterDrawer: () => void;
  filters: FilterType;
  values: Maybe<FilterFieldType & FilterValues> | undefined;
  resetFilters: () => void;
  setFilters: Dispatch<SetStateAction<FilterType>>;
  extraColumns: string[];
}

export const useDrawerFilter = <
  FilterType extends PossibleFilters = PossibleFilters,
  FilterValues extends AnyObject = AnyObject,
>({
  entityName,
  entityTitle,
  withDefaultFilters = true,
  additionalColumns,
}: Props): DrawerFilterReturnProps<FilterType, FilterValues> => {
  const { openDrawer, closeDrawer } = useDrawer();

  const [filters, setFilters] = useState<FilterType>({} as FilterType);
  const [values, setValues] = useState<Maybe<FilterFieldType & FilterValues>>();
  const [extraColumns, setExtraColumns] = useState<string[] | []>([]);
  const { additionalFields, additionalFilterFunction } =
    useAdditionalFilters<FilterValues>({
      entityName,
      values,
    });

  const clearFilter = useCallback(() => {
    setFilters({} as FilterType);
    setValues(undefined);
    setExtraColumns([]);
  }, [setFilters, setValues]);

  const handleSubmitValues = useCallback(
    (values: FilterFieldType & FilterValues) => {
      const { createdAt, name, columns } = values;

      const defaultFilters = withDefaultFilters
        ? {
            ...(createdAt && {
              createdAt: transformToDateFilterInput(createdAt),
            }),
            ...(name && {
              ...getEntityNameFilterFunction(entityName)(name),
            }),
          }
        : {};

      if (columns) {
        setExtraColumns(columns as string[] | []);
      }

      setValues(values);
      setFilters({
        ...defaultFilters,
        ...additionalFilterFunction?.(values),
      } as FilterType);
    },
    [withDefaultFilters, entityName, additionalFilterFunction],
  );

  const handleToggleFilterDrawer = useCallback(() => {
    openDrawer(
      <FiltersForm<FilterFieldType & FilterValues>
        handleSubmit={handleSubmitValues}
        onCancel={closeDrawer}
        initialValues={values ?? undefined}
        entityName={entityName}
        entityTitle={entityTitle}
        withDefaultFilters={withDefaultFilters}
        additionalColumns={additionalColumns}
      >
        {additionalFields}
      </FiltersForm>,
      `Filters`,
      {
        headerAction: {
          title: 'Clear filters',
          iconType: 'delete',
          action: () => {
            clearFilter();
            closeDrawer();
          },
        },
      },
    );
  }, [
    openDrawer,
    handleSubmitValues,
    closeDrawer,
    values,
    entityName,
    entityTitle,
    withDefaultFilters,
    additionalColumns,
    additionalFields,
    clearFilter,
  ]);

  return useMemo(
    () => ({
      handleToggleFilterDrawer,
      filters,
      values,
      resetFilters: clearFilter,
      setFilters,
      extraColumns,
    }),
    [clearFilter, extraColumns, filters, handleToggleFilterDrawer, values],
  );
};
