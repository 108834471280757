import { FC } from 'react';

import { EmployeeStatusSelect } from '@components/hr/employees/employeeInputs/EmployeeStatusSelect';

import { UserRolesSelect } from '@ui/form/UserRolesSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';

export const EmployeeAditionalFields: FC<AdditionalFieldProps> = ({
  values,
}) => (
  <>
    <ExpandableFormItem
      name={'status'}
      label={'Employee Status'}
      defaultOpen={!!values?.status}
      withDivider
    >
      <EmployeeStatusSelect />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'team'}
      label={'Employee Team'}
      defaultOpen={!!values?.team}
      withDivider
    >
      <UserRolesSelect placeholder={'Select Team'} />
    </ExpandableFormItem>
  </>
);
