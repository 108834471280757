import { CustomFormItem } from '@form/item/FormItem';

import { colorOptions } from '@inventory/inventoryManagement/products/static/colorOptions';
import { STONE_COLOR_FIELD_NAME } from '@inventory/inventoryManagement/products/static/fieldName';
import { Select } from 'antd';
import { CSSProperties, FC, useEffect, useState } from 'react';

import { GetChangeHandler } from '@form/hooks/useCustomForm';

import { AnyObject } from '@helpers/types';
import { MultiplyStoneColorDominantSelect } from '@inventory/inventoryManagement/products/inputs/ColorDominantInput/MultiplyStoneColorDominantSelect';
import { MultiplyStoneColorIntensitySelect } from '@inventory/inventoryManagement/products/inputs/ColorIntensityInput/MultiplyStoneColorIntensitySelect';
import { MultiplyStoneColorSecondarySelect } from '@inventory/inventoryManagement/products/inputs/ColorSecondaryInput/MultiplyStoneColorSecondarySelect';

interface Props {
  type: EnumStoneType;
  getChangeHandler: GetChangeHandler<AnyObject>;
  initialValues?: any;
  customStyles?: CSSProperties;
}

export const DiamondColorCustomFields: FC<Props> = ({
  type,
  initialValues,
  getChangeHandler,
  customStyles,
}) => {
  const [color, setColor] = useState('');

  const handleColorChange = (value: string) => {
    setColor(value);
  };

  useEffect(() => {
    if (initialValues?.diamondColor) {
      setColor('D-Z');
    }
    if (initialValues?.colorIntensity) {
      setColor('Fancy');
    }
  }, [initialValues?.diamondColor, initialValues?.colorIntensity]);
  return (
    <>
      <CustomFormItem
        label={'Color'}
        style={color ? { marginBottom: 24 } : customStyles}
      >
        <Select
          allowClear
          value={color}
          onChange={handleColorChange}
          options={[
            { value: 'D-Z', label: 'D-Z' },
            { value: 'Fancy', label: 'Fancy' },
          ]}
        />
      </CustomFormItem>

      {color === 'Fancy' && (
        <>
          <CustomFormItem
            name={[STONE_COLOR_FIELD_NAME, 'colorIntensity']}
            label={'Color Intensity'}
            style={color ? { marginBottom: 24 } : customStyles}
          >
            <MultiplyStoneColorIntensitySelect
              allowClear
              initialValue={initialValues?.colorIntensity ?? undefined}
              type={type}
              onChange={getChangeHandler('colorIntensity')}
            />
          </CustomFormItem>
          <CustomFormItem
            name={[STONE_COLOR_FIELD_NAME, 'colorSecondary']}
            label={'Secondary Colors'}
            style={color ? { marginBottom: 24 } : customStyles}
          >
            <MultiplyStoneColorSecondarySelect
              allowClear
              initialValue={initialValues?.colorSecondary ?? undefined}
              onChange={getChangeHandler('colorSecondary')}
            />
          </CustomFormItem>
          <CustomFormItem
            name={[STONE_COLOR_FIELD_NAME, 'colorDominant']}
            label={'Dominant Color'}
            style={customStyles}
          >
            <MultiplyStoneColorDominantSelect
              allowClear
              initialValue={initialValues?.colorDominant ?? undefined}
              onChange={getChangeHandler('colorDominant')}
            />
          </CustomFormItem>
        </>
      )}

      {color === 'D-Z' && (
        <CustomFormItem
          name={[STONE_COLOR_FIELD_NAME, 'diamondColor']}
          label={'D-Z'}
          style={customStyles}
        >
          <Select allowClear mode={'multiple'} options={colorOptions} />
        </CustomFormItem>
      )}
    </>
  );
};
