import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Hr = lazy<FC>(() => import('@/pages/hr'));
const HrDashboard = lazy<FC>(() => import('@/pages/hr/dashboard'));
const HrManagement = lazy<FC>(() => import('@/pages/hr/management'));

const { index, dashboard, management } = ROUTES.tenant.hr;

export const hrRoutes: RouteObject = {
  Component: Hr,
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={dashboard} />,
    },
    {
      Component: HrDashboard,
      path: dashboard,
    },
    {
      Component: HrManagement,
      path: management,
    },
  ],
};
