import { useBoxPapersLazyQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { isNotEmpty } from '@helpers/types';
import { useProductFormWatch } from '@inventory/inventoryManagement/products/hooks/useProductFormWatch';
import { useDataSelectMultiple } from '@inventory/inventoryManagement/products/inputs/useDataSelectMultiple';
import { SelectProps } from 'antd/lib';
import { FC, useEffect, useMemo } from 'react';

interface Props extends SelectProps {
  onChange?: (value: string[] | null) => void;
  initialValue?: Maybe<string>[];
}

export const MultiplyBoxPaperSelect: FC<Props> = ({
  onChange,
  initialValue,
  ...props
}) => {
  const { productType } = useProductFormWatch();
  const initialValues = useMemo(
    () => initialValue?.filter(isNotEmpty) || [],
    [initialValue],
  );

  const { handleChange, handleSearch, queryParams, selectedValue } =
    useDataSelectMultiple({
      onChange,
      initialSelectedValue: initialValues,
      initialFilters: {
        productType: {
          id: { eq: productType },
        },
      },
    });

  const [fetch, { data, loading }] = useBoxPapersLazyQuery({
    variables: {
      ...queryParams,
      pagination: {
        limit: -1,
      },
      sort: ['createAt:asc'],
    },
  });

  useEffect(() => {
    fetch();
  }, [fetch]);

  const options = useMemo(() => {
    const types = data?.boxPapers?.data || [];

    return types?.map((entity) => ({
      value: entity?.id,
      label: entity?.attributes?.name,
    }));
  }, [data?.boxPapers?.data]);

  return (
    <SearchSelect
      defaultValue={initialValues}
      placeholder={'Select the attribute'}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      loading={loading}
      onSearch={handleSearch}
      mode={'multiple'}
      {...props}
    />
  );
};
