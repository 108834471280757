import React, { FC } from 'react';

import { Checkbox, ConfigProvider } from 'antd';
import { CheckboxProps } from 'antd/lib';

import styles from './index.module.scss';

interface Props extends CheckboxProps {
  disabled?: boolean;
}

const CustomSquareCheckbox: FC<Props> = ({ disabled, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorBorder: disabled ? '#9E9E9E' : '#4876D5',
            colorPrimary: '#0146C5',
            colorPrimaryBorder: '#0146C5',
            colorPrimaryHover: '#0146C5',
            controlInteractiveSize: 20,
            lineWidth: 4,
            lineWidthBold: 2,
            paddingXS: 14,
            borderRadiusSM: 2,
          },
        },
      }}
    >
      <Checkbox {...props} className={styles.checkbox} />
    </ConfigProvider>
  );
};

export default React.memo(CustomSquareCheckbox);
