import { FC } from 'react';

import EmployeeSelect from '@ui/form/EmployeeSelect';
import LeadSourceSelect from '@ui/form/LeadSourceSelect';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { AdditionalFieldProps } from '@filters/types';
import { CompanySelect } from '@ui/form/CompanySelect';

import ContactsSelect from '@ui/form/ContactsSelect';

export const ContactAdditionalFields: FC<
  AdditionalFieldProps & { entityName: string }
> = ({ values, entityName }) => (
  <>
    <ExpandableFormItem
      name={'contact'}
      label={'Contact'}
      defaultOpen={!!values?.contact}
    >
      <ContactsSelect defaultValue={values?.contact ?? undefined} />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadSource'}
      label={'Lead Source'}
      defaultOpen={!!values?.leadSource?.length}
      withDivider={entityName === 'contacts'}
    >
      <LeadSourceSelect mode={'multiple'} />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'leadOwner'}
      label={'Contact Owner'}
      defaultOpen={!!values?.leadOwner?.length}
      withDivider
    >
      <EmployeeSelect
        mode={'multiple'}
        shouldApplyDefaultValue={false}
        placeholder={'Filter contacts by Contact Owner'}
      />
    </ExpandableFormItem>
    <ExpandableFormItem
      name={'company'}
      label={'Company'}
      defaultOpen={!!values?.company?.length}
      withDivider
    >
      <CompanySelect allowClear />
    </ExpandableFormItem>
  </>
);
