import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const WebsiteEditor = lazy<FC>(() => import('@/pages/websiteEditor'));

const CreateFooterForm = lazy<FC>(
  () => import('@/pages/websiteEditor/footer/createFooterForm'),
);
const CustomerReviewForm = lazy<FC>(
  () => import('@/pages/websiteEditor/customer-review/customerReviewForm'),
);
const FollowUsForm = lazy<FC>(
  () => import('@/pages/websiteEditor/follow-us/followUsForm'),
);
const SubscribeNewsLetterForm = lazy<FC>(
  () =>
    import(
      '@/pages/websiteEditor/subscribe-newsletter/subscribeNewsletterForm'
    ),
);
const HomePage = lazy<FC>(
  () => import('@/pages/websiteEditor/home-page/HomePage'),
);
const WebsiteSettings = lazy<FC>(
  () => import('@/pages/websiteEditor/website-settings/WebsiteSettings'),
);
const ShopPage = lazy<FC>(
  () => import('@/pages/websiteEditor/shop-page/ShopPage'),
);
const AboutUs = lazy<FC>(
  () => import('@/pages/websiteEditor/about-us/AboutUs'),
);
const Blog = lazy<FC>(() => import('@/pages/websiteEditor/blog/Blog'));
const Faq = lazy<FC>(() => import('@/pages/websiteEditor/faq/Faq'));
const PrivacyPolicy = lazy<FC>(
  () =>
    import(
      '@pages/websiteEditor/privacy-policy-terms-conditions/PrivacyPolicy'
    ),
);
const TermsConditions = lazy<FC>(
  () =>
    import(
      '@/pages/websiteEditor/privacy-policy-terms-conditions/TermsConditions'
    ),
);
const {
  index,
  footer,
  customReview,
  subscribeNewsletter,
  followUs,
  homePage,
  websiteSettings,
  shopPage,
  aboutUs,
  blog,
  faq,
  privacyPolicy,
  termsConditions,
} = ROUTES.tenant.websiteEditor;

export const websiteRoutes: RouteObject = {
  path: index,
  Component: WebsiteEditor,
  children: [
    {
      index: true,
      element: <Navigate to={websiteSettings} />,
    },
    {
      path: footer,
      Component: CreateFooterForm,
    },
    {
      path: customReview,
      Component: CustomerReviewForm,
    },
    {
      path: subscribeNewsletter,
      Component: SubscribeNewsLetterForm,
    },
    {
      path: followUs,
      Component: FollowUsForm,
    },
    {
      path: homePage,
      Component: HomePage,
    },
    {
      path: websiteSettings,
      Component: WebsiteSettings,
    },
    {
      path: shopPage,
      Component: ShopPage,
    },
    {
      path: aboutUs,
      Component: AboutUs,
    },
    {
      path: blog,
      Component: Blog,
    },
    {
      path: faq,
      Component: Faq,
    },
    {
      path: privacyPolicy,
      Component: PrivacyPolicy,
    },
    {
      path: termsConditions,
      Component: TermsConditions,
    },
  ],
};
