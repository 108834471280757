import { ChangeEvent, FC, useCallback, useState } from 'react';

import { Col, Input, Row } from 'antd';

import { Icon } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';

interface Props {
  handleAddNew: (value: string) => Promise<void>;
  loading?: boolean;
  shouldRenderIcon?: boolean;
}

export const AddNewSelectButton: FC<Props> = ({
  handleAddNew,
  loading,
  shouldRenderIcon = true,
}) => {
  const [name, setName] = useState<string>('');

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleClick = useCallback(async () => {
    await handleAddNew(name);

    setName('');
  }, [handleAddNew, name]);

  return (
    <Row align={'middle'} gutter={8} style={{ padding: '4px 8px' }}>
      <Col span={16}>
        <Input
          placeholder={'Enter name'}
          value={name}
          onChange={handleNameChange}
          disabled={loading}
        />
      </Col>
      <Col span={8}>
        <CustomButton
          icon={shouldRenderIcon && <Icon type={'plus-circle'} />}
          onClick={handleClick}
          block
          loading={loading}
          disabled={!name}
          size={'large'}
        >
          Add new
        </CustomButton>
      </Col>
    </Row>
  );
};
