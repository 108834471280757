import { Flex, InputNumber, Slider } from 'antd';
import { useEffect, useState } from 'react';

import * as helpers from '@ui/form/PriceRangeInput/helpers';

export const StoneWeightCustomInput = ({
  onChange,
  initialValue,
}: {
  onChange: (value: any) => void;
  initialValue?: { value?: number[]; unit: EnumWeightUnit };
}) => {
  const [range, setRange] = useState<number[]>([0.5, 14.5]);

  const handleRangeChange = (value: number[]) => {
    setRange(value);
    onChange?.({ value: range, unit: 'ct' });
  };

  const handleMinValueChange = (value: number | null) => {
    if (value !== null) {
      setRange([value, range[1]]);
      onChange?.({ value: [value, range[1]], unit: 'ct' });
    }
  };

  const handleMaxValueChange = (value: number | null) => {
    if (value !== null) {
      setRange([range[0], value]);
      onChange?.({ value: [range[0], value], unit: 'ct' });
    }
  };

  useEffect(() => {
    if (initialValue?.value) {
      setRange(initialValue?.value);
    }
  }, [initialValue?.value]);

  return (
    <>
      <Slider
        range
        defaultValue={[0.2, 9]}
        value={range}
        onChange={handleRangeChange}
        min={0}
        max={15}
        autoFocus={true}
        tipFormatter={(value) => `${value} ct`}
        step={0.1}
      />
      <Flex
        style={{ width: '100%' }}
        align={'center'}
        justify={'space-between'}
        gap={15}
      >
        <InputNumber<number>
          min={0}
          max={15}
          step={0.1}
          value={range[0]}
          defaultValue={0}
          onChange={handleMinValueChange}
          parser={helpers.priceInputParser}
          style={{ width: '100%' }}
        />
        <InputNumber<number>
          min={0}
          max={15}
          step={0.1}
          value={range[1]}
          defaultValue={10}
          onChange={handleMaxValueChange}
          parser={helpers.priceInputParser}
          style={{ width: '100%' }}
        />
      </Flex>
    </>
  );
};
