import { FC, useMemo } from 'react';

import { Card, ConfigProvider, Flex, Space, Typography } from 'antd';

import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';

import { useToken } from '@/hooks/useToken';

import { formatToCurrency } from '@helpers/formatter';

import AvatarInfo from '@ui/avatar/AvatarInfo';
import CustomBadge from '@ui/badge/StatusBadge';
import { CustomSpace } from '@ui/space';

import { DealsDropdown } from '@crm/profile/deals/DealsDropdown';
import { DealStages } from '@crm/profile/deals/types';

import styles from './deals.module.scss';

interface Props {
  deal: DealFragment;
}

const DealsCard: FC<Props> = ({ deal }) => {
  const { token } = useToken();
  const dealAttributes = deal?.attributes;
  const currentCardName =
    dealAttributes?.contact?.data?.attributes?.fullName ||
    dealAttributes?.company?.data?.attributes?.name ||
    dealAttributes?.lead?.data?.attributes?.fullName;
  const currentCardPhotoUrl =
    dealAttributes?.contact?.data?.attributes?.avatar?.data?.attributes?.url ||
    dealAttributes?.company?.data?.attributes?.avatar?.data?.attributes?.url ||
    dealAttributes?.lead?.data?.attributes?.avatar?.data?.attributes?.url;

  const badgeTypeProp = useMemo(() => {
    switch (dealAttributes?.stage) {
      case DealStages.New:
      case DealStages.Sourcing:
        return 'processing';
      case DealStages.Negotiating:
        return 'warning';
      case DealStages.Won:
        return 'success';
      case DealStages.Lost:
        return 'error';
      default:
        return 'default';
    }
  }, [dealAttributes?.stage]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontSizeHeading5: 12,
            colorTextHeading: token.colorText,
            fontWeightStrong: 500,
            colorText: token.colorTextDescription,
          },
        },
      }}
    >
      <Card>
        <CustomSpace direction={'vertical'} block size={20}>
          <Flex justify={'space-between'}>
            <Space size={0} direction={'vertical'}>
              <Typography.Title level={5}>
                {dealAttributes?.name}
              </Typography.Title>
              <CustomBadge
                text={capitalize(dealAttributes?.stage ?? 'no stage')}
                type={badgeTypeProp}
              />
            </Space>
            <DealsDropdown entityId={deal?.id ?? ''} />
          </Flex>
          <AvatarInfo
            title={currentCardName ?? 'No name'}
            alt={currentCardName ?? 'No name'}
            src={currentCardPhotoUrl}
          />
          <Flex gap={20} justify={'space-between'}>
            <Space size={0} style={{ width: '50%' }} direction={'vertical'}>
              <Typography.Text style={{ fontSize: 12 }} type={'secondary'}>
                Budget
              </Typography.Text>
              <Typography.Title level={5}>
                {formatToCurrency(dealAttributes?.budget)}
              </Typography.Title>
            </Space>
            <Space size={0} style={{ width: '50%' }} direction={'vertical'}>
              <Typography.Text style={{ fontSize: 12 }}>
                Start Date
              </Typography.Text>
              <Typography.Title level={5}>
                {dayjs(dealAttributes?.startDate).format('MM/DD/YYYY')}
              </Typography.Title>
            </Space>
          </Flex>
          <Space style={{ width: '100%' }} direction={'vertical'}>
            <Typography.Text style={{ fontSize: 12 }}>Notes</Typography.Text>
            <CustomSpace className={styles.textShow} block>
              <Typography.Paragraph
                style={{ fontSize: 12 }}
                ellipsis={{ rows: 3 }}
              >
                {dealAttributes?.notes}
              </Typography.Paragraph>
            </CustomSpace>
          </Space>
        </CustomSpace>
      </Card>
    </ConfigProvider>
  );
};

export default DealsCard;
