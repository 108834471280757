import { Layout, Typography } from 'antd';
import { FC } from 'react';

const { Title, Paragraph } = Typography;

const PrivacyPolicy: FC = () => {
  return (
    <Layout style={{ padding: '0 50px', marginTop: '20px' }}>
      <Title level={2} style={{ marginBottom: 10 }}>
        Privacy Policy
      </Title>
      <Paragraph>
        Our Company is committed to protecting the privacy and security of your
        personal information. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you use our website (the
        Service). By accessing or using the Service, you agree to the terms of
        this Privacy Policy.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        1. Information We Collect
      </Title>
      <Paragraph>
        We may collect the following types of personal information:
      </Paragraph>

      <Title level={4} style={{ marginBottom: 10 }}>
        Personal Identification Information:
      </Title>
      <Paragraph>
        • Name, email address, postal address, phone number, or other similar
        identifiers.
      </Paragraph>
      <Title level={4} style={{ marginBottom: 10 }}>
        Non-personal Information:
      </Title>
      <Paragraph>
        • Information about your device, IP address, browser type, and usage
        patterns through cookies and similar technologies.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        2. How We Collect Information
      </Title>
      <Paragraph>We collect information in the following ways:</Paragraph>

      <Title level={4} style={{ marginBottom: 10 }}>
        Directly from You:
      </Title>
      <Paragraph>
        • When you provide information to us through forms, registrations, or
        other interactions.
      </Paragraph>
      <Title level={4} style={{ marginBottom: 10 }}>
        Automatically:
      </Title>
      <Paragraph>
        • Through cookies, web beacons, and similar technologies as you navigate
        through the Service.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        3. Use of Information
      </Title>
      <Paragraph>
        We use the collected information for the following purposes:
      </Paragraph>
      <Paragraph>To provide and maintain the Service.</Paragraph>
      <Paragraph>To improve and personalize the Service.</Paragraph>
      <Paragraph>
        To communicate with you, including responding to your inquiries and
        providing updates.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        4. Disclosure of Information
      </Title>
      <Paragraph>We may share your information with:</Paragraph>

      <Paragraph>
        Third-party service providers assisting with the operation of the
        Service.
      </Paragraph>
      <Paragraph>
        Legal authorities if required by law or to protect our rights.
      </Paragraph>

      <Title style={{ marginBottom: 10 }} level={3}>
        5. Your Choices
      </Title>
      <Paragraph>You can:</Paragraph>
      <Paragraph>Opt-out of certain data collection practices.</Paragraph>
      <Paragraph>
        Access, correct, or delete your personal information.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        6. Security
      </Title>
      <Paragraph>
        We employ reasonable security measures to protect your information from
        unauthorized access.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        7. Data Retention
      </Title>
      <Paragraph>
        We retain your information for as long as necessary to fulfill the
        purposes outlined in this Privacy Policy.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        8. Changes to This Privacy Policy
      </Title>
      <Paragraph>
        We reserve the right to update this Privacy Policy. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </Paragraph>

      <Title level={3} style={{ marginBottom: 10 }}>
        9. Contact Us
      </Title>
      <Paragraph>
        For any questions or concerns about this Privacy Policy, contact us at
        [Contact Information].
      </Paragraph>
    </Layout>
  );
};

export default PrivacyPolicy;
