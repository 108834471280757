import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';
import dayjs from 'dayjs';

export const createStatusFilter: FilterFunction<string> = (
  status?: string,
): DealTransactionFiltersInput => {
  return status
    ? {
        status: {
          eq: status,
        },
      }
    : {};
};

export const createSalesOrderFilter: FilterFunction<string> = (
  sellingOrder?: string,
): DealTransactionFiltersInput => {
  return sellingOrder
    ? {
        sellingOrder: {
          id: {
            eq: sellingOrder,
          },
        },
      }
    : {};
};

export const createDealTransactionCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): DealTransactionFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};

export const createDealTransactionDueDateFilter: FilterFunction<Date> = (
  dueDate?: Date,
): DealTransactionFiltersInput => {
  const date = dayjs(dueDate);
  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');

  return dueDate
    ? {
        and: [
          {
            dueDate: {
              between: [startOfDay.toDate(), endOfDay.toDate()],
            },
          },
        ],
      }
    : {};
};
