import dayjs from 'dayjs';

import { FilterFunction } from '@filters/types';
import { transformToDateFilterInput } from '@helpers/filters';

export const createTransferOrdersLocationFromFilters: FilterFunction<string> = (
  location?: string,
): TransferOrderFiltersInput => ({
  locationFrom: {
    id: {
      eq: location,
    },
  },
});
export const createTransferOrdersSubLocationFromFilters: FilterFunction<
  string
> = (sublocation?: string): TransferOrderFiltersInput => ({
  sublocationFrom: {
    id: {
      eq: sublocation,
    },
  },
});

export const createTransferOrdersLocationToFilters: FilterFunction<string> = (
  location?: string,
): TransferOrderFiltersInput => ({
  locationTo: {
    id: {
      eq: location,
    },
  },
});

export const createTransferOrdersSubLocationToFilters: FilterFunction<
  string
> = (sublocation?: string): TransferOrderFiltersInput => ({
  sublocationTo: {
    id: {
      eq: sublocation,
    },
  },
});

export const createTransferOrdersProductFilters: FilterFunction<string> = (
  product?: string,
): TransferOrderFiltersInput => ({
  transferOrderItems: {
    product: {
      id: {
        eq: product,
      },
    },
  },
});

export const createTransferOrdersEmployeeFilters: FilterFunction<string> = (
  employee?: string,
): TransferOrderFiltersInput => ({
  employee: {
    id: {
      eq: employee,
    },
  },
});

export const createTransferOrdersTransferDateFilter: FilterFunction<Date> = (
  orderDate?: Date,
): TransferOrderFiltersInput => {
  const date = dayjs(orderDate);
  const startOfDay = date.startOf('day');
  const endOfDay = date.endOf('day');

  return orderDate
    ? {
        orderDate: {
          between: [startOfDay.toDate(), endOfDay.toDate()],
        },
      }
    : {};
};

export const createTransferOrdersCreatedAtFilter = (
  createdAt?: [null | dayjs.Dayjs, null | dayjs.Dayjs] | null,
): TransferOrderFiltersInput => {
  return createdAt
    ? {
        ...(createdAt && {
          createdAt: transformToDateFilterInput(createdAt),
        }),
      }
    : {};
};
