import { FC, Ref } from 'react';

import { ConfigProvider, Segmented, SegmentedProps } from 'antd';

import classNames from 'classnames';
import styles from './index.module.scss';

interface Props extends SegmentedProps {
  ref?: Ref<HTMLDivElement>;
}

export const CustomSegmented: FC<Props> = ({
  defaultValue,
  onChange,
  options,
  ref,
  block = true,
  className,
  ...otherProps
}) => (
  <ConfigProvider
    theme={{
      token: {
        controlHeight: 28,
        controlPaddingHorizontal: 6,
        fontSizeLG: 12,
      },
    }}
  >
    <Segmented
      block={block}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      className={classNames(styles.segmented, className)}
      ref={ref}
      {...otherProps}
    />
  </ConfigProvider>
);
