import { FC, useEffect, useState } from 'react';

import { Flex } from 'antd';
import { SelectProps } from 'antd/lib';

import { useServicesQuery } from '@/graphql';

import { formatToCurrency } from '@helpers/formatter';

import AvatarInfo from '@ui/avatar/AvatarInfo';

import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';

const buildServiceInfo = (serviceItem: ServiceFragment) => (
  <Flex vertical gap={4}>
    <div>
      Default Price: {formatToCurrency(serviceItem?.attributes?.defaultPrice)}
    </div>
  </Flex>
);

interface Props extends SelectProps {
  onChange?: (value: string) => void;
  initValue?: string;
  selectedIds?: Maybe<string>[];
}

export const ServiceSelect: FC<Props> = ({
  onChange,
  initValue,
  value,
  selectedIds,
  ...props
}) => {
  const [filters, setFilters] = useState<ServiceFiltersInput>({});

  const { data, refetch, loading } = useServicesQuery({
    variables: {
      filters: {
        ...filters,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const services = data?.services?.data || [];

  const serviceItems = services?.map((serviceItem) => ({
    value: serviceItem?.id,
    label: (
      <AvatarInfo
        src={serviceItem?.attributes?.files?.data?.[0]?.attributes?.url}
        title={serviceItem?.attributes?.name}
        description={buildServiceInfo(serviceItem)}
        isProduct
      />
    ),
    title: serviceItem?.attributes?.name,
    disabled: selectedIds?.includes(serviceItem?.id),
  }));

  const handleSearch = (value: string) => {
    setFilters({
      name: {
        containsi: value,
      },
    });
  };

  return (
    <SearchSelect
      defaultValue={initValue}
      placeholder={props.placeholder || 'Select the service'}
      onChange={onChange}
      options={serviceItems}
      loading={loading}
      onSearch={handleSearch}
      value={value}
      optionLabelProp={'title'}
      {...props}
    />
  );
};
