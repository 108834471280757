import { PathEntityType } from '@crm/types';

export const getActivityRelationField = (context: string): string => {
  switch (context) {
    case PathEntityType.Contacts:
      return 'contact_id';
    case PathEntityType.Leads:
      return 'lead_id';
    case PathEntityType.Companies:
      return 'company_id';
    default:
      return '';
  }
};
