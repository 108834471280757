import { useOnboardingsQuery } from '@/graphql';
import omit from 'lodash/omit';
import { useEffect } from 'react';

type ReturnType = {
  status: boolean;
  isLogoUpload: boolean;
  isMainLocation: boolean;
  isCompanyInfo: boolean;
  isStoresAdded: boolean;
  isEmployeeAdded: boolean;
  isCustomerImported: boolean;
  isProductsImported: boolean;
  isTwilioConnected: boolean;
  isStripeConnected: boolean;
  isQuickBooksConnected: boolean;
  stepsCount: number;
  doneSteps: number;
  loading: boolean;
};

export function useOnboardingData(tenantSlug: string): ReturnType {
  const { data, loading, refetch } = useOnboardingsQuery({
    variables: {
      filters: {
        tenant: {
          slug: {
            eq: tenantSlug,
          },
        },
      },
    },
  });

  const status = data?.onboardings?.data[0]?.attributes?.isCompleted as boolean;
  const isLogoUpload = data?.onboardings?.data[0]?.attributes
    ?.isLogoUpload as boolean;

  const onboardingData = omit(data?.onboardings?.data[0]?.attributes || {}, [
    '__typename',
    'tenant',
    'isCompleted',
  ]);
  const doneSteps = Object.values(onboardingData).filter(
    (item) => item === true,
  ).length;
  const isMainLocation = data?.onboardings?.data[0]?.attributes
    ?.isMainLocation as boolean;
  const isCompanyInfo = data?.onboardings?.data[0]?.attributes
    ?.isEmailAndPhone as boolean;
  const isStoresAdded = data?.onboardings?.data[0]?.attributes
    ?.isStoreAdded as boolean;
  const isEmployeeAdded = data?.onboardings?.data[0]?.attributes
    ?.isEmployeeAdded as boolean;

  const isCustomerImported = data?.onboardings?.data[0]?.attributes
    ?.isCustomerImported as boolean;
  const isProductsImported = data?.onboardings?.data[0]?.attributes
    ?.isProductsImported as boolean;
  const isTwilioConnected = data?.onboardings?.data[0]?.attributes
    ?.isTwilioConnected as boolean;
  const isStripeConnected = data?.onboardings?.data[0]?.attributes
    ?.isStripeConnected as boolean;
  const isQuickBooksConnected = data?.onboardings?.data[0]?.attributes
    ?.isQuickBooksConnected as boolean;

  const stepsCount = Object.keys(onboardingData).length;

  useEffect(() => {
    refetch();
  }, [tenantSlug, data, refetch]);

  return {
    isCompanyInfo,
    stepsCount,
    status,
    isLogoUpload,
    isMainLocation,
    isStoresAdded,
    doneSteps,
    isEmployeeAdded,
    isCustomerImported,
    isProductsImported,
    isQuickBooksConnected,
    isStripeConnected,
    isTwilioConnected,
    loading,
  };
}
