import { FC } from 'react';

import { useToken } from '@hooks/useToken';

import { Icon } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';

interface Props {
  link: string;
  onClick?: () => void;
  backTitle?: string;
}

const MenuBackButton: FC<Props> = ({ link, onClick, backTitle }) => {
  const { token } = useToken();

  return (
    <CustomButton
      href={link}
      style={{
        color: token.colorText,
        paddingInline: 0,
        paddingBlock: 0,
        height: '36px',
        fontSize: '14px',
        fontWeight: 600,
        border: 'none',
        boxShadow: 'none',
      }}
      onClick={onClick}
    >
      <Icon type={'chevron-left'} size={24} />
      {backTitle && backTitle}
    </CustomButton>
  );
};

export default MenuBackButton;
