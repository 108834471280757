import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ROUTES } from '@router/routes';

const Shipment = lazy<FC>(() => import('@pages/inventory/shipment/index'));

const CreateShipmentForm = lazy<FC>(
  () => import('@inventory/shipments/forms/CreateShipmentForm'),
);

const UpdateShipmentForm = lazy<FC>(
  () => import('@inventory/shipments/forms/UpdateShipmentForm'),
);

const { index, shipped, unshipped, create } = ROUTES.tenant.inventory.shipments;

export const shipmentManagementRoutes: RouteObject = {
  path: index,
  children: [
    {
      index: true,
      element: <Navigate to={unshipped} />,
    },
    {
      path: shipped,
      Component: Shipment,
    },
    {
      path: unshipped,
      Component: Shipment,
    },

    {
      path: create,
      Component: CreateShipmentForm,
    },
    {
      path: `${unshipped}/:uuid`,
      Component: UpdateShipmentForm,
    },
    {
      path: `${shipped}/:uuid`,
      Component: UpdateShipmentForm,
    },
  ],
};
