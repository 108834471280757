import { FC, useCallback, useMemo } from 'react';

import { MenuProps, ModalFuncProps } from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { useDeleteDealMutation } from '@/graphql';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';

import ActionMenuDropdown from '@ui/dropdown/ActionMenuDropdown';
import ActionMenuButton from '@ui/dropdown/ActionMenuDropdown/ActionMenuButton';

import { useDrawer } from '@drawer/drawerContext';

import UpdateDealsForm from '@crm/profile/deals/EditDealForm/UpdateDealsForm';

interface Props {
  entityId: string;
}

export const DealsDropdown: FC<Props> = ({ entityId }) => {
  const { openDrawer, closeDrawer } = useDrawer();
  const message = useStatusMessage();
  const [deleteDeal, { loading }] = useDeleteDealMutation({
    onError: () => {
      message?.open('error');
    },
    refetchQueries: ['deals'],
  });

  const handleUpdateDeal = useCallback(() => {
    openDrawer(
      <UpdateDealsForm
        onCancel={closeDrawer}
        id={entityId}
        location={'deal-management'}
      />,
      'Update Deal',
    );
  }, [closeDrawer, entityId, openDrawer]);

  const handleDeleteNote = useCallback(async () => {
    await deleteDeal({
      variables: {
        id: entityId,
      },
      onCompleted: () => {
        message?.open('success', 'Deal deleted successfully!');
      },
    });
  }, [deleteDeal, message, entityId]);

  const confirmDeleteProps = useMemo(
    (): ModalFuncProps => ({
      title: 'Do You Want to Delete This Deal?',
      icon: <ExclamationCircleOutlined />,
      maskClosable: true,
      centered: true,
      okText: loading ? 'Deleting...' : 'Confirm',
      okType: 'danger',
      onOk: async () => {
        await handleDeleteNote();
      },
    }),
    [loading, handleDeleteNote],
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'Edit',
        label: (
          <ActionMenuButton iconType={'edit'} onClick={handleUpdateDeal}>
            Edit
          </ActionMenuButton>
        ),
      },
      {
        key: `divider-2`,
        type: 'divider',
      },
      {
        key: 'Delete',
        label: (
          <ActionMenuButton
            iconType={'delete'}
            actionType={'delete'}
            confirmProps={confirmDeleteProps}
          >
            Delete
          </ActionMenuButton>
        ),
      },
    ],
    [confirmDeleteProps, handleUpdateDeal],
  );

  return <ActionMenuDropdown items={items} iconSize={16} />;
};
