import { useEffect, useMemo, useState } from 'react';

import { useMeQuery, useUsersQuery } from '@/graphql';

import { isNotEmpty } from '@helpers/types';

interface Props {
  pagination?: PaginationArg;
  initialFilters?: UsersPermissionsUserFiltersInput;
  roles?: string[];
  sort?: string | string[];
  withoutOwner?: boolean;
}

export const useEmployeesQuery = ({
  pagination,
  initialFilters,
  roles,
  sort,
  withoutOwner,
}: Props) => {
  const [filters, setFilters] = useState({});

  const { data: meData, loading: meLoading } = useMeQuery();
  // TODO() check filters
  const { data, refetch, loading } = useUsersQuery({
    variables: {
      filters: {
        id: {
          ne: meData?.me?.id,
        },
        ...initialFilters,
        role: {
          name: {
            in: roles ? roles : ['Employee', 'Owner'],
          },
        },
        ...filters,
      },
      pagination,
      sort,
    },
  });

  useEffect(() => {
    refetch();
  }, [filters, meData?.me?.id, pagination, refetch]);

  const userArray = useMemo(() => {
    const employees =
      data?.usersPermissionsUsers?.data.filter(isNotEmpty) || [];
    return [...(meData?.me && !withoutOwner ? [meData.me] : []), ...employees];
  }, [data?.usersPermissionsUsers?.data, meData?.me, withoutOwner]);

  return {
    data: userArray,
    meta: data?.usersPermissionsUsers?.meta,
    loading: loading || meLoading,
    refetch,
    changeFilters: setFilters,
  };
};
