import { EmployeeStatus } from '@components/hr/employees/employeeInputs/EmployeeStatusSelect';

import { FilterFunction } from '@filters/types';

export const createEmployeeStatusFilter: FilterFunction<EmployeeStatus> = (
  status?: EmployeeStatus,
): UsersPermissionsUserFiltersInput => {
  switch (status) {
    case 'pending':
      return {
        confirmed: {
          eq: false,
        },
      };
    case 'active':
      return {
        confirmed: {
          eq: true,
        },
        blocked: {
          eq: false,
        },
      };
    case 'not active':
      return {
        blocked: {
          eq: true,
        },
      };
    default:
      return {};
  }
};

export const createEmployeeTeamFilter: FilterFunction = (
  team?: string,
): UsersPermissionsUserFiltersInput => {
  return {
    and: [
      {
        role: {
          id: {
            eq: team,
          },
        },
      },
    ],
  };
};
