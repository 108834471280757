import { FilterFieldType, StoneFiltersType } from '@filters/types';
import { CustomFormItem } from '@form/item/FormItem';
import { Col } from 'antd';
import { CSSProperties, FC } from 'react';

import { GetChangeHandler } from '@form/hooks/useCustomForm';
import { AnyObject } from '@helpers/types';

import { DiamondColorCustomFields } from '@filters/additionalFields/stoneCustomFileds/DiamondColorCustomFields';
import { StoneWeightCustomInput } from '@filters/additionalFields/stoneCustomFileds/StoneWeigthCustomInput';
import { MultiplyStoneClarityEnhancementSelect } from '@inventory/inventoryManagement/products/inputs/ClarityEnhancementInput/MultiplyStoneClarityEnhancementSelect';
import { MultiplyStoneClaritySelect } from '@inventory/inventoryManagement/products/inputs/ClarityInput/MultiplyStoneClaritySelect';
import { MultiplyStoneColorEnhancementSelect } from '@inventory/inventoryManagement/products/inputs/ColorEnhancementInput/MultiplyStoneColorEnhancementSelect';
import { MultiplyStoneCutSelect } from '@inventory/inventoryManagement/products/inputs/CutInput/MultiplyStoneCutSelect';
import { MultiplyStoneFluorescenceSelect } from '@inventory/inventoryManagement/products/inputs/FluorescenceInput/MultiplyStoneFluorescenceSelect';
import { MultiplyStoneGirdleFinishSelect } from '@inventory/inventoryManagement/products/inputs/GirdleFinishInput/MultiplyStoneGirdleFinishSelect';
import { MultiplyStoneGirdleThicknessSelect } from '@inventory/inventoryManagement/products/inputs/GirdleThicknessInput/MultiplyStoneGirdleThicknessSelect';
import { MultiplyStoneGradingReportSelect } from '@inventory/inventoryManagement/products/inputs/GradingReportInput/MultiplyStoneGradingReportSelect';
import { MultiplyStoneHueSelect } from '@inventory/inventoryManagement/products/inputs/HueInput/MultiplyStoneHueSelect';
import { MultiplyStonePolishSelect } from '@inventory/inventoryManagement/products/inputs/PolishInput/MultiplyStonePolishSelect';
import { MultiplyStoneOriginSelect } from '@inventory/inventoryManagement/products/inputs/StoneOriginInput/MultiplyStoneOriginSelect';
import { MultiplyStoneShapeSelect } from '@inventory/inventoryManagement/products/inputs/StoneShapeInput/MultiplyStoneShapeSelect';
import { MultiplyStoneSymmetrySelect } from '@inventory/inventoryManagement/products/inputs/SymmetyInput/MultiplyStoneSymmetrySelect';

interface Props {
  handleChange: GetChangeHandler<StoneFiltersType>;
  initialValues?: (FilterFieldType & AnyObject<any>) | null | undefined;
  customStyles?: CSSProperties;
}

export const DiamondCustomFields: FC<Props> = ({
  initialValues,
  handleChange,
  customStyles,
}) => {
  return (
    <>
      <Col span={24}>
        <CustomFormItem
          name={'shape'}
          label={'Diamond Shape'}
          style={customStyles}
        >
          <MultiplyStoneShapeSelect
            allowClear
            initialValue={initialValues?.shape ?? undefined}
            onChange={handleChange('stoneShape')}
            type={'Diamond'}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'weight'}
          label={'Weight in carat'}
          style={customStyles}
        >
          <StoneWeightCustomInput
            onChange={handleChange('weight')}
            initialValue={initialValues?.weight}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <DiamondColorCustomFields
          type={'Diamond'}
          initialValues={initialValues?.color ?? undefined}
          getChangeHandler={handleChange}
          customStyles={customStyles}
        />
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'origin'}
          label={'Nature/Origin'}
          style={customStyles}
        >
          <MultiplyStoneOriginSelect
            allowClear
            initialValue={initialValues?.origin ?? undefined}
            onChange={handleChange('origin')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'girdleThickness'}
          label={'Girdle Thickness'}
          style={customStyles}
        >
          <MultiplyStoneGirdleThicknessSelect
            allowClear
            initialValue={initialValues?.girdleThickness ?? undefined}
            onChange={handleChange('girdleThickness')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'girdleFinish'}
          label={'Girdle Finish'}
          style={customStyles}
        >
          <MultiplyStoneGirdleFinishSelect
            allowClear
            initialValue={initialValues?.girdleFinish ?? undefined}
            onChange={handleChange('girdleFinish')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceLong'}
          label={'Fluorescence (Long Wave)'}
          style={customStyles}
        >
          <MultiplyStoneFluorescenceSelect
            allowClear
            initialValue={initialValues?.fluorescenceLong ?? undefined}
            onChange={handleChange('fluorescenceLong')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceShort'}
          label={'Fluorescence (Short Wave)'}
          style={customStyles}
        >
          <MultiplyStoneFluorescenceSelect
            allowClear
            initialValue={initialValues?.fluorescenceShort ?? undefined}
            onChange={handleChange('fluorescenceShort')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceLongHue'}
          label={'Fluorescence Long Hue'}
          style={customStyles}
        >
          <MultiplyStoneHueSelect
            allowClear
            initialValue={initialValues?.fluorescenceLongHue ?? undefined}
            onChange={handleChange('fluorescenceLongHue')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'fluorescenceShortHue'}
          label={'Fluorescence Short Hue'}
          style={customStyles}
        >
          <MultiplyStoneHueSelect
            allowClear
            initialValue={initialValues?.fluorescenceLongHue ?? undefined}
            onChange={handleChange('fluorescenceShortHue')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'clarity'} label={'Clarity'} style={customStyles}>
          <MultiplyStoneClaritySelect
            allowClear
            initialValue={initialValues?.clarity ?? undefined}
            type={'Diamond'}
            onChange={handleChange('clarity')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'cut'} label={'Cut'} style={customStyles}>
          <MultiplyStoneCutSelect
            allowClear
            initialValue={initialValues?.cut ?? undefined}
            onChange={handleChange('cut')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem name={'polish'} label={'Polish'} style={customStyles}>
          <MultiplyStonePolishSelect
            allowClear
            initialValue={initialValues?.polish ?? undefined}
            onChange={handleChange('polish')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'symmetry'}
          label={'Symmetry'}
          style={customStyles}
        >
          <MultiplyStoneSymmetrySelect
            allowClear
            initialValue={initialValues?.symmetry ?? undefined}
            onChange={handleChange('symmetry')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'colorEnhancement'}
          label={'Color Enhancements'}
          style={customStyles}
        >
          <MultiplyStoneColorEnhancementSelect
            allowClear
            initialValue={initialValues?.colorEnhancement ?? undefined}
            onChange={handleChange('colorEnhancement')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'clarityEnhancement'}
          label={'Clarity Enhancements'}
          style={customStyles}
        >
          <MultiplyStoneClarityEnhancementSelect
            allowClear
            initialValue={initialValues?.clarityEnhancement ?? undefined}
            onChange={handleChange('clarityEnhancement')}
          />
        </CustomFormItem>
      </Col>
      <Col span={24}>
        <CustomFormItem
          name={'gradingReportBy'}
          label={'Grading Report By'}
          style={customStyles}
        >
          <MultiplyStoneGradingReportSelect
            allowClear
            initialValue={initialValues?.gradingReportBy ?? undefined}
            type={'Diamond'}
            onChange={handleChange('gradingReportBy')}
          />
        </CustomFormItem>
      </Col>
    </>
  );
};
