import { ColProps } from 'antd';

export const verticalLabelColProps: ColProps = {
  span: 24,
  style: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};
